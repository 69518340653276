import Vue from 'vue'
import Router from 'vue-router'
import IndexIndex from '../view/index/index'
import changeCity from '../view/index/change_city'

import soarMove from '../view/soar/move' // 知产落地页
import soarSoar from '../view/soar/soar'
import expertAdd from '../view/expert/add'
import expertList from '../view/expert/list'
import expertDetail from '../view/expert/detail'

import activityAdd from '../view/activity/add' // 活动
import activityList from '../view/activity/list'
import activityDetail from '../view/activity/detail'
import activityProtocol from '../view/activity/protocol'
import activityAdmin from '../view/activity/admin'
// 会员权益
import rights from '../view/rights/index'
// 新闻
import news from '../view/news/list'
import newsAdd from '../view/news/add'
import newsDetail from '../view/news/detail'
import newsAdmin from '../view/news/admin'

import shoreList from '../view/store/list.vue' //企业列表
import shop from '../view/store/index.vue'
import storeAbout from '../view/store/about.vue'
import storeAnli from '../view/store/anli.vue'
import storeAnliDetail from '../view/store/anli_detail.vue'
import storeLeave from '../view/store/leave.vue'
import storeMessage from '../view/store/message'
import cVipIndex from '../view/template/company/vip_01/index'
import cVipIntroduction from '../view/template/company/vip_01/introduction'
import cVipNews from '../view/template/company/vip_01/news'
import cVipProduct from '../view/template/company/vip_01/product'
import cVipProductDetail from '../view/template/company/vip_01/product_detail'
import cVipContact from '../view/template/company/vip_01/contact'

import product from '../view/product/list' //产品列表
import demandList from '../view/demand/list.vue' //需求列表
import demandDetail from '../view/demand/detail.vue'
import demandAdd from '../view/demand/add.vue'
import demandAdmin from '../view/demand/admin'

import login from '../view/user/login.vue'
import register from '../view/user/register.vue'

import company from '../view/company/company'
import zcMessage from '../view/company/zc_message.vue'
import zcPassword from '../view/company/zc_password.vue'
import zcVerify from '../view/company/zc_verify.vue'
import qyMessage from '../view/company/qy_message.vue'
import qyStatus from '../view/company/qy_status.vue'

import cpAdmin from '../view/company/cp_admin.vue'
import cpProcess from '../view/company/cp_process.vue'
import wxLogin from '../view/company/wx_login.vue'

import mail from '../view/company/mail.vue'
import templet from '../view/edit/templet.vue'

import prizeDraw from '../view/index/prize_draw.vue'

//成果
import achivementAdd from "../view/achievement/add.vue"
import achivementAdmin from "../view/achievement/admin.vue"
import achivementDetail from "../view/achievement/detail.vue"
import achivementIndex from "../view/achievement/index.vue"

// 设置
import editbaseinfo from '../view/set/editbaseinfo'

Vue.use(Router)

const router = new Router({
	mode: "history",
	routes: [{
			path: '/',
			name: 'IndexIndex',
			component: IndexIndex
		}, {
			path: '/change_city',
			name: 'changeCity',
			component: changeCity
		}, {
			path: '/achivement/add',
			name: 'achivementAdd',
			component: achivementAdd
		},
		{
			path: '/achivement/admin',
			name: 'achivementAdmin',
			component: achivementAdmin
		}, {
			path: '/achivement/detail',
			name: 'achivementDetail',
			component: achivementDetail
		}, {
			path: '/achievement',
			name: 'achivementIndex',
			component: achivementIndex
		},
		{
			path: '/move',
			name: 'soarMove',
			component: soarMove,
		},
		{
			path: '/soar',
			name: 'soarSoar',
			component: soarSoar,
			meta: {
				title: '一实知产高企申报，一站式高企申报服务商'
			}
		},
		{
			path: '/expert',
			name: 'expertList',
			component: expertList
		},
		{
			path: '/expert/detail',
			name: 'expertDetail',
			component: expertDetail
		},
		{
			path: '/expert/add',
			name: 'expertAdd',
			component: expertAdd
		},
		{
			path: '/activity',
			name: 'activityList',
			component: activityList
		},
		{
			path: '/activity/add',
			name: 'activityAdd',
			component: activityAdd
		},
		{
			path: '/activity/detail',
			name: 'activityDetail',
			component: activityDetail
		},
		{
			path: '/activity/protocol',
			name: 'activityProtocol',
			component: activityProtocol
		}, {
			path: '/activity/admin',
			name: 'activityAdmin',
			component: activityAdmin
		},
		{
			path: '/news',
			name: 'news',
			component: news
		},
		{
			path: '/news/detail',
			name: 'newsDetail',
			component: newsDetail
		},
		{
			path: '/news/admin',
			name: 'newsAdmin',
			component: newsAdmin
		},
		{
			path: '/news/add',
			name: 'newsAdd',
			component: newsAdd
		},
		{
			path: '/rights',
			name: 'rights',
			component: rights
		},
		{
			path: '/store',
			name: 'shoreList',
			component: shoreList
		},
		{
			path: '/store/index',
			name: 'shop',
			component: shop
		},
		{
			path: '/store/anli',
			name: 'store_anli',
			component: storeAnli
		},
		{
			path: '/store/about',
			name: 'store_about',
			component: storeAbout
		},
		{
			path: '/store/anli_detail',
			name: 'store_anli_detail',
			component: storeAnliDetail,
		},
		{
			path: '/store/leave',
			name: 'store_leave',
			component: storeLeave,
		},
		{
			path: '/store/message',
			name: 'store_message',
			component: storeMessage,
		},
		{
			path: '/c/vip01/index',
			name: 'cVipIndex',
			component: cVipIndex,
		},
		{
			path: '/c/vip01/introduction',
			name: 'cVipIntroduction',
			component: cVipIntroduction,
		},
		{
			path: '/c/vip01/news',
			name: 'cVipNews',
			component: cVipNews,
		},
		{
			path: '/c/vip01/product',
			name: 'cVipProduct',
			component: cVipProduct,
		},{
			path: '/c/vip01/product_detail',
			name: 'cVipProductDetail',
			component: cVipProductDetail,
		},{
			path: '/c/vip01/contact',
			name: 'cVipContact',
			component: cVipContact,
		},
		{
			path: '/product',
			name: 'product',
			component: product,
		},
		{
			path: '/demand',
			name: 'demandList',
			component: demandList
		},
		{
			path: '/demand/detail',
			name: 'demandDetail',
			component: demandDetail
		},
		{
			path: '/demand/add',
			name: 'demandAdd',
			component: demandAdd
		},
		{
			path: '/demand/admin',
			name: 'demandAdmin',
			component: demandAdmin
		},
		{
			path: '/login',
			name: 'login',
			component: login
		},
		{
			path: '/register',
			name: 'register',
			component: register
		},
		{
			path: '/user_center',
			name: 'company',
			component: company
		},
		{
			path: '/user_center/zc_message',
			name: 'zcMessage',
			component: zcMessage
		},
		{
			path: '/user_center/zc_password',
			name: 'zcPassword',
			component: zcPassword
		},
		{
			path: '/user_center/zc_verify',
			name: 'zcVerify',
			component: zcVerify
		},
		{
			path: '/company',
			name: 'company',
			component: company
		},
		{
			path: '/company/qy_message',
			name: 'qyMessage',
			component: qyMessage
		},
		{
			path: '/company/qy_status',
			name: 'qyStatus',
			component: qyStatus
		},

		{
			path: '/company/cp_admin',
			name: 'cpAdmin',
			component: cpAdmin
		},
		{
			path: '/company/cp_process',
			name: 'cpProcess',
			component: cpProcess
		},
		{
			path: '/company/mail',
			name: 'mail',
			component: mail
		},
		{
			path: '/edit/templet',
			name: 'templet',
			component: templet
		},
		{
			path: '/edit/links_list',
			name: 'linksList',
			meta: { title: "友情链接设置" },
			component: () => import('@/view/edit/links_list.vue')
		},
		{
			path: '/edit/edit_links',
			name: 'editLinks',
			meta: { title: "添加友情链接" },
			component: () => import('@/view/edit/edit_links.vue')
		},
		{
			path: '/edit/banner_list',
			name: 'bannerList',
			meta: { title: "幻灯片设置" },
			component: () => import('@/view/edit/banner_list.vue')
		},
		{
			path: '/edit/banner_links',
			name: 'bannerLinks',
			meta: { title: "添加幻灯片" },
			component: () => import('@/view/edit/banner_links.vue')
		},
		{
			path: '/prize_draw',
			name: 'prizeDraw',
			component: prizeDraw
		},
		{
			path: '/user_center/wx_login',
			name: 'wxLogin',
			component: wxLogin
		},
		{
			path: '/setting/editbaseinfo',
			name: 'editbaseinfo',
			component: editbaseinfo
		},
	],
})

router.beforeEach((to, from, next) => {
	const token = localStorage.getItem('token');
	if (!token) {

		if (to.path == '/user_center' || to.path == '/company/qy_message') {
			return next('/login')
		}
	}
	if (to.meta.title != null) {
		document.title = to.meta.title
	} else {
		document.title = "科技牛服务_创新资源共享平台"
	}
	next()

})
export default router
