<template>
	<div id="company">
		<div class="vip_center_main">
			<!-- 左侧权益列表-->
			<div class="vip_c_left">
				<div class="left_company">
					<el-image style="width: 34px; height: 30px" src="//jic.makepolo.net/img15/new_vip/my_can.png" fit="cover"></el-image>
					<span>我的商铺</span>
				</div>
				<!--=S sidebar -->
				<div class="main_left">
					<Left></Left>
				</div>
				<div class="business_div" style="display:none;">
					<div class="business_b">
						<div class="business_t">
							<span>已为您匹配<i id="bus_num">1</i>条商机</span>
							<img src="//jic.makepolo.net/img15/memberpng/c_bus.png" class="close_business">
							<!-- <el-image style="width: 34px; height: 30px" src="//jic.makepolo.net/img15/memberpng/c_bus.png" fit="cover"></el-image> -->
						</div>
						<ul id="bus_ul">
						</ul>
						<div class="checkmore">查看更多&gt;&gt;</div>
					</div>
				</div>

				<!--=E sidebar -->
			</div>
			<!-- 右侧具体内容-->
			<div class="vip_c_right">
				<div class="right_head">
					<div class="r_h_name">
						<img src="http://qybb.makepolo.net/avatar/9.png">
						<span>{{detail.contact_name}}</span>
					</div>
					<div class="r_h_company">
						<div>
							<span>{{time_text}}，{{detail.name}}</span>
						</div>
						<div>
							<img src="//jic.makepolo.net/img15/new_vip/ren.png">
						</div>
					</div>
					<div class="r_h_num">
						<!-- <span>产品发布条数剩余:100条</span> -->
						<div style="margin-top: 10px;">
							<span id="giveDay" style="font-size: 14px;display: none;color: #FF5337;">明日登陆免费赠送您25条发布权限</span>
							<span style="display: none;color: rgba(68, 110, 201, 1);" class="check_vip">查看会员服务</span>
						</div>
					</div>
				</div>
				<div class="vip_content">
					<div class="new_top_tip">科技牛直通车，强势来袭！火热招商中，先到先得！</div>
					<ul class="word_ul">
						<li class="word_li">
							<span>金牌</span>
							<span>￥<i>5680</i>/年</span>
							<span>8个词</span>
							<span>立即开通</span>
							<input type="radio" name="aid" class="radio2" value="10100" style="visibility: hidden">
						</li>
					</ul>
					<div class="word_div">
						<div></div>
						<div>
							<img src="/img/index15.cdf4fd8b.jpg">
							<span class="word_p">手机扫码下载科技牛APP</span>
						</div>
					</div>
					<div class="new_vip_tip">了解详情</div>
				</div>
				<!--店铺运营-->
				<div class="shop_div" style="display: none;">
					<div class="shop_top">
						<span>店铺运营</span>
						<span>开通会员可增加曝光量哦~<i class="openvip_btn">去开通会员</i></span>
					</div>
					<ul>
						<li class="shop_before">
							<div class="s_before">
								<span>在线商品数</span>
								<span>5</span>
							</div>
							<div class="s_back">
								<span>在线商品数</span>
								<span>发布高质量商品可提高产品收录和曝光量</span>
								<span class="launch_products">发布产品</span>
							</div>
						</li>
						<li class="shop_before">
							<div class="s_before">
								<span style="width: 95px;">网站信息百度收录量</span>
								<span style="font-size: 20px;" id="bai_num">6亿5083万</span>
							</div>
							<div class="s_back">
								<span>商品百度收录数</span>
								<span>开通会员搜索引擎收录提升高达90%</span>
								<span class="openvip_btn">开通会员</span>
							</div>
						</li>
						<li class="shop_before">
							<div class="s_before">
								<span>商品曝光量(近7日)</span>
								<span>19</span>
							</div>
							<div class="s_back">
								<span>商品曝光量</span>
								<span>开通会员商品曝光量可提升到原数据+500~1000次</span>
								<span class="openvip_btn">开通会员</span>
							</div>
						</li>
						<li class="shop_before">
							<div class="s_before">
								<span>电话曝光量(近7日)</span>
								<span>3</span>
							</div>
							<div class="s_back">
								<span>电话曝光量</span>
								<span>开通会员电话曝光量可提升到原数据+2500~3500次</span>
								<span class="openvip_btn">开通会员</span>
							</div>
						</li>
						<li class="shop_before">
							<div class="s_before">
								<span>企业曝光量(近7日)</span>
								<span>9</span>
							</div>
							<div class="s_back">
								<span>企业曝光量</span>
								<span>开通会员企业曝光量可提升到原数据+1000~1500次</span>
								<span class="openvip_btn">开通会员</span>
							</div>
						</li>
						<li class="shop_before">
							<div class="s_before">
								<span>商机匹配量</span>
								<span id="opp_num">0</span>
							</div>
							<div class="s_back">
								<span>商机匹配量</span>
								<span>开通会员商机匹配量可提5倍</span>
								<span class="openvip_btn">开通会员</span>
							</div>
						</li>
						<li class="shop_before">
							<div class="s_before">
								<span>商品询盘量</span>
								<span>29</span>
							</div>
							<div class="s_back">
								<span>商品询盘量</span>
								<span>开通会员商机盘无限查看</span>
								<span class="openvip_btn">开通会员</span>
							</div>
						</li>
					</ul>
				</div>
				<!--最新推荐-->
				<div class="div_new">
					<p class="new_p">最新推荐</p>
					<ul>
						<li class="new_before">
							<span>精准采购商机</span>
							<span>精准商机免费查看,成单率高达80%</span>
							<span>立即使用</span>
						</li>
						<li class="new_before">
							<span>企业网站产品体检</span>
							<span>一键检查并指导修改,让产品更加完善,得到更多客户的青睐</span>
							<span>立即使用</span>
						</li>
						<li class="new_before">
							<span>批量上传产品</span>
							<span>产品快捷上传、管理省时省力</span>
							<span>立即使用</span>
						</li>
						<li class="new_before">
							<span>豪华模板装修</span>
							<span>20套宽屏模板，让您的店铺更豪华</span>
							<span>立即使用</span>
						</li>
						<li class="new_before">
							<span>微信小程序</span>
							<span>便于企业推广展示公司实力</span>
							<span>立即使用</span>
						</li>
					</ul>
				</div>
				<!--会员增值-->
				<div class="word_vip">
					<p class="new_p">会员增值服务</p>
					<div class="h_vip_black vip_board">
						<div class="v_board_black">
							<img src="http://jic.makepolo.net/img15/new_vip/hei.png">
							<div class="black_s">￥<span>3999</span>/年</div>
							<div>原价¥57990</div>
						</div>
						<div class="v_board_text">
							<div class="board_text_t">经典会员 </div>
							<div class="board_text_c">送4000条发布权限、搜索排名前一、全网搜索引擎优化</div>
							<div class="board_text_btn">
								<span class="open_vip">立即开通</span>
								<span class="look_btn">了解权益详情</span>
							</div>
						</div>
					</div>
				</div>
				<!--全网播报-->
				<div class="hyzs_scroll">
					<p class="new_p">全网播报</p>
					<div class="hyzs_scrollll" style="height: 300px;background: #fff;">
						<div class="marquee-wrap">
							<ul class="marquee-list" :class="{'animate-up': animateUp}">
								<li v-for="(item,i) in demand.list" :key="i">
									<span class="time"><span class="name" v-html="item.created_at.substr(0,10)"></span>{{item.title}}</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// import wx from "weixin-js-sdk";
	import Left from "./Left.vue"
	import QRCode from 'qrcodejs2'
	export default {
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		components:{
			Left
		},
		data() {
			return {
				animateUp: false,
				timer: null,
				detail: {},
				id: '',
				time_text: '',
				scan: {},
				code_url: '',
				demand: {
					list: [],
					count: 0,
				},
				demand_request: {
					page: 1,
					limit: 10,
				}
			}
		},
		mounted() {
			this.timer = setInterval(this.scrollAnimate, 5000);
			this.id = this.$route.query.id;
			this.get_detail();
			var d = new Date();
			var n = d.getHours();
			if (n <= 12) {
				this.time_text = '上午好'
			} else {
				this.time_text = '下午好'
			}
			this.get_demand_list()
		},
		destroyed() {
			clearInterval(this.timer);
		},
		methods: {
			async get_demand_list() {
				await this.$http_qm.post('demand/list', this.demand_request).then(res => {
					this.demand.list = res.data;
					this.demand.count = res.count;
				}).catch(err => {
					console.log(err);
				})
			},
			do_pay() {
				console.log('do_pay')
				this.$http.post('pay/pay_submit', {
					type: "wx_scan",
					order_type: 3,
				}).then(result => {
					if (result.code !== 200) {
						this.$message.info("获得订单信息失败")
						return;
					}
					new QRCode(this.$refs.qrCodeUrl, {
						text: result.data.code_url, // 需要转换为二维码的内容
						width: 100,
						height: 100,
						colorDark: '#000000',
						colorLight: '#ffffff',
						correctLevel: QRCode.CorrectLevel.H
					})
					// let data = JSON.parse(result.data)
					//函数为分装过得  (就是调用微信支付)

				}).catch(err => {
					console.log(err);
				})
			},


			get_detail() {
				this.$http_qm.post('company/detail', {
					type: 'me'
				}).then(res => {
					if (res.code == 200) {
						this.detail = res.data;
					}
				}).catch(err => {
					console.log(err);
				})
			},
			scrollAnimate() {
				if(this.demand.list.length >= 10){
					this.animateUp = true;
					setTimeout(() => {
						this.demand.list.push(this.demand.list[0]);
						this.demand.list.shift();
						this.animateUp = false;
					}, 1000);
				}
			}
		}
	}
</script>

<style lang="less" src="../../assets/css/patter.less"></style>
