<template>
	<div id="Left">
		<div class="left-nav-list">
			<div class="j-nav left-nav-item" v-for="(item,i) in list" :key="i">
				<div class="left-nav-item-title">
					<i :class="item.icon"></i>
					<p class="pcb">{{item.title}}</p>
				</div>
				<ul class="left-nav-inner-list">
					<li class="left-nav-inner-item left-nav-inner-item-active" 
					v-for="(v,index) in item.listItem" 
					:key="index" 
					:class="{'isActive': clickIndex === i + index}"
					 @click="son(index,i)">
						<span>{{v.name}}</span>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				list: [{
						title: '个人资料',
						icon: 'el-icon-user',
						listItem: [{
							name: '基本信息',
							url: '/setting/editbaseinfo'
						}]
					},
					{
						title: '账号安全',
						icon: 'el-icon-lock',
						listItem: [{
							name: '当前安全级别',
							url: '/message'
						}, {
							name: '修改登录密码',
							url: '/message'
						}, {
							name: '手机绑定',
							url: '/message'
						}, {
							name: '邮箱绑定',
							url: '/message'
						}, ]
					},
					{
						title: '账户管理',
						icon: 'el-icon-mobile'
					},
					{
						title: '发票管理',
						icon: 'el-icon-postcard'
					},
				],
				clickIndex: 0
			}
		},
		methods: {
			son(i,index) {
				this.clickIndex = i + index;
			}
		}
	}
</script>

<style lang="less" src="../../assets/css/set.less"></style>
