<template>
	<div class="top_box">
		<div class="top1">

			<div class="logo_box">
				<img class="logo" src="../assets/img/logo.png" alt="">
			</div>
			<div class="menu">
				<ul>
					<li v-for="(v,k) in nav" v-bind:class="{active:path==v.to}" :key="k" @click="href(v.to)">{{v.name}}
					</li>
				</ul>
			</div>
			<div class="right_menu">
				<ul>
					<li v-for="(v,k) in nav2" v-bind:class="{reg:!user.username&& k==3}" :key="k" >
					<span @click="href(v.to)">	{{v.name}}</span>
						<i v-if="user.username&& k==2" class="el-icon-arrow-down"></i>
						<div class="right_down" v-show="right_down_show" v-if="k==2&&user.username">
							<div class="down_li" @click="href('/user_center/zc_message')">资料与账号</div>
							<div class="down_li" @click="logout()">退出</div>
						</div>


					</li>


				</ul>
			</div>
		</div>
	</div>

</template>

<script>
	import {
		mapGetters,
		mapMutations
	} from 'vuex'
	export default {
		props: {
			search_text: {
				type: String,
				default: ''
			},
			page_type: {
				type: String,
				default: "0"
			}
			/* ,
			user: {
				type: Object,
			} */
		},
		data() {
			return {
				right_down_show: false,
				nav: [{
						name: '首页',
						checked: true,
						to: '/'
					},
					{
						name: '需求大厅',
						checked: false,
						to: '/demand'
					},
					{
						name: '认证专家',
						checked: false,
						to: '/expert'
					},
					{
						name: '新闻动态',
						checked: false,
						to: '/news'
					},
					{
						name: '活动大厅',
						checked: false,
						to: '/activity'
					}
				],
				nav2: [{
						name: '发布需求',
						to: '/demand/add'
					},
					{
						name: '工作台',
						to: '/demand/admin'
					},


				],
				path: '',
				search_type: 0
			};
		},
		computed: {
			...mapGetters(['city','user'])

		},
		mounted() {

			this.path = this.$route.path
			this.right_down_show = false;
			if (typeof(this.user.username) == 'undefined') {
				this.nav2.push({
					name: '登录',
					to: '/login'
				}, {
					name: '/    注册',
					to: '/register'
				})
			} else {
				this.nav2.push({
					name: this.user.username,
					to: ''
				})
			}


		},
		watch: {
			$route(to) {
				this.path = to.path
				this.right_down_show = false;
				this.$forceUpdate()
			},
			user(newValue) {
				console.log(newValue)
			}
		},

		methods: {
			...mapMutations(['setLogout']),
			find_nav() {
				var page_type = this.page_type;
				this.find_cate.forEach(function(v, k) {
					if (parseInt(page_type) == k) {
						v.checked = true;
					} else {
						v.checked = false;
					}
				});
			},
			find_nav_fill() {
				var path = this.$route.path;
				this.nav.forEach(function(v) {
					if (v.to == path) {
						v.checked = true;
					} else {
						v.checked = false;
					}
				});
			},
			logout() {
				this.setLogout();
				this.$router.push({
					path: '/login'
				})
			},
			nav_click(k, to) {
				if (!to) {
					this.$message('功能暂未开发');
					return;
				}
				this.nav.forEach(function(v, k1) {
					if (k == k1) {
						v.checked = true;
					} else {
						v.checked = false;
					}
				});

				// this.nav[k].checked = true;
			},
			href(url) {
				console.log(url);
				if (url) {
					this.$router.push({
						path: url,
						query: {}
					});
					/* var router =this.$router.resolve({
							path: url,
							query: {}
							
						});
						window.open(router.href, '_blank'); */
				} else {
					this.right_down_show = this.right_down_show ? false : true;
					this.$forceUpdate()
				}
			},

		}
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
