<template>
	<div id="login" class="register" style="background-image: url(https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/login_bg1.jpg);">
		<div class="kjn-header">
			<div class="account-header" id="utopia_widget_1">
				<div class="grid">
					<div class="logo">
						<div class="logo-img"></div>
						<h1 class="logo-title">新用户注册</h1>
					</div>
				</div>
			</div>
		</div>

		<div class="content" >
			<div class="kjn-grid">
				<div class="box-warp">
					<div class="box-form-wrap">
						<div class="box-form">
							<el-tabs v-model="activeName" @tab-click="handleClick">
								<el-tab-pane label="手机注册" name="code">
									<div class="login-form">
										<div class="input-new">
											<img src="//as.zbjimg.com/static/nodejs-zbj-account-web/widget/login/login-form/login-password-form/img/username-input_4485dfc.png"
											 class="left" width="37px">
											<input id="username" name="username" class="username-input" v-model="form.tel" type="text" placeholder="请输入常用的手机号"
											 tabindex="1">
										</div>

										<div class="flex-row">
											<div class="input-new">
												<img src="//as.zbjimg.com/static/nodejs-zbj-account-web/widget/login/login-form/login-password-form/img/password-input_d4f84c3.png"
												 class="left" width="37px">
												<input id="username" name="username" class="username-input" v-model="form.code" type="number" placeholder="输入6位动态码"
												 tabindex="2">
											</div>
											<button type="button" @click="get_code()" class="get-dynamic-code">{{get_code_text}}</button>
										</div>
										<div class="input-new">
											<img src="//as.zbjimg.com/static/nodejs-zbj-account-web/widget/login/login-form/login-password-form/img/password-input_d4f84c3.png"
											 class="left" width="37px">
											<input id="username" name="username" v-model="form.password" class="username-input" type="password"
											 placeholder="密码(8-16位字母/数字/符号)" tabindex="2">
										</div>
										<div class="slip">
											<label>
												<input type="checkbox" name="cache" v-model="xieyi_checked" tabindex="4">
												<span>我已阅读并同意服务协议</span>
											</label>
										</div>
										<div class="login-form-button">
											<button class="button_login" @click="do_register()" tabindex="5" type="button">注&nbsp;册</button>
										</div>
									    <router-link class="authorize-link" style="" to="./login"> 已有账号去登录></router-link>
									</div>
								</el-tab-pane>
							</el-tabs>
							<!-- <div class="login-other-ways-login login-other-ways">
								<div class="authorize-login">
									<p class="authorize-login-title" style="display: flex;align-items: center;">
										<span class="authorize-login-title-left">使用第三方账号登录</span>
										<ul class="authorize-login-list">
											<li class="authorize-login-item">
												<span class="j-authorize-login-item wechat-login" title="微信登录" style="margin-top: 0px;"></span>
											</li>
										</ul>
									</p>
								</div>
							</div> -->
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="account-footer" id="utopia_widget_15">
			<div class="zbj-grid zbj-footer-grid">
				<div class="footer-sitelink">
					<h5>关于我们</h5>
					<span class="split">|</span>
					<h5>联系我们</h5>
					<span class="split">|</span>
					<h5>专家服务</h5>
					<span class="split">|</span>
					<h5>新闻中心</h5>
					<span class="split">|</span>
					<h5>热门服务</h5>
				</div>
				<p class="footer-copyright">Copyright http://www.keji6.cn/ 版权所有
					<span>沪ICP备16055134号-1</span>
				</p>
				<div class="footer-gov" style="display: none;">
					<div class="info">
						<img v-if="img" :src="img">
						<span v-else class="footer-gov-text">工商网监电子标识</span>
					</div>
					<div class="info">
						<img v-if="img" :src="img">
						<span v-else class="footer-gov-text">工商网监电子标识</span>
					</div>
					<div class="info">
						<img v-if="img" :src="img">
						<span v-else class="footer-gov-text">工商网监电子标识</span>
					</div>
					<div class="info">
						<img v-if="img" :src="img">
						<span v-else class="footer-gov-text">工商网监电子标识</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapMutations
	} from "vuex";
	export default {
		data() {
			return {
				img: '//as.zbjimg.com/static/nodejs-zbj-account-web/widget/accountFooter/images/gov3_f555083.jpg',
				activeName: 'code',
				get_code_text: '获取验证码',
				second: 60,
				form: {
					tel: '',
					code: '',
					password: '',
				},
				xieyi_checked: false,
			}
		},
		methods: {
			...mapMutations(['setLogin']),
			handleClick(tab, event) {
				console.log(tab, event);
			},
			do_register() {
				if (!this.xieyi_checked) {
					this.$message.error('请先选中我已阅读并同意协议');
					return false;
				}
				this.$http.post("user/register", this.form)
					.then(res => {
						if (res.code == 200) {
							this.setLogin(res.data)
							this.$router.back();
						} else {
							this.$message.error(res.msg)
						}
					})
					.catch(err => {
						console.log(err)
					})
			},
			get_code() {
				if (this.form.tel == '') {
					this.$message.error('请输入手机号');
					return false;
				}
				this.$http.post('user/get_code', {
					tel: this.form.tel,
					template: 'reg',
				}).then(res => {
					if (res.code == 200) {
						var interval = setInterval(() => {
							--this.second;
							this.get_code_text = '重新获取' + '(' + this.second + ')';
						}, 1000)
						setTimeout(() => {
							clearInterval(interval)
							this.second = 60;
							this.get_code_text = '获取验证码';
						}, 60000)
					}

				}).catch(err => {
					console.log(err);
				})
			},
		}
	}
</script>

<style lang="less" src="../../assets/css/patter.less"></style>
<style scoped>
	.authorize-link{
		color: #0088ff; display: block; margin-top: 10px;
	}
	@media screen and (max-width: 750px) {

		.kjn-grid .box-warp {
			margin:0 20px;
			width: 100% !important;
		}
		.kjn-grid .box-warp .box-form{
			width: 100%;
			height: 100%;
		}
	}

	@media screen and (max-width: 1620px) {
		.content {
			background-size: cover !important;
		}
	}

	@media screen and (max-width: 1920px) {
		.content {
			background-size: 100% 100%;
		}

		.kjn-grid {
			margin: 0 auto;
			max-width: 1200px;
			width: 100%;
			display: flex;
		}

		.kjn-grid .box-warp {
			width: 328px;
			display: flex;
			justify-content: end;
		}
	}
</style>
