<template>
	<div id="add">
		<Top></Top>
		<div class="expert_tbbg">
			<div class="container">
				<div class="logo"><img
						src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/lucency-Logo.png" /></div>
				<div class="logo-title fl" style="display: block;">专家入驻</div>
			</div>
		</div>
		<div class="expert-bgcont">
			<div class="container expert-bdcont">
				<div class="expert-title">填写入驻信息</div>
				<div class="expert-form">
					<div class="head">
						<el-upload class="avatar-uploader" :action="baseUrl + 'file/upload_one'"
							:data="{ dir: 'expert' }" :show-file-list="false" :on-success="handleAvatarSuccess"
							:before-upload="beforeAvatarUpload">
							<img v-if="form.img" :src="form.img" class="avatar" />
							<i v-else class="el-icon-camera-solid avatar-uploader-icon"></i>
						</el-upload>
					</div>
					<el-form label-position="left" :inline="true" :rules="rules" ref="ruleForm" label-width="110px"
						:model="form" class="demo-formLine-2">
						<el-col :span="12">
							<el-form-item label="真实姓名" prop="name">
								<el-input v-model="form.name" placeholder="请输入您的真实姓名" style="width: 300px;"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="邮箱" prop="mailbox">
								<el-input v-model="form.email" placeholder="如:keji6@163.com" style="width: 300px;">
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="所在地" prop="city">
								<el-select v-model="form.province" @change="provice_change()" placeholder="请选择">
									<el-option v-for="(v, k) in citylist" :key="k" :label="v.p" :value="v.p">
									</el-option>
								</el-select>
								<el-select v-model="form.city" @change="city_change()" placeholder="请选择">
									<template v-if="citylist[province_key] && citylist[province_key].c.length > 0">
										<el-option v-for="(v, k) in citylist[province_key].c" :key="k" :label="v.n"
											:value="v.n"></el-option>
									</template>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="产业领域" prop="territory_three_name">
								<el-select v-model="form.territory_one_name" @change="territory_one_change()"
									placeholder="请选择">
									<el-option v-for="(v, k) in industrylist" :key="k" :label="v.name" :value="v.name">
									</el-option>
								</el-select>
								<el-select v-model="form.territory_two_name" @change="territory_two_change()"
									placeholder="请选择">
									<template v-if="industrylist[territory_one]">
										<el-option v-for="(v, k) in industrylist[territory_one].childrens" :key="k"
											:label="v.name" :value="v.name"></el-option>
									</template>
								</el-select>
								<el-select v-model="form.territory_three_name" placeholder="请选择">
									<template
										v-if="industrylist[territory_one] && industrylist[territory_one].childrens[territory_two]">
										<el-option
											v-for="(v, k) in industrylist[territory_one].childrens[territory_two].childrens"
											:key="k" :label="v.name" :value="v.name"></el-option>
									</template>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="学科分类" prop="subject">
								<el-select v-model="form.subject.cate_one" @change="subject_change()" placeholder="请选择">
									<el-option v-for="(v, k) in subjectlist" :key="k" :label="v.p" :value="v.p">
									</el-option>
								</el-select>
								<el-select v-model="form.subject.cate_two" placeholder="请选择">
									<template v-if="subjectlist[subject1_key]">
										<el-option v-for="(v, k) in subjectlist[subject1_key].c" :key="k" :label="v.n"
											:value="v.n"></el-option>
									</template>
								</el-select>
							</el-form-item>
						</el-col>

						<el-col :span="24">
							<el-form-item label="工作单位" prop="unit">
								<!-- <el-select v-model="form.unit" placeholder="请选择">
									<el-option label="区域一" value="shanghai"></el-option>
									<el-option label="区域二" value="beijing"></el-option>
								</el-select> -->
								<el-input v-model="form.danwei" placeholder="请输入您的单位名称" style="width: 300px;">
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="提供的服务">
								<div style="fuwu">
									<el-checkbox-group style="font-size: 14px;" v-model="select_service"
										@change="do_select_service()">
										<div class="fuwu_item flex" v-for="(v, k) in service" :key="k">
											<el-checkbox v-model="v.checked" :label="v.name" v-if="v.name2">
												{{ v.name2 }}</el-checkbox>
											<el-checkbox v-model="v.checked" :label="v.name" v-else>{{ v.name }}
											</el-checkbox>
											<el-input v-model="v.price" :disabled="v.checked ? false : true"
												@change="do_select_service()" placeholder="调整价格为" style="width: 300px;">
											</el-input>
											<span>/元</span>
										</div>
									</el-checkbox-group>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="头衔" prop="zhiwu">
								<el-input v-model="form.zhiwu" placeholder="请输入您的头衔" style="width: 300px;"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="职称" prop="zhicheng">
								<el-input v-model="form.zhicheng" placeholder="请输入您的职称" style="width: 300px;">
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="简介" prop="profile">
								<el-input type="textarea" v-model="form.profile" placeholder="请对您的技术进行简要描述"></el-input>
							</el-form-item>
						</el-col>
						<transition name="el-fade-in-linear">
							<div v-if="show">
								<el-col :span="24">
									<el-form-item label="关键词">
										<!-- <el-input v-model="form.tag" style="width: 940px;"></el-input> -->
										<el-tag :key="tag" v-for="tag in form.tag" closable :disable-transitions="false"
											@close="handleClose(tag)">{{ tag }}</el-tag>
										<el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue"
											ref="saveTagInput" size="small" @keyup.enter.native="handleInputConfirm"
											@blur="handleInputConfirm"></el-input>
										<el-button v-else class="button-new-tag" size="small" @click="showInput">添加关键词
										</el-button>
									</el-form-item>
								</el-col>
								<el-col :span="24">
									<el-form-item label="人才计划">
										<el-input v-model="form.talent_programme" style="width: 940px;"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="24">
									<el-form-item label="项目案例">
										<el-input type="textarea" v-model="form.yjycg"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="24">
									<el-form-item label="荣誉奖项">
										<el-input type="textarea" v-model="form.jlry"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="24">
									<el-form-item label="代表成果">
										<el-input type="textarea" v-model="form.zlcg"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="24">
									<el-form-item label="社会服务">
										<el-input type="textarea" v-model="form.shfw"></el-input>
									</el-form-item>
								</el-col>
							</div>
						</transition>
						<div class="expert-zkycbt">
							<p @click="show = !show">
								<span>{{ show ? '收起' : '填写详细信息' }}</span>
								<i :class="show ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
							</p>
						</div>
						<el-col :span="24">
							<el-form-item style="justify-content: center;">
								<el-button type="primary" @click="submitForm('ruleForm')">提交审核</el-button>
							</el-form-item>
						</el-col>
					</el-form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import citylist from '../../assets/js/city.min.js';
	import industrylist from '../../assets/js/industrylist.js';
	import subjectlist from '../../assets/js/subjectlist.js';
	import Top from "@/components/top.vue"
	export default {
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		components: {
			Top
		},
		data() {
			return {
				show: false,
				citylist: [],
				province_key: '',
				city: '',
				territory_one: '',
				territory_two: '',
				subject1_key: 0,
				subject2: '',
				territory_three_name: '',
				industrylist: [],
				subjectlist: [],
				form: {
					img: '',
					name: '',
					email: '',
					province: '',
					city: '',
					site: '',
					territory_one_name: '',
					territory_two_name: '',
					territory_three_name: '',
					subject: {
						cate_one: '',
						cate_two: ''
					},
					danwei: '',
					zhiwu: '',
					zhicheng: '',
					profile: '',
					tag: [],
					talent_programme: '',
					yjycg: '',
					jlry: '',
					zlcg: '',
					shfw: '',
					service: []
				},
				rules: {
					name: [{
						required: true,
						message: '请输入您的真实姓名'
					}],
					email: [{
						required: true,
						message: '请输入您的邮箱'
					}],
					city: [{
						required: true,
						message: '请选择您的所在地'
					}],
					territory_three_name: [{
						required: true,
						message: '请选择您的产业领域'
					}],
					subject: [{
						required: true,
						message: '请选择学科分类'
					}],
					danwei: [{
						required: true,
						message: '请输入您的单位名称'
					}],
					zhiwu: [{
						required: true,
						message: '请输入您的头衔'
					}],
					zhicheng: [{
						required: true,
						message: '请输入您的职称'
					}],
					profile: [{
						required: true,
						message: '请输入您的简要描述'
					}],
					fuwu: [{
						required: true,
						message: '请选择您提供的服务'
					}]
				},
				inputVisible: false,
				inputValue: '',
				checked1: false,
				checked2: false,
				select_service: [],
				service: [{
						name: 'pt',
						name2: '图文咨询',
						price: '',
						checked: false
					},
					{
						name: 'tel',
						name2: '电话咨询',
						price: '',
						checked: false
					},
					{
						name: 'meet',
						name2: '线下咨询',
						price: '',
						checked: false
					}
				]
			};
		},
		mounted() {
			this.citylist = citylist;
			this.industrylist = industrylist;
			this.subjectlist = subjectlist;
			this.get_detail();
		},
		methods: {
			get_detail() {
				this.$http
					.post('expert/detail', {})
					.then(res => {
						if (typeof res.data.id != 'undefined') {
							this.form = res.data;
							if (Array.isArray(res.data.img)) {
								this.form.img = res.data.img[0];
							}
							let that = this;
							if (typeof this.form.subject.cate_one != 'undefined') {
								var cate_one = this.form.subject.cate_one;
								this.subjectlist.forEach(function(v, k) {
									if (v.p == cate_one) {
										that.subject1_key = k;
									}
								});
							}
							if (typeof res.data.tag == 'undefined' || res.data.tag == null) {
								this.form.tag = [];
							}
							if (Array.isArray(this.form.service)) {
								this.service = this.form.service;
								let that = this;
								this.service.forEach(function(v) {
									if (v.checked) {
										that.select_service.push(v.name);
									}
								});
								console.log(this.select_service);
							}
							if (typeof res.data.subject == 'undefined') {
								this.form.subject = {
									cate_one: '',
									cate_two: ''
								};
							}
						}
					})
					.catch(err => {
						console.log(err);
					});
			},
			do_select_service() {
				var that = this;
				this.select_service.forEach(function(v) {
					that.service.forEach(function(v2) {
						if (v == v2.name) {
							v2.checked = true;
						}
					});
				});
				this.form.service = this.service;
			},
			provice_change() {
				let that = this;
				that.citylist.forEach(function(v, k) {
					if (that.form.province == v.p) {
						that.province_key = k;
					}
				});
				that.form.city = '';
			},
			territory_one_change() {
				let that = this;
				this.industrylist.forEach(function(v, k) {
					if (v.name == that.form.territory_one_name) {
						that.territory_one = k;
					}
				});
				this.form.territory_two_name = '';
				this.form.territory_three_name = '';
				// this.form.territory_one_name = this.industrylist[this.territory_one].name;
			},
			territory_two_change() {
				let that = this;
				this.industrylist[this.territory_one].childrens.forEach(function(v, k) {
					if (v.name == that.form.territory_two_name) {
						that.territory_two = k;
					}
				});
				this.form.territory_three_name = '';
				// this.form.territory_two_name = this.industrylist[this.territory_one].childrens[this.territory_two].name;
			},
			subject_change() {
				//第一开始this 都要指向that
				let that = this;
				var cate_one = this.form.subject.cate_one;
				this.subjectlist.forEach(function(v, k) {
					if (v.p == cate_one) {
						that.subject1_key = k;
					}
				});
				that.form.subject.cate_two = '';
				// let subject1 = this.subjectlist[this.subject1_key].p;
				// this.form.subject = {
				// 	cate_one: subject1,
				// 	cate_two: this.subject2
				// };
			},
			city_change() {
				let province = this.citylist[this.province_key].p;
				this.form.province = province;
			},
			handleAvatarSuccess(res) {
				if (res.code == 200) {
					this.form.img = res.data.img_url;
				}
			},
			beforeAvatarUpload(file) {
				const isIMG = file.type === 'image/jpeg' || 'image/jpg' || 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isIMG) {
					this.$message.error('上传头像图片只能是 JPG/JPEG/PNG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isIMG && isLt2M;
			},
			submitForm(formName) {
				this.$refs[formName].validate(valid => {
					if (!valid) {
						console.log('error submit!!');
						return false;
					}
					let add_data = this.form;
					if (typeof add_data.id == 'undefined') {
						var url = 'expert/add';
						var http = this.$http.post(url, add_data);
					} else {
						url = 'expert/update';
						http = this.$http_qm.post(url, add_data);
					}
					http.then(res => {
						this.$message.info(res.msg);
						this.$router.push({
							path: '/'
						});
					}).catch(err => {
						console.log(err);
					});
				});
			},
			handleClose(tag) {
				this.form.tag.splice(this.form.tag.indexOf(tag), 1);
			},
			showInput() {
				this.inputVisible = true;
				this.$nextTick(() => {
					this.$refs.saveTagInput.$refs.input.focus();
				});
			},

			handleInputConfirm() {
				let inputValue = this.inputValue;
				if (inputValue) {
					this.form.tag.push(inputValue);
				}
				this.inputVisible = false;
				this.inputValue = '';
			}
		}
	};
</script>

<style lang="less">
	#add {
		padding: 0;
		margin: 0;

		.expert_tbbg {
			width: 100%;
			height: 90px;
			padding-top: 17px;
			padding-bottom: 17px;
			background-color: #ffffff;

			.container {
				margin: 0 auto;
				width: 1200px;

				.logo {
					float: left;

					img {
						display: block;
						width: 90px;
						height: 90px;
					}
				}

				.logo-title {
					margin: 25px 0 0 20px;
					padding: 0 0 0 20px;
					height: 38px;
					border-left: 2px solid #0088ff;
					font-size: 26px;
					line-height: 38px;
					color: #666;
				}
			}
		}

		.expert-bgcont {
			width: 100%;
			height: auto;
			background-color: #f5f5f5;
			overflow: hidden;

			.expert-bdcont {
				background-color: #fff;
				padding: 90px 20px;
				position: relative;

				.expert-title {
					width: 100%;
					height: 16px;
					border-left: #00a3e7 4px solid;
					font: 16px/16px '微软雅黑';
					color: #333;
					margin: 16px 0px;
					text-indent: 10px;
				}

				.expert-form {
					width: 100%;
					height: auto;
					overflow: hidden;

					.demo-formLine-2 {
						.el-select {
							margin-right: 10px;
						}

						.el-form-item {
							display: flex;
							flex-direction: row;
						}

						.el-form-item__content {
							display: flex;
							flex-direction: row;
						}

						.el-textarea__inner {
							min-height: 90px !important;
							font-size: 16px;
							width: 940px;
						}

						.el-tag {
							margin-left: 10px;
						}

						.button-new-tag {
							margin-left: 10px;
							height: 32px;
							line-height: 30px;
							padding-top: 0;
							padding-bottom: 0;
						}

						.input-new-tag {
							width: 90px;
							margin-left: 10px;
							vertical-align: bottom;
						}

						.fuwu {
							display: flex;
							flex-direction: row;
						}

						.fuwu_item {
							height: 63px;

							.el-input__inner {
								width: 150px;
							}

							.el-input {
								width: 150px !important;
								margin: 0 10px;
							}
						}
					}

					.head {
						display: block;
						position: absolute;
						top: -70px;
						left: 550px;
						width: 128px;
						height: 128px;
						border: #ffffff 3px solid;
						border-radius: 50%;
						background: #333;
						overflow: hidden;

						.avatar-uploader .el-upload {
							border-radius: 6px;
							cursor: pointer;
							position: relative;
							overflow: hidden;
						}

						.avatar-uploader .el-upload:hover {
							border-color: #409eff;
						}

						.avatar-uploader-icon {
							font-size: 40px;
							color: #ffffff;
							width: 128px;
							height: 128px;
							line-height: 128px;
							text-align: center;
						}

						.avatar {
							width: 128px;
							height: 128px;
							display: block;
						}

						.el-upload {
							border: none !important;
						}
					}

					.expert-zkycbt {
						width: 120px;
						height: 40px;
						margin: 20px auto;
						display: flex;

						p {
							width: 120px;
							height: 40px;
							cursor: pointer;

							span {
								font: 14px/40px '微软雅黑';
								color: #666;
								margin-right: 8px;
							}

							.icon-xlan {
								width: 12px;
								height: 7px;
							}
						}
					}
				}
			}

			.container {
				margin: 0 auto;
				width: 1200px;
				margin: 110px auto 50px;
			}
		}
	}
</style>
