<template>
	<div id="vip01_product_detail">
		<Header :data="data" :id="id"></Header>
		<div class="pro_banner"><img src="../../../../assets/img/template/pro_banner.png" alt=""></div>
		<div class="product wrap">
			<el-tabs v-model="activeName">
				<el-tab-pane label="产品分类" name="first"></el-tab-pane>
				<div class="product_type">

					<!--展示图-->
					<div class="pro_img" id="img_big">
						<pic-zoom v-if="productData.cover" :url="productData.cover[index]" :scale="3"></pic-zoom>
					</div>

					<div class="pro_info">
						<p class="pro_type">
							<span v-for="(i,k) in productData.tag" :key="k">
								{{i}}
							</span>
						</p>
						<h3 class="single-line">{{productData.title}}</h3>
						<div class="pro_info_text">
							<p class="single-line">发布时间：{{productData.created_at}}</p>
							<p class="double-line">最小采购量：{{productData.minimum_purchase}}</p>
						</div>
						<div class="pro_buy">
							<p><span>￥</span>{{productData.price}}</p>
							<el-button type="primary">咨询购买</el-button>
						</div>
						<div class="pro_img_little" id="img_small">
							<ul>
								<li :class="index == i ? 'on' :''" v-for="(item,i) in productData.cover" :key="i" @click='getIndex(i)'>
									<el-image style="width: 84px; height: 84px" :src="item" fit="cover"></el-image>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="goods_datail">
					<h3>商品详情</h3>
					<div class="content" v-html="productData.introduction"></div>
				</div>
			</el-tabs>

		</div>
		<Footer :data="data" :id="id"></Footer>
	</div>
</template>

<script>
	import PicZoom from 'vue-piczoom'
	import Header from "./header.vue"
	import Footer from "./footer.vue"
	export default {
		components: {
			PicZoom,
			Header,
			Footer
		},
		data() {
			return {
				activeName: 'first',
				id: '',
				data: {},
				productID: '',
				productData: {},
				index: 0,
			}
		},

		mounted() {
			let query = this.$route.query;
			this.id = query.id;
			this.productID = query.productID;
			this.get_detail();
			this.product_detail();
		},
		methods: {
			get_detail() {
				this.$http_qm.post('company/detail', {
					id: this.id
				}).then(res => {
					this.data = res.data;
				}).catch(err => {
					console.log(err);
				})
			},
			product_detail() {
				this.$http_qm.post('product/detail', {
					id: this.productID
				}).then(res => {
					this.productData = res.data;
					console.log(this.productData)
				}).catch(err => {
					console.log(err);
				})
			},
			getIndex(i) {
				this.index = i;
			},
		}
	}
</script>

<style lang="less">
	.mouse-cover-canvas {
		width: 300px;
		height: 300px;
		position: fixed;
		background: rgba(255, 255, 255, 0.5);
		border: 1px solid #da9974;
		cursor: move;
		left: 920px !important;
		top: calc(100vh - 44.7vh) !important;
		display: none;
	}

	#vip01_product_detail {

		.pro_banner {
			height: 280px;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.el-tabs__item {
			height: 36px;
			line-height: 36px;
			width: 75px;
			margin: 15px 15px 15px 0;
			padding: 0;
			text-align: center;
		}

		.is-active {
			color: #fff;
			background-color: #0088ff;
		}

		.el-tabs__active-bar {
			background-color: none;
		}

		.wrap {
			width: 1200px;
			margin: 0 auto;

			.product_type {
				width: 900px;
				height: 400px;
				padding: 40px 0;
				margin: 0 auto;
				position: relative;

				.mouse-cover-canvas {
					width: 140px;
					height: 140px;
					position: absolute;
					background: rgba(255, 255, 255, 0.5);
					border: 1px solid #da9974;
					cursor: move;
					left: 258px;
					top: 188px;
					display: none;
				}

				.pro_img {
					float: left;
					width: 400px;
					height: 400px;
					border: 1px solid #e6e6e6;
					margin-top: -1px;
					margin-left: -1px;
					position: relative;
					cursor: pointer;

				}

				.pro_info {
					float: right;
					width: 480px;
					height: 400px;

					.pro_type {
						font-size: 14px;
						line-height: 20px;
						color: #999;
					}

					h3 {
						font-size: 30px;
						height: 60px;
						line-height: 48px;
						color: #333;
					}

					.pro_info_text {
						padding: 20px 0;
						border-top: 1px solid #e6e6e6;
						border-bottom: 1px solid #e6e6e6;

						p {
							font-size: 14px;
							line-height: 32px;
							color: #333;
						}

						.double-line {
							height: 64px;
						}
					}

					.pro_buy {
						height: 76px;
						line-height: 76px;
						border-bottom: 1px solid #e6e6e6;

						p {
							float: left;
							display: inline-block;
							width: 146px;
							height: 76px;
							line-height: 76px;
							font-size: 30px;
							color: #d80000;

							span {
								font-size: 16px;
								padding: 0 2px;
							}
						}
					}

					.pro_img_little {
						height: auto;
						overflow: hidden;
						padding-top: 20px;

						ul {
							padding: 0;
						}

						li {
							display: inline-block;
							box-sizing: border-box;
							margin-right: 12px;
						}

						.on {
							border: 3px solid #ff7720;
						}

						.el-image {
							overflow: initial;
						}
					}
				}
			}

			.goods_datail {
				height: auto;
				overflow: hidden;
				padding-top: 25px;
				padding-bottom: 36px;
				border-top: 1px solid #e6e6e6;

				h3 {
					font-size: 20px;
					color: #333;
					line-height: 60px;
				}

				.content {
					padding: 0;
					margin: 0;
				}
			}
		}

	}
</style>
