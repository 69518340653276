<template>
	<div id="detail">
		<Top></Top>
		<div class="newpolicy-detail">

			<div class="content-view">
				<div class="left-detail">
					<div class="title-view">
						<p class="title">
							<el-breadcrumb separator-class="el-icon-arrow-right">
								<el-breadcrumb-item :to="{ path: '/news' }">新闻中心</el-breadcrumb-item>
								<el-breadcrumb-item>{{data.title}}</el-breadcrumb-item>
							</el-breadcrumb>
						</p>
						<h2 class="news_title">
							{{data.title}}
						</h2>
						<div class="fot">
							<p class="notice-num">
								<span>浏览量：{{data.view_num}}</span>
								<span>{{data.created_at}}</span>
							</p>
						</div>
					</div>

					<div class="news-info">
						<div v-html="data.content">
						</div>
					</div>
					<div class="news-fot">
						<div class="give-like" @click="do_support(2,data.id,data.is_support,0)">
							<!-- <i :class="data.is_support ? 'likeLill-icon' : 'like-icon'"></i> -->
							<span>{{data.is_support ?  '点赞' : '已点赞'}}</span>
						</div>
						<div class="share-list">
							<i class="el-icon-share"></i>
							<share :config="config"></share>
						</div>
					</div>
					<div class="comment-view">
						<p class="head">评论</p>
						<div class="input-view">
							<div class="no-log" v-if="!token">
								<span @click="listNew('/login')">登录</span>后发表评论
							</div>
							<div class="log">
								<el-input v-model="comment_content" type="textarea" :rows="6">
								</el-input>
							</div>
							<div class="handle-bar">
								<div class="user-info" v-if="token">
									<img :src="user.avatar" alt="">
									<!-- <el-image
									      style="width: 30px; height: 30px"
									      :src="user.avatar"
									      fit="cover"></el-image> -->
									<span class="name">{{user.username}}</span>
								</div>
								<button v-if="token" @click="do_comment()" type="button"
									class="el-button btn el-button--primary">
									<span>提交评论</span>
								</button>
								<button v-else @click="listNew('/login')" type="button"
									class="el-button btn el-button--primary">
									<span>登录后发表评论</span>
								</button>
							</div>
						</div>
					</div>
					<div class="comment-list" v-if="comment_list.data.length>0">
						<div class="list-item" v-for="(v,k) in comment_list.data" :key="k">
							<div class="avatar" v-if="v.user_info">
								<img :src="v.user_info.avatar" alt="" class="avatar">
							</div>
							<div class="item-content">
								<p class="notice" v-if="v.user_info">{{v.user_info.username}}：</p>
								<p class="text">
									<span>{{v.content}}</span>
								</p>
								<div class="item-fot">
									<div class="time">{{v.created_at}}</div>
									<div class="handle">
										<p class="like" @click="do_support(3,v.id,v.is_support,k)">
											<i class="like-icon"></i>
											<span>{{v.support_num}}</span>
										</p>
										<!-- <p class="add-comment" @click="click_reply(k)">
											<i class="el-icon-chat-dot-square"></i>
											<span>{{v.comment_num}}</span>
										</p> -->
										<p class="delete-comment" v-if="v.uid==user.id" @click="del_comment(v.id,k,1)">
											<span>删除</span>
										</p>
									</div>
								</div>
								<div v-show="v.reply_show">
									<div class="input-view">
										<div class="log">
											<el-input v-model="reply_content" type="textarea" :rows="6">
											</el-input>
										</div>
										<div class="handle-bar">
											<div class="user-info" v-if="v.user_info">
												<span class="name">回复@{{v.user_info.username}}</span>
											</div>
											<button v-if="!token" @click="listNew('/login')" type="button"
												class="el-button btn el-button--primary">
												<span>登录后发表评论</span>
											</button>
											<button type="button" @click="reply(v.id,v.id,k)" v-else
												class="el-button btn el-button--primary">
												<span>提交评论</span>
											</button>
										</div>
									</div>

									<template v-if="v.child&&v.child.length>0">
										<div class="list-item" v-for="(v2,k2) in v.child" :key="k2">
											<div class="avatar" v-if="v2.user_info">
												<img :src="v2.user_info.avatar" alt="" class="avatar">
											</div>
											<div class="item-content">
												<p class="notice">说：</p>
												<p class="text">
													<span>{{v2.content}}</span>
												</p>
												<div class="item-fot">
													<div class="time">{{v2.created_at}}</div>
													<div class="handle">
														<p class="like" @click="do_support(3,v2.id,v2.is_support,k,k2)">
															<i class="like-icon"></i>
															<span>{{v2.support_num}}</span>
														</p>
														<p class="delete-comment" @click="del_comment(v2.id,k,2)">
															<span>删除</span>
														</p>
													</div>
												</div>
											</div>
										</div>
									</template>
								</div>
							</div>
						</div>
					</div>
					<div class="nodate" v-else>
						<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/no.feebf58.png">
						<p>暂无记录</p>
					</div>
				</div>
				<div class="right-list">
					<div class="fixed-status">
						<div class="pannel-title">
							<span>热门新闻</span>
						</div>
						<div class="list-view">
							<ul class="list">
								<li v-for="(v,k) in hot_news" @click="href_self('/news/detail',v.id)" :key="k">
									<span class="nuxt-link-active">{{v.title}}</span>
									<span class="time">{{v.created_at}}</span>
								</li>
							</ul>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Top from "@/components/top.vue"
	import {
		mapGetters
	} from 'vuex'
	export default {
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		data() {
			return {
				likeIcon: true,
				comment: false,
				config: {
					title: '科技牛需求分享',
					description: '科技牛最新需求链接内容详情分享',
					url: 'http://pc.keji6.cn/#/demand/detail?id=' + this.id,
					source: 'http://pc.keji6.cn/#/demand/detail?id=' + this.id,
					image: 'http://hellofood.fun/webicon.png',
					// summary : '吃货召唤', //相当于description
					sites: ['qzone', 'qq', 'weibo', 'wechat'], // 启用的站点
					// disabled: ['google', 'facebook', 'twitter', 'linyin'], // 禁用的站点
					wechatQrcodeTitle: "微信扫一扫：分享", // 微信二维码提示文字
					wechatQrcodeHelper: '立即下载发送专属二维码,邀请朋友加入',
				},
				id: '',
				data: {},
				reply_content: '',
				comment_content: '',
				comment_list: {
					data: [],
					page: 1,
					limit: 10,
				},
				hot_news: [],
			}
		},
		components: {
			Top
		},
		computed: {
			...mapGetters(['token', 'user'])
		},
		mounted() {
			this.id = this.$route.query.id;
			this.get_detail();
			this.get_comment_list();
			this.get_hot_list();
		},
		methods: {
			href_self(path, id) {
				this.$router.push({
					path: path,
					query: {
						id: id
					}
				})
				this.id = this.$route.query.id;
				this.get_detail();
				this.get_comment_list();
				this.get_hot_list();
			},
			get_hot_list() {
				this.$http_qm.post('news/list', {
					request_type: 'recommended',
					limit: 10,
					page: 1
				}).then(res => {
					this.hot_news = res.data
				}).catch(err => {
					console.log(err)
				})
			},
			do_support(type, support_id, is_support, comment_index, comment_index2 = -1) {

				this.$http_qm.post('support/add', {
					type: type,
					support_id: support_id,
					is_support: is_support,
				}).then(res => {
					if (res.code == 200) {
						if (type == 2) {
							this.data.is_support = res.data.is_support;
						} else if (type == 3) {
							this.comment_list.data[comment_index].is_support = res.data.is_support;
							if (comment_index2 >= 0) {
								if (res.data.is_support == 0) {
									this.comment_list.data[comment_index].child[comment_index2].support_num -= 1;
								} else {
									this.comment_list.data[comment_index].child[comment_index2].support_num += 1;
								}
							} else {
								if (res.data.is_support == 0) {
									this.comment_list.data[comment_index].support_num -= 1;
								} else {
									this.comment_list.data[comment_index].support_num += 1;
								}

							}
						}
						this.$forceUpdate();
					}
				}).catch(err => {
					console.log(err)
				})

			},
			do_collect(type, collect_id, is_collect) {

				this.$http_qm.post('collect/add', {
					type: type,
					collect_id: collect_id,
					is_collect: is_collect
				}).then(res => {
					if (res.code == 200) {

						this.data.is_support = res.data.is_support
						this.$forceUpdate();
					}
				}).catch(err => {
					console.log(err)
				})

			},
			del_comment(id, index, level) {
				this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http_qm.post('comment/delete', {
						"id": id
					}).then(res => {
						this.$message.info(res.msg)
						if (level == 1) {
							this.comment_list.data.splice(index, 1);
							this.$message({
								type: 'success',
								message: '删除成功!'
							});
						} else {
							this.comment_list.data[index].child.splice(index, 1);
						}
					}).catch(err => {
						console.log(err)
					})



				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});


			},
			do_comment() {
				this.comment_fun(this.comment_content, this.id, '', '', 0)
			},
			reply(reply_id, one_comment_id, one_comment_index) {
				this.comment_fun(this.reply_content, this.id, reply_id, one_comment_id, one_comment_index)
			},
			comment_fun(comment_content, id, reply_id, one_comment_id, one_comment_index) {
				this.$http_qm.post('comment/add', {
					type: 1,
					content: comment_content,
					type_id: id,
					reply_id: reply_id,
					one_comment_id: one_comment_id,
				}).then(res => {
					this.$message.info(res.msg)

					if (res.code == 200) {
						console.log(one_comment_index)
						this.get_comment_list();
						if (one_comment_id == 0) {
							this.comment_content = ''
							/* this.comment_list.data.unshift({
								content: comment_content,
								user_info: {
									'username': this.user.username,
									'avatar': this.user.avatar
								}
							}) */


						} else {
							this.reply_content = '';
							/* this.comment_list.data[one_comment_index].child.unshift({
								content: comment_content,
								user_info: {
									'username': this.user.username,
									'avatar': this.user.avatar
								}
							})
								this.comment_list.data[one_comment_index].comment_num+=1; */
						}
					}
					this.$forceUpdate();
				}).catch(err => {
					console.log(err);
				})
			},
			click_reply(index) {
				this.comment_list.data[index].reply_show = !this.comment_list.data[index].reply_show
				this.$forceUpdate();
			},
			get_comment_list() {
				this.$http_qm.post('comment/list', {
					type: 1,
					page: this.comment_list.page,
					id: this.id,
					limit: this.comment_list.limit,
				}).then(res => {
					if (res.code == 200) {
						if (res.data) {
							this.comment_list.data = res.data
						}

					}
				}).catch(err => {
					console.log(err);
				})
			},
			get_detail() {
				this.$http_qm.post('news/detail', {
					id: this.id
				}).then(res => {
					this.data = res.data;

				}).catch(err => {
					console.log(err);
				})
			},
			listNew(url) {
				this.$router.push({
					path: url
				})
			}
		}
	}
</script>
<style scoped>
>>> .news-info img {
  width: 100%;
}
</style>
<style lang="less" src="../../assets/css/news.less"></style>
