<template>
	<div id="vip01_product">
		<Header :data="data" :id="id"></Header>
		<div class="pro_banner"><img src="../../../../assets/img/template/pro_banner.png" alt=""></div>
		<div class="product wrap">
			<el-tabs v-model="activeName">
				<el-tab-pane label="产品分类" name="first">
						<div class="product_list">
							<div class="a" v-for="(item,index) in product.list" :key="index" >
								<dl @click="detail(item.id)">
									<dt>
										<el-image v-if="item.cover" style="width: 274px; height: 274px;" :src="item.cover[0]" fit="cover"></el-image>
										<div class="show_shade">
											<p>DETAIL</p>
											<span><i></i></span>
										</div>
									</dt>
									<dd class="strip"></dd>
									<dd class="pro_name">{{item.title}}</dd>
								</dl>
							</div>
						</div>
					<div class="page">
						<el-pagination :page-size="product.limit" :current-page="currentPage" @current-change="handleCurrentChange" background layout="prev, pager, next" :total="product.count"></el-pagination>
					</div>
				</el-tab-pane>
				<!-- <el-tab-pane label="行业动态" name="second" disabled></el-tab-pane> -->
			</el-tabs>

		</div>
		<Footer ref="mychild" :data="data" :id="id"></Footer>
	</div>
</template>

<script>
	import Header from "./header.vue"
	import Footer from "./footer.vue"
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				activeName: 'first',
				id: '',
				data: {},
				product: {
					list: [],
					limit: 8,
					page: 1,
					count: 0,
				},
				currentPage:1
			}
		},
		watch:{
			$route(){
				let query = this.$route.query
				if(typeof(query.page) != 'undefined'){
					this.product.page = parseInt(query.page);
					this.currentPage = parseInt(query.page);
				}
				this.get_list();
			},
		},
		created() {
			var querys = this.$route.query
			if(typeof(querys.page) != 'undefined'){
				this.product.page = parseInt(querys.page);
				this.currentPage = parseInt(querys.page);
			}else{
				this.product.page = 1;
				this.currentPage = 1;
			}
		},
		mounted() {
			let query = this.$route.query;
			this.id = query.id;
			this.get_detail();
		},
		methods: {
			get_detail() {
				this.$http_qm.post('company/detail', {
					id: this.id
				}).then(res => {
					this.data = res.data;
					this.$refs.mychild.link_list(this.data.id);
					this.list();
				}).catch(err => {
					console.log(err);
				})
			},
			list() {
				this.$http_qm.post('product/list', {
					limit: this.product.limit,
					page: this.product.page,
					uid: this.data.uid,
					request_type: 'store'
				}).then(res => {
					this.product.list = res.data;
					this.product.count = res.count;
				}).catch(err => {
					console.log(err);
				})
			},
			handleCurrentChange(page) {
				this.$router.push({
					path: '/c/vip01/product',
					query: {
						page: page
					}
				});
			},
			detail(id){
				this.$router.push({
					path: '/c/vip01/product_detail',
					query:{
						id:this.id,
						productID:id,
					}
				})
			}
		}
	}
</script>

<style lang="less">
	#vip01_product {
		.pro_banner {
			height: 280px;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.el-tabs__item {
			height: 36px;
			line-height: 36px;
			width: 75px;
			margin: 15px 15px 15px 0;
			padding: 0;
			text-align: center;
		}

		.is-active {
			color: #fff;
			background-color: #0088ff;
		}

		.el-tabs__active-bar {
			background-color: none;
		}

		.wrap {
			width: 1200px;
			margin: 0 auto;
		}
		.product_list {
		    height: auto;
		    overflow: hidden;
		    padding-top: 50px;
			display: flex;
			flex-wrap: wrap;
			.a {
			    float: left;
			    margin: 0 13px 34px;
			    transition: all .3s;
			    position: relative;
			    top: 0;
				&:hover{
					.pro_name {
					    box-shadow: 0 0 10px rgba(155,143,143,.5);
					}
					transform: translateY(-8px);
				}
				dt {
				    width: 274px;
				    height: 274px;
				    position: relative;
					&:hover{
						.show_shade{
							display: block;
						}
						img{
							opacity: 0.5;
						}
						
					}
					img {
					    width: 100%;
					    height: 100%;
					    overflow: hidden;
					}
					.show_shade {
					    display: none;
						p {
						    display: block;
						    width: 80px;
						    height: 80px;
						    line-height: 80px;
						    font-size: 14px;
						    color: #fff;
						    text-align: center;
						    position: absolute;
						    left: 50%;
						    top: 50%;
						    margin-left: -40px;
						    margin-top: -40px;
						    z-index: 2;
						}
						span {
						    display: block;
						    width: 80px;
						    height: 80px;
						    background: #da9974;
						    position: absolute;
						    left: 50%;
						    top: 50%;
						    margin-left: -40px;
						    margin-top: -40px;
						    box-shadow: 5px 5px 5px #807068;
						    transform: rotate(45deg);
						    z-index: 1;
							i {
							    display: block;
							    width: 60px;
							    height: 60px;
							    margin: 9px;
							    border: 1px solid #fff;
								font-style: normal;
								font-size: 9px;
								font-weight: 400;
							}
						}
					}
				}
				.strip {
				    height: 4px;
				    background: #e6e6e6;
				}
				.pro_name {
				    font-size: 14px;
				    color: #333;
				    height: 56px;
				    line-height: 56px;
				    text-align: center;
				}
			}
		}

		.page {
			margin: 20px 0 40px 0px;
			text-align: center;
		}
	}
</style>
