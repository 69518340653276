<template>
	<div id="homepage">
		<Header :data="data" :id="id"></Header>
		<div class="block animated wow zoomIn">
			<el-carousel height="600px" v-if="linkList.list&&linkList.list.length>0">
				<el-carousel-item v-for="(item,index) in linkList.list" :key="index">
					<router-link target="_blank" :to="item.link">
					<el-image style="width: 100%; height: 600px" :src="item.img" fit="cover" :alt="item.title"></el-image>
					</router-link>
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="product_show wrap">
			<div class="product_show_header ">
				<h3>产品展示</h3>
				<p>PRODUCT<span><i></i></span></p>
			</div>
			<div class="product_show_box">
				<div class="show_this">
					<ul>
						<li v-for="(item,index) in new_product.list" :key="index" class="animated wow flipInY">
							<div class="a" @click="productDetail(item.id)">
								<dt>
									<el-image v-if="item.cover" style="width: 300px; height: 300px;" :src="item.cover[0]" fit="cover"></el-image>
									<div class="show_shade">
										<p>DETAIL</p>
										<span><i></i></span>
									</div>
								</dt>
								<!-- <el-image v-if="item.cover" style="width: 300px; height: 300px;" :src="item.cover[0]" fit="cover"></el-image> -->
								<p>{{item.title}}</p>
							</div>
						</li>
					</ul>
				</div>
				<div class="button">
					<span class="lastPage" @click="toggleFun(-1)"><i></i></span>
					<span class="nextPage" @click="toggleFun(1)"><i></i></span>
				</div>
			</div>
		</div>
		<div class="about_us">
			<div class="wrap">
				<div class="about_us_left">
					<div class="title_box animated wow slideInDown"><span>公司简介</span></div>
					<h3 class="fadeInLeft animated wow slideInDown">ABOUT US</h3>
					<div class="left_img"><img src="../../../../assets/img/template/aboutUs.png" alt=""></div>
					<div class="left_base">
						<p>我们</p>
						<p>用心服务每一位客户</p>
						<p>WHOLE-HEARTED SERVICE</p>
						<p>EVERY CUSTOMERS</p>
					</div>
				</div>
				<div class="about_us_right bounceInRight">
					<h3 class="animated wow fadeInRight">{{data.name}}<span></span></h3>
					<p class=" animated wow fadeInRight">{{data.introduction}}</p>
					<router-link :to="{path:'/c/vip01/introduction',query:{id:id}}" class="enter animated wow bounce"></router-link>
				</div>
			</div>
		</div>
		<div class="news_feed wrap">
			<div class="feed_box animated wow fadeInUp" v-for="(v,k) in news.list" :key="k" v-show="k>0">
				<div class="feed_timer"><span>{{v.created_at.substring(5,7)}}</span><i>{{v.created_at.substring(8,10)}}</i></div>
				<span class="feed_title double-line"  @click="detail(v.id)">{{v.title}}</span>
				<p>{{v.describe}}</p>
				<div class="look_more animated wow swing"  @click="detail(v.id)">查看详情+</div>
			</div>
			<div class="news_feed_right" v-if="news.list[0]" @click="detail(news.list[0].id)">
				<div class="title_box animated wow fadeInDown"><span>新闻动态</span></div>
				<h3 class="fadeInRight animated wow">NEWS</h3>
				<div class="right_img">
					<el-image v-if="news.list[0].cover[0]" style="width: 400px; height: 250px;" :src="news.list[0].cover[0]" fit="cover"></el-image>
				</div>
				<p class="news_title">{{news.list[0].title}}</p>
				<p class="double-line animated wow fadeIn">{{news.list[0].describe}}</p>
			</div>
			<router-link :to="{path:'/c/vip01/news',query:{id:id}}" class="enter bounce animated wow bounce"></router-link>
		</div>
		<Footer ref="mychild" :data="data" :id="id"></Footer>
	</div>
</template>

<script>
	import Header from "./header.vue"
	import Footer from "./footer.vue"
	import WOW from 'wowjs'
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				id: '',
				data: {},
				new_product: {
					list: [],
					limit: 8,
					page: 1,
					count: 0,
				},
				news: {
					page: 1,
					limit: 4,
					status: '',
					request_type: 'me',
					list: [],
					count: 0,
				},
				linkList:{
					list:[],
					limit:5,
					page:1
				}
			}
		},
		mounted() {
			new WOW.WOW().init();
			let query = this.$route.query;
			this.id = query.id;
			this.get_detail();
		},
		methods: {
			get_detail() {
				this.$http_qm.post('company/detail', {
					id: this.id
				}).then(res => {
					this.data = res.data;
					this.linkList.company_id = this.data.id;
					this.get_new_product()
					this.get_list();
					this.banner_list()
					 this.$refs.mychild.link_list(this.data.id);
				}).catch(err => {
					console.log(err);
				})
			},
			banner_list(){
				let data = this.linkList;
				this.$http_qm.post('company_banner/list', data).then(res => {
					if (res.code == 200) {
						this.linkList.list = res.data;
					}
				}).catch(err => {
					console.log(err);
				})
			},
			get_new_product() {
				this.$http_qm.post('product/list', {
					limit: this.new_product.limit,
					page: this.new_product.page,
					uid: this.data.uid,
					request_type: 'store'
				}).then(res => {
					this.new_product.list = res.data;
					this.new_product.count = res.count;
				}).catch(err => {
					console.log(err);
				})
			},
			get_list() {
				this.$http_qm.post('news/list', this.news).then(res => {
					this.news.list = res.data != null ? res.data : [],
					this.news.count = res.count
				}).catch(err => {
					console.log(err)
				})
			},
			toggleFun(p) {
				this.new_product.list = this.new_product.list.map((item, index, array) => {
					if (index === array.length - 1 && p === 1) {
						item = array[0]
					} else if (index === 0 && p === -1) {
						item = array[array.length - 1];
					} else {
						item = array[index + p];
					}
					return item;
				})
			},
			detail(id) {
				this.$router.push({
					path: '/news/detail',
					query:{id:id}
				})
			},
			productDetail(id){
				this.$router.push({
					path: '/c/vip01/product_detail',
					query:{
						id:this.id,
						productID:id,
					}
				})
			}
		}
	}
</script>

<style lang="less">
	#homepage {
		.wrap {
			width: 1200px;
			margin: 0 auto;
		}

		.product_show {
			height: auto;
			overflow: hidden;
			padding: 50px 0 30px;

			.product_show_header {
				width: 364px;
				margin: 0 auto;
				text-align: center;

				h3 {
					font-size: 30px;
					color: #333;
					line-height: 32px;
				}

				p {
					display: inline-block;
					width: 364px;
					height: 44px;
					line-height: 40px;
					font-size: 16px;
					color: #999;
					border-bottom: 1px solid #e6e6e6;
					position: relative;

					span {
						display: block;
						width: 36px;
						height: 10px;
						background: #fff;
						position: absolute;
						left: 50%;
						margin-left: -18px;

						i {
							display: block;
							width: 10px;
							height: 10px;
							background: #0088ff;
							margin-left: 13px;
							transform: rotate(-45deg);
						}
					}
				}
			}

			.product_show_box {
				height: auto;
				overflow: hidden;
				padding: 30px 75px;
				position: relative;

				.show_this {
					width: 1050px;
					height: 350px;
					overflow: hidden;
					position: relative;

					ul {
						display: flex;
						flex-direction: row;

						li {
							//高能部分，flex不太好解释
							width: 31.33333%;
							flex-shrink: 0;
							padding-left: 2%;
							text-align: center;
							cursor: pointer;

							&:hover {
								p {
									color: #0088ff;
								}
							}

							.a {
								display: block;
								width: 100%;
								height: 100%;
								box-sizing: border-box;
								dt {
								    position: relative;
									&:hover{
										.show_shade{
											display: block;
										}
										img{
											opacity: 0.5;
										}
										
									}
									.show_shade {
									    display: none;
										p {
										    display: block;
										    width: 80px;
										    height: 80px;
										    line-height: 80px;
										    font-size: 14px;
										    color: #fff;
										    text-align: center;
										    position: absolute;
										    left: 50%;
										    top: 50%;
										    margin-left: -40px;
										    margin-top: -40px;
										    z-index: 2;
										}
										span {
										    display: block;
										    width: 80px;
										    height: 80px;
										    background: #da9974;
										    position: absolute;
										    left: 50%;
										    top: 50%;
										    margin-left: -40px;
										    margin-top: -40px;
										    box-shadow: 5px 5px 5px #807068;
										    transform: rotate(45deg);
										    z-index: 1;
											i {
											    display: block;
											    width: 60px;
											    height: 60px;
											    margin: 9px;
											    border: 1px solid #fff;
												font-style: normal;
												font-size: 9px;
												font-weight: 400;
											}
										}
									}
								}
							}

							p {
								display: block;
								height: 40px;
								line-height: 40px;
							}
						}
					}
				}

				span {
					display: block;
					width: 35px;
					height: 35px;
					background: #fff;
					border: 1px solid #0088ff;
					cursor: pointer;

					&:hover {
						background: #0088ff;

						i {
							border-bottom: 1px solid #fff;
							border-right: 1px solid #fff;
						}
					}

					i {
						float: left;
						width: 15px;
						height: 15px;
						border-bottom: 1px solid #0088ff;
						border-right: 1px solid #0088ff;
						margin-left: 6px;
						margin-top: 6px;
						font-style: normal;
						font-size: 9px;
						font-weight: 400;
					}
				}

				.lastPage {
					position: absolute;
					left: 10px;
					top: 160px;
					-webkit-transform: rotate(135deg);
					-moz-transform: rotate(135deg);
					-ms-transform: rotate(135deg);
					-o-transform: rotate(135deg);
					transform: rotate(135deg);
				}

				.nextPage {
					position: absolute;
					right: 10px;
					top: 160px;
					-webkit-transform: rotate(-45deg);
					-moz-transform: rotate(-45deg);
					-ms-transform: rotate(-45deg);
					-o-transform: rotate(-45deg);
					transform: rotate(-45deg);
				}
			}
		}

		.about_us {
			height: 572px;
			overflow: hidden;
			background: url("../../../../assets/img/template/about_us.png") no-repeat;
			background-size: cover;

			.wrap {
				width: 1200px;
				margin: 0 auto;

				.about_us_left {
					float: left;
					width: 450px;
					height: auto;
					overflow: hidden;
					position: relative;

					.title_box {
						width: 140px;
						height: 140px;
						background: #0088ff;
						position: absolute;
						top: 50px;
						left: 30px;
						z-index: 1;

						span {
							display: block;
							width: 62px;
							height: 102px;
							padding: 10px 30px;
							font-size: 30px;
							line-height: 50px;
							color: #fff;
							border: 1px solid #fff;
							margin: 8px;
						}
					}

					h3 {
						height: 140px;
						font-size: 30px;
						color: #666;
						line-height: 200px;
						text-indent: 190px;
					}

					.left_img {
						width: 450px;
						height: 224px;

						img {
							width: 100%;
							height: 100%;
							overflow: hidden;
						}
					}

					.left_base {
						width: 184px;
						height: auto;
						overflow: hidden;
						float: right;

						p {
							display: block;
							text-align: right;
							font-size: 14px;
							line-height: 20px;
							color: #999;

							&:nth-child(1) {
								font-size: 26px;
								height: 72px;
								line-height: 86px;
								color: #0088ff;
							}

							&:nth-child(2) {
								height: 38px;
								line-height: 38px;
								font-size: 14px;
								color: #333;
								border-bottom: 1px solid #333;
								border-top: 1px solid #333;
								margin-bottom: 12px;
							}
						}
					}
				}

				.about_us_right {
					float: right;
					width: 716px;
					height: auto;
					padding-top: 120px;

					h3 {
						font-size: 26px;
						height: 60px;
						line-height: 60px;
						color: #333;

						span {
							display: block;
							width: 50px;
							height: 2px;
							background: #0088ff;
						}
					}

					p {
						height: 240px;
						padding: 26px 0;
						font-size: 14px;
						line-height: 30px;
						color: #666;
						text-indent: 30px;
						word-break: break-all;
					}

					.enter {
						display: block;
						width: 46px;
						height: 46px;
						background: url("../../../../assets/img/template/stone_icon.png") no-repeat;
						background-position: 0 0;
					}
				}
			}
		}

		.news_feed {
			height: 646px;
			overflow: hidden;
			position: relative;

			.feed_box {
				float: left;
				width: 198px;
				height: 178px;
				border: 1px solid #e6e6e6;
				padding: 100px 16px 40px;
				margin-top: 140px;
				margin-right: 34px;
				position: relative;

				&:nth-child(3) {
					margin-top: 168px;
				}

				.feed_timer {
					width: 66px;
					height: 70px;
					background: url("../../../../assets/img/template/stone_icon.png") no-repeat;
					background-position: 0 -100px;
					position: absolute;
					top: -1px;
					left: -1px;
					color: #fff;

					span {
						font-size: 14px;
						line-height: 30px;
						margin-left: 10px;
						display: block;
					}

					i {
						font-size: 30px;
						line-height: 40px;
						margin-left: 26px;
						display: block;
					}
				}

				.feed_title {
					font-size: 16px;
					line-height: 30px;
					color: #333;
					display: -webkit-box;
					margin-bottom: 26px;
					max-height: 60px;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;

					&:hover {
						color: #0088ff;
					}
				}

				p {
					font-size: 14px;
					line-height: 24px;
					color: #666;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 3;
					overflow: hidden;
					padding-top: 20px;
					border-top: 1px solid #ccc;
					height: 72px;
				}

				.look_more {
					display: block;
					width: 98px;
					height: 28px;
					background: #fff;
					border: 1px solid #e6e6e6;
					font-size: 12px;
					color: #0088ff;
					line-height: 28px;
					text-align: center;
					position: absolute;
					left: 50%;
					bottom: -14px;
					margin-left: -50px;

					&:hover {
						color: #fff;
						background: #0088ff;
						border: 1px solid #0088ff;
					}
				}
			}

			.news_feed_right {
				float: right;
				width: 400px;
				height: auto;
				position: relative;
				cursor: pointer;
				.title_box {
					width: 140px;
					height: 140px;
					background: #0088ff;
					position: absolute;
					left: 232px;
					top: 50px;
					z-index: 1;

					span {
						display: block;
						width: 62px;
						height: 102px;
						padding: 10px 30px;
						font-size: 30px;
						line-height: 50px;
						color: #fff;
						border: 1px solid #fff;
						margin: 8px;
					}
				}

				h3 {
					height: 140px;
					font-size: 30px;
					color: #666;
					line-height: 200px;
					text-indent: 126px;
				}

				.right_img {
					width: 400px;
					height: 250px;

					img {
						width: 100%;
						height: 100%;
						overflow: hidden;
					}
				}

				p {
					font-size: 14px;
					line-height: 26px;
					color: #666;
				}

				.news_title {
					font-size: 16px;
					line-height: 46px;
					color: #333;
					padding-top: 16px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				.double-line {
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
				}
			}

			.enter {
				position: absolute;
				left: 50%;
				bottom: 50px;
				margin-left: -24px;
				display: block;
				width: 46px;
				height: 46px;
				background: url("../../../../assets/img/template/stone_icon.png") no-repeat;
				background-position: 0 0;
			}
		}
	}
</style>
