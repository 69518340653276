<template>
	<div id="activity">
		<Top></Top>
		<div class="w1200">
			<div class="caption">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/activity' }">活动中心</el-breadcrumb-item>
					<el-breadcrumb-item>活动详情页</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="top-main-view">
				<div class="v-ticket">
					<div class="left-img" v-if="data.cover">
						<el-image style="width: 510px; height: 254px" :src="data.cover[0]" fit="cover"></el-image>
					</div>
					<div class="right-content">
						<div class="r-title">{{data.title}}</div>
						<div class="r-list">
							<div class="list-item">
								<i class="el-icon-location-information"></i>
								<span class="item-label">活动地点：</span>
								<span class="item-info">{{data.addr}}</span>
							</div>
							<div class="list-item">
								<i class="el-icon-timer"></i>
								<span class="item-label">活动时间：</span>
								<span class="item-info">{{data.time_start}}-{{data.time_end}}</span>
							</div>
							<div class="list-item">
								<i class="el-icon-office-building"></i>
								<span class="item-label">主办方：</span>
								<span class="item-info">上海一实网络科技有限公司</span>
							</div>
							<!-- <div class="list-item">
								<i class="el-icon-user"></i>
								<span class="item-label">活动限数：</span>
								<span class="item-info">5<em>/700人</em></span>
							</div> -->
						</div>
						<div class="r-foot">
							<div class="share-view clear-fix">
								<span class="el-icon-share"></span>
								<span class="name">分享：</span>
								<div class="share_box">
									<share :config="config"></share>
								</div>
							</div>
							<div class="looked-total">
								<i class="el-icon-view"></i>
								<span>{{data.view_num}}人浏览</span>
							</div>
						</div>
					</div>
				</div>
				<div v-show="tabel == false" class="v-apply">
					<!-- <div class="apply-label">
						<i class="el-icon-s-ticket"></i>
						<span>活动票种：</span>
					</div> -->
					<div class="buy-list">
						<!-- <ul class="clear-fix">
							<li v-for="(item,index) in ticket" :key="index" @click="Ticket(index)" :class="{'activeClass' : ticketIndex == index}">
								<div class="t-name">{{item.title}}</div>
								<div class="t-info">{{item.content}}</div>
							</li>
						</ul> -->
						<!-- <div class="notice">
							<i class="el-icon-warning-outline"></i>
							<span class="time-h">【订购日期：{{ticket[ticketIndex].time}}】</span>
							<span>{{ticket[ticketIndex].introduce}}</span>
						</div> -->
						<div class="v-foot clear-fix">
							<button type="button" @click="tabel = true" class="el-button apply-btn el-button--danger">
								<span>我要报名</span>
							</button>
							
						</div>
					</div>
				</div>
				<div v-show="tabel == true" class="v-apply-table">
					<h2>请填写报名表单</h2>
					<div style="margin: 20px;"></div>
					<el-form label-position="top" label-width="80px" :model="formLabelAlign">
						<el-form-item label="姓名">
							<el-input v-model="formLabelAlign.name"></el-input>
						</el-form-item>
						<el-form-item label="电话">
							<el-input v-model="formLabelAlign.tel"></el-input>
						</el-form-item>
						<el-form-item label="公司">
							<el-input v-model="formLabelAlign.company_name"></el-input>
						</el-form-item>
						<el-form-item label="职务">
							<el-input v-model="formLabelAlign.duties"></el-input>
						</el-form-item>
						<el-form-item label="需求描述">
							<el-input type="textarea" :autosize="{ minRows: 3}" v-model="formLabelAlign.requirements_des"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="do_join()">提交</el-button>
							<el-button @click="tabel = false">取消</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
			<div class="road-detail">
				<div class="wrap">
					<div class="panel">
						<div class="title">活动内容</div>
						<div class="panel-active">
							<div class="panel-content-detail">
								<div v-html="data.content">
								</div>
							</div>
							<!---->
						</div>
					</div>
					<div class="panel">
						<div class="title">活动标签</div>
						<div class="panel-tags clear-fix">
							<span class="tag" v-for="i in data.tag" :key="i">{{i}}</span>
						</div>
					</div>
					<div class="panel no-border">
						<div class="title">最新参与</div>
						<div class="panel-join">
							<ul>
								<li v-for="(v,k) in join_list" :key="k">
									<div>
										<img :src="v.user_info.avatar">
										<p>{{v.name}}</p>
										<label>已报名</label>
										<span>{{v.created_at}}</span>
									</div>
								</li>
							</ul>
						</div>
					</div>
					<div class="panel no-border">
						<div class="title">您可能感兴趣
							<span>更多
								<i class="el-icon-d-arrow-right"></i>
							</span>
						</div>
						<div class="panel-inter">
							<ul>
								<li v-for="(v,k) in like_list" :key="k">
									<a>
										<div class="img_show">
											<el-image :src="v.cover[0]" fit="cover"></el-image>
										</div>
										<p>{{v.title}}</p>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="slider">
					<div id="fixRightView" class="panel">
						<div class="title">主办方</div>
						<div class="panel-card">
							<div class="card-box">
								<el-image style="width: 135px; height: 135px" src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/logo.png" fit="cover"></el-image>
							</div>
							<p>上海一实网络科技有限公司</p>
						</div>
					</div>
					<div style="top: 120px; left: 1281.5px;" class="">
						<div class="panel">
							<div class="title">活动地图</div>
							<div id="allmap" class="map">
								<baidu-map @ready="handler" style="height:250px" @click="getClickInfo" :scroll-wheel-zoom='true'>
								</baidu-map>
							</div>
						</div>
						<div class="panel">
							<div class="title">活动日期</div>
							<div id="calendar">
								<el-calendar v-model="start_date">
								</el-calendar>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import Top from "@/components/top.vue"
	export default {
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		data() {
			return {
				ticketIndex: 0,
				ticket: [{
					title: '免费票',
					content: '免费（福利票）（余96张）',
					time: '2020-25-52',
					introduce: '限时票价'
				}, {
					title: '免费票',
					content: '免费（合作咨询）（余99张）',
					time: '2020-05-22',
					introduce: '大会全程论坛，领袖社区，资料一份。预留座位。'
				}, {
					title: '¥99',
					content: '付费票（余100张）',
					time: '2020-05-02',
					introduce: '联系咨询：13828836825'
				}, {
					title: '￥199',
					content: '特价票（限时）（余100张）',
					time: '2020-05-02',
					introduce: ' 大会全部论坛；前排座位，专属桌牌，“精选”领袖微信交流群；伴手礼；并获得大会资料一套'
				}, {
					title: '￥380',
					content: 'CEO尊享（标准票）（余100张）',
					time: '2020-05-02 9:30',
					introduce: '联系咨询：13828836825'
				}, {
					title: '￥580',
					content: 'VIP票（余100张）',
					time: '2020-05-22 30:50',
					introduce: ' 1.专属桌牌；2.红毯入场+专业摄影；3.免费在《企业资源对接手册》发布信息1-2条；4.伴手礼。'
				}, {
					title: '￥2800',
					content: 'SVIP票（余100张）',
					time: '2020-05-22',
					introduce: '大会全程论坛，领袖社区，资料一份。预留座位。'
				}],
				config: {
					title: '科技牛需求分享',
					description: '科技牛最新需求链接内容详情分享',
					url: 'http://pc.keji6.cn/#/demand/detail?id=' + this.id,
					source: 'http://pc.keji6.cn/#/demand/detail?id=' + this.id,
					image: 'http://hellofood.fun/webicon.png',
					// summary : '吃货召唤', //相当于description
					sites: ['qzone', 'qq', 'weibo', 'wechat'], // 启用的站点
					// disabled: ['google', 'facebook', 'twitter', 'linyin'], // 禁用的站点
					wechatQrcodeTitle: "微信扫一扫：分享", // 微信二维码提示文字
					wechatQrcodeHelper: '立即下载发送专属二维码,邀请朋友加入',
				},
				start_date: new Date('2019-03-04'),
				id: '',
				data: {},
				like_list: [],
				join_list: [],
				formLabelAlign: {
					name: '',
					tel: '',
					company_name: '',
					duties: '',
					requirements_des: ''
				},
				tabel: false
			}
		},
		mounted() {
			this.id = this.$route.query.id;
			this.get_detail()
			this.get_list();
			this.get_join_list();
		},
		components: {
			Top
		},
		methods: {
			do_collect() {
				this.$http.post('collect/add', {
					collect_id: this.id,
					type: 5,
					is_collect: this.data.is_collect ? 1 : 0,
				}).then(res => {
					this.$message.info(res.msg)
					this.data.is_collect = res.data.is_collect
					if (res.data.is_collect) {
						this.data.collect_num++;
					} else {
						this.data.collect_num--;
					}
				}).catch(err => {
					console.log(err)
				})
			},
			get_join_list() {
				this.$http_qm.post('join_activity/list', {
					activity_id: this.id,
					limit: 6,
					page: 1,
				}).then(res => {
					this.join_list = res.data;
				}).catch(err => {
					console.log(err)
				})
			},
			do_join() {
				var formLabelAlign = this.formLabelAlign;
				formLabelAlign.activity_id = this.id
				this.$http_qm.post('join_activity/add', formLabelAlign).then(res => {
					this.$message.info(res.msg)
					if(res.code==200){
						this.tabel = false;
					}
				}).catch(err => {
					console.log(err)
				})
			},
			get_list() {
				this.$http_qm.post('activity/list', {
					limit: 4,
					page: 1
				}).then(res => {
					if (res.code == 200) {
						this.like_list = res.data;
					}
				}).catch(err => {
					console.log(err)
				})
			},
			get_detail() {
				this.$http_qm.post('activity/detail', {
					id: this.id
				}).then(res => {
					if (res.code == 200) {
						this.data = res.data;
						this.start_date = new Date(this.data.time_start2)
					}
				}).catch(err => {
					console.log(err)
				})
			},
			Ticket(index) {
				this.ticketIndex = index;
			},
			handler({
				map
			}) {
				// var city = this.data.city;
				let city = '上海'
				if (city != "") {
					map.centerAndZoom(city, 9); // 用城市名设置地图中心点
				}
			},
			getClickInfo(e) {
				console.log(e.point.lng)
				console.log(e.point.lat)
				this.center.lng = e.point.lng
				this.center.lat = e.point.lat
			}
		}
	}
</script>

<style lang="less" src="../../assets/css/activity.less"></style>
