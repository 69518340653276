var industrylist = {
	"industrylist": [{
		"childrens": [{
			"childrens": [{
				"name": "基础软件"
			}, {
				"name": "嵌入式软件"
			}, {
				"name": "计算机辅助设计与辅助工程管理软件"
			}, {
				"name": "中文及多语种处理软件"
			}, {
				"name": "图形和图像处理软件"
			}, {
				"name": "地理信息系统(GIS)软件"
			}, {
				"name": "电子商务软件"
			}, {
				"name": "电子政务软件"
			}, {
				"name": "企业管理软件"
			}, {
				"name": "物联网应用软件"
			}, {
				"name": "云计算与移动互联网软件"
			}, {
				"name": "Web服务与集成软件"
			}],
			"name": "软件"
		}, {
			"childrens": [{
				"name": "集成电路设计技术"
			}, {
				"name": "集成电路产品设计技术"
			}, {
				"name": "集成电路封装技术"
			}, {
				"name": "集成电路测试技术"
			}, {
				"name": "集成电路芯片制造工艺技术"
			}, {
				"name": "集成光电子器件设计、制造与工艺技术"
			}],
			"name": "微电子技术"
		}, {
			"childrens": [{
				"name": "计算机及终端设计与制造技术"
			}, {
				"name": "计算机外围设��设计与制造技术"
			}, {
				"name": "网络设备设计与制造技术"
			}, {
				"name": "网络应用技术"
			}],
			"name": "计算机产品及其网络应用技术"
		}, {
			"childrens": [{
				"name": "通信网络技术"
			}, {
				"name": "光传输系统技术"
			}, {
				"name": "有线宽带接入系统技术"
			}, {
				"name": "移动通信系统技术"
			}, {
				"name": "宽带无线通信系统技术"
			}, {
				"name": "卫星通信系统技术"
			}, {
				"name": "微波通信系统技术"
			}, {
				"name": "物联网设备、部件及组网技术"
			}, {
				"name": "电信网络运营支撑管理技术"
			}, {
				"name": "电信网与互联网增值业务应用技术"
			}],
			"name": "通信技术"
		}, {
			"childrens": [{
				"name": "广播电视节目采编播系统技术"
			}, {
				"name": "广播电视业务集成与支撑系统技术"
			}, {
				"name": "有线传输与覆盖系统技术"
			}, {
				"name": "无线传输与覆盖系统技术"
			}, {
				"name": "广播电视监测监管、安全运行与维护系统技术"
			}, {
				"name": "数字电影系统技术"
			}, {
				"name": "数字电视终端技术"
			}, {
				"name": "专业视频应用服务平台技术"
			}, {
				"name": "音响、光盘技术"
			}],
			"name": "广播影视技术"
		}, {
			"childrens": [{
				"name": "半导体发光技术"
			}, {
				"name": "片式和集成无源元件"
			}, {
				"name": "大功率半导体器件"
			}, {
				"name": "专用特种器件"
			}, {
				"name": "敏感元器件与传感器"
			}, {
				"name": "中高档机电组件"
			}, {
				"name": "平板显示器件"
			}],
			"name": "新型电子元器件"
		}, {
			"childrens": [{
				"name": "密码技术"
			}, {
				"name": "认证授权技术"
			}, {
				"name": "系统与软件安全技术"
			}, {
				"name": "网络与通信安全技术"
			}, {
				"name": "安全保密技术"
			}, {
				"name": "安全测评技术"
			}, {
				"name": "安全管理技术"
			}, {
				"name": "应用安全技术"
			}],
			"name": "信息安全技术"
		}, {
			"childrens": [{
				"name": "交通控制与管理技术"
			}, {
				"name": "交通基础信息采集、处理技术"
			}, {
				"name": "交通运输运营管理技术"
			}, {
				"name": "车、船载电子设备技术"
			}, {
				"name": "轨道交通车辆及运行保障技术"
			}, {
				"name": "轨道交通运营管理与服务技术"
			}],
			"name": "智能交通和轨道交通技术"
		}],
		"name": "电子信息"
	}, {
		"childrens": [{
			"childrens": [{
				"name": "研发服务"
			}, {
				"name": "设计服务"
			}],
			"name": "研发与设计服务"
		}, {
			"childrens": [{
				"name": "检验检测认证技术"
			}, {
				"name": "标准化服务技术"
			}],
			"name": "检验检测认证与标准服务"
		}, {
			"childrens": [{
				"name": "云计算服务技术"
			}, {
				"name": "数据服务技术"
			}, {
				"name": "其他信息服务技术"
			}],
			"name": "信息技术服务"
		}, {
			"childrens": [{
				"name": "无"
			}],
			"name": "高技术专业化服务"
		}, {
			"childrens": [{
				"name": "无"
			}],
			"name": "知识产权与成果转化服务"
		}, {
			"childrens": [{
				"name": "电子商务技术"
			}, {
				"name": "物流与供应链管理技术"
			}],
			"name": "电子商务与现代物流技术"
		}, {
			"childrens": [{
				"name": "智慧城市服务支撑技术"
			}, {
				"name": "互联网教育"
			}, {
				"name": "健康管理"
			}, {
				"name": "现代体育服务支撑技术"
			}],
			"name": "城市管理与社会服务"
		}, {
			"childrens": [{
				"name": "创作、设计与制作技术"
			}, {
				"name": "传播与展示技术"
			}, {
				"name": "文化遗产发现与再利用技术"
			}, {
				"name": "运营与管理技术"
			}],
			"name": "文化创意产业支撑技术"
		}],
		"name": "高技术服务"
	}, {
		"childrens": [{
			"childrens": [{
				"name": "现场总线与工业以太网技术"
			}, {
				"name": "嵌入式系统技术"
			}, {
				"name": "新一代工业控制计算机技术"
			}, {
				"name": "制造执行系统(MES)技术"
			}, {
				"name": "工业生产过程综合自动化控制系统技术"
			}],
			"name": "工业生产过程控制系统"
		}, {
			"childrens": [{
				"name": "矿山安全生产技术"
			}, {
				"name": "危险化学品安全生产技术"
			}, {
				"name": "其它事故防治及处置技术"
			}],
			"name": "安全生产技术"
		}, {
			"childrens": [{
				"name": "新型传感器"
			}, {
				"name": "新型自动化仪器仪表"
			}, {
				"name": "科学分析仪器/检测仪器"
			}, {
				"name": "精确制造中的测控仪器仪表"
			}, {
				"name": "微机电系统技术"
			}],
			"name": "高性能、智能化仪器仪表"
		}, {
			"childrens": [{
				"name": "高档数控装备与数控加工技术"
			}, {
				"name": "机器人"
			}, {
				"name": "智能装备驱动控制技术"
			}, {
				"name": "特种加工技术"
			}, {
				"name": "大规模集成电路制造相关技术"
			}, {
				"name": "增材制造技术"
			}, {
				"name": "高端装备再制造技术"
			}],
			"name": "先进制造工艺与装备"
		}, {
			"childrens": [{
				"name": "机械基础件及制造技术"
			}, {
				"name": "通用机械装备制造技术"
			}, {
				"name": "极端制造与专用机械装备制造技术"
			}, {
				"name": "纺织及其他行业专用设备制造技术"
			}],
			"name": "新型机械"
		}, {
			"childrens": [{
				"name": "发电与储能技术"
			}, {
				"name": "输电技术"
			}, {
				"name": "配电与用电技术"
			}, {
				"name": "变电技术"
			}, {
				"name": "系统仿真与自动化技术"
			}],
			"name": "电力系统与设备"
		}, {
			"childrens": [{
				"name": "车用发动机及其相关技术"
			}, {
				"name": "汽车关键零部件技术"
			}, {
				"name": "节能与新能源汽车技术"
			}, {
				"name": "机动车及发动机先进设计、制造和测试平台技术"
			}, {
				"name": "轨道车辆及关键零部件技术"
			}],
			"name": "汽车及轨道车辆相关技术"
		}, {
			"childrens": [{
				"name": "高技术船舶设计制造技术"
			}, {
				"name": "海洋工程装备设计制造技术"
			}],
			"name": "高技术船舶与海洋工程装备设计制造技术"
		}, {
			"childrens": [{
				"name": "乐器制造技术"
			}, {
				"name": "印刷技术"
			}],
			"name": "传统文化产业改造技术"
		}],
		"name": "先进制造与自动化"
	}, {
		"childrens": [{
			"childrens": [{
				"name": "飞行器"
			}, {
				"name": "飞行器动力技术"
			}, {
				"name": "飞行器系统技术"
			}, {
				"name": "飞行器制造与材料技术"
			}, {
				"name": "空中管制技术"
			}, {
				"name": "民航及通用航空运行保障技术"
			}],
			"name": "航空技术"
		}, {
			"childrens": [{
				"name": "卫星总体技术"
			}, {
				"name": "运载火箭技术"
			}, {
				"name": "卫星平台技术"
			}, {
				"name": "卫星有效载荷技术"
			}, {
				"name": "航天测控技术"
			}, {
				"name": "航天电子与航天材料制造技术"
			}, {
				"name": "先进航天动力设计技术"
			}, {
				"name": "卫星应用技术"
			}],
			"name": "航天技术"
		}],
		"name": "航空航天"
	},{
		"childrens": [{
			"childrens": [{
				"name": "新型疫苗"
			}, {
				"name": "生物治疗技术和基因工程药物"
			}, {
				"name": "快速生物检测技术"
			}, {
				"name": "生物大分子类药物研发技术"
			}, {
				"name": "天然药物生物合成制备技术"
			}, {
				"name": "生物分离介质、试剂、装置及相关检测技术"
			}],
			"name": "医药生物技术"
		}, {
			"childrens": [{
				"name": "中药资源可持续利用与生态保护技术"
			}, {
				"name": "创新药物研发技术"
			}, {
				"name": "中成药二次开发技术"
			}, {
				"name": "中药质控及有害物质检测技术"
			}],
			"name": "中药、天然药物"
		}, {
			"childrens": [{
				"name": "创新药物技术"
			}, {
				"name": "手性药物创制技术"
			}, {
				"name": "晶型药物创制技术"
			}, {
				"name": "国家基本药物生产技术"
			}, {
				"name": "国家基本药物原料药和重要中间体的技术"
			}],
			"name": "化学药研发技术"
		}, {
			"childrens": [{
				"name": "创新制剂技术"
			}, {
				"name": "新型给药制剂技术"
			}, {
				"name": "制剂新辅料开发及生产技术"
			}, {
				"name": "制药装备技术"
			}],
			"name": "药物新剂型与制剂创制技术"
		}, {
			"childrens": [{
				"name": "医学影像诊断技术"
			}, {
				"name": "新型治疗、急救与康复技术"
			}, {
				"name": "新型电生理检测和监护技术"
			}, {
				"name": "医学检验技术及新设备"
			}, {
				"name": "医学专用网络新型软件"
			}, {
				"name": "医用探测及射线计量检测技术"
			}],
			"name": "医疗仪器、设备与医学专用软件"
		}, {
			"childrens": [{
				"name": "高效工业酶制备与生物催化技术"
			}, {
				"name": "微生物发酵技术"
			}, {
				"name": "生物反应及分离技术"
			}, {
				"name": "天然产物有效成份的分离提取技术"
			}, {
				"name": "食品安全生产与评价技术"
			}, {
				"name": "食品安全检测技术"
			}],
			"name": "轻工和化工生物技术"
		}, {
			"childrens": [{
				"name": "农林植物优良新品种与优质高效安全生产技术"
			}, {
				"name": "畜禽水产优良新品种与健康养殖技术"
			}, {
				"name": "重大农林生物灾害与动物疫病防控技术"
			}, {
				"name": "现代农业装备与信息化技术"
			}, {
				"name": "农业面源和重金属污染农田综合防治与修复技术"
			}],
			"name": "农业生物技术"
		}],
		"name": "生物与新医药"
	}, {
		"childrens": [{
			"childrens": [{
				"name": "精品钢材制备技术"
			}, {
				"name": "铝、铜、镁、钛合金清洁生产与深加工技术"
			}, {
				"name": "稀有、稀土金属精深产品制备技术"
			}, {
				"name": "纳米及粉末冶金新材料制备与应用技术"
			}, {
				"name": "金属及金属基复合新材料制备技术"
			}, {
				"name": "电工、微电子和光电子新材料制备与应用技术"
			}, {
				"name": "超导、高效能电池等其它新材料制备与应用技术"
			}, {
				"name": "半导体新材料制备与应用技术"
			}],
			"name": "金属材料"
		}, {
			"childrens": [{
				"name": "结构陶瓷及陶瓷基复合材料强化增韧技术"
			}, {
				"name": "功能陶瓷制备技术"
			}, {
				"name": "功能玻璃制备技术"
			}, {
				"name": "节能与新能源用材料制备技术"
			}, {
				"name": "环保及环境友好型材料技术"
			}],
			"name": "无机非金属材料"
		}, {
			"childrens": [{
				"name": "新型功能高分子材料的制备及应用技术"
			}, {
				"name": "工程和特种工程塑料制备技术"
			}, {
				"name": "新型橡胶的合成技术及橡胶新材料制备技术"
			}, {
				"name": "新型纤维及复合材料制备技术"
			}, {
				"name": "高分子材料制备及循环再利用技术"
			}, {
				"name": "高分子材料的新型加工和应用技术"
			}],
			"name": "高分子材料"
		}, {
			"childrens": [{
				"name": "介入治疗器具材料制备技术"
			}, {
				"name": "心脑血管外科用新型生物材料制备技术"
			}, {
				"name": "骨科内置物制备技术"
			}, {
				"name": "口腔材料制备技术"
			}, {
				"name": "组织工程用材料制备技术"
			}, {
				"name": "新型敷料和止血材料制备技术"
			}, {
				"name": "专用手术器械和材料制备技术"
			}, {
				"name": "其他新型医用材料及制备技术"
			}],
			"name": "生物医用材料"
		}, {
			"childrens": [{
				"name": "新型催化剂制备及应用技术"
			}, {
				"name": "电子化学品制备及应用技术"
			}, {
				"name": "超细功能材料制备及应用技术"
			}, {
				"name": "精细化学品制备及应用技术"
			}],
			"name": "精细和专用化学品"
		}, {
			"childrens": [{
				"name": "文化载体和介质新材料制备技术"
			}, {
				"name": "艺术专用新材料制备技术"
			}, {
				"name": "影视场景和舞台专用新材料的加工生产技术"
			}, {
				"name": "文化产品印刷新材料制备技术"
			}, {
				"name": "文物保护新材料制备技术"
			}],
			"name": "与文化艺术产业相关的新材料"
		}],
		"name": "新材料"
	}, {
		"childrens": [{
			"childrens": [{
				"name": "太阳能"
			}, {
				"name": "风能"
			}, {
				"name": "生物质能"
			}, {
				"name": "地热能、海洋能及运动能"
			}],
			"name": "可再生清洁能源"
		}, {
			"childrens": [{
				"name": "核能"
			}, {
				"name": "氢能"
			}],
			"name": "核能及氢能"
		}, {
			"childrens": [{
				"name": "高性能绿色电池(组)技术"
			}, {
				"name": "新型动力电池组与储能电池技术"
			}, {
				"name": "燃料电池技术"
			}, {
				"name": "超级电容器与热电转换技术"
			}],
			"name": "新型高效能量转换与储存技术"
		}, {
			"childrens": [{
				"name": "工业节能技术"
			}, {
				"name": "能量回收利用技术"
			}, {
				"name": "蓄热式燃烧技术"
			}, {
				"name": "输配电系统优化技术"
			}, {
				"name": "高温热泵技术"
			}, {
				"name": "建筑节能技术"
			}, {
				"name": "能源系统管理、优化与控制技术"
			}, {
				"name": "节能监测技术"
			}],
			"name": "高效节能技术"
		}],
		"name": "新能源与节能"
	}, {
		"childrens": [{
			"childrens": [{
				"name": "城镇污水处理与资源化技术"
			}, {
				"name": "工业废水处理与资源化技术"
			}, {
				"name": "农业水污染控制技术"
			}, {
				"name": "流域水污染治理与富营养化综合控制技术"
			}, {
				"name": "节水与非常规水资源综合利用技术"
			}, {
				"name": "饮用水安全保障技术"
			}],
			"name": "水污染控制与水资源利用技术"
		}, {
			"childrens": [{
				"name": "机动车排放控制技术"
			}, {
				"name": "工业炉窑污染防治技术"
			}, {
				"name": "工业有害废气控制技术"
			}, {
				"name": "有限空间空气污染防治技术"
			}, {
				"name": "煤燃烧污染防治技术"
			}],
			"name": "大气污染控制技术"
		}, {
			"childrens": [{
				"name": "危险固体废弃物处置技术"
			}, {
				"name": "工业固体废弃物综合利用技术"
			}, {
				"name": "生活垃圾处置与资源化技术"
			}, {
				"name": "建筑垃圾处置与资源化技术"
			}, {
				"name": "有机固体废物处理与资源化技术"
			}, {
				"name": "社会源固体废物处置与资源化技术"
			}],
			"name": "固体废弃物处置与综合利用技术"
		}, {
			"childrens": [{
				"name": "噪声、振动污染防治技术"
			}, {
				"name": "核与辐射安全防治技术"
			}],
			"name": "物理性污染防治技术"
		}, {
			"childrens": [{
				"name": "环境监测预警技术"
			}, {
				"name": "应急环境监测技术"
			}, {
				"name": "生态环境监测技术"
			}, {
				"name": "非常规污染物监测技术"
			}],
			"name": "环境监测及环境事故应急处理技术"
		}, {
			"childrens": [{
				"name": "无"
			}],
			"name": "生态环境建设与保护技术"
		}, {
			"childrens": [{
				"name": "重污染行业生产过程中节水、减排及资源化关键技术"
			}, {
				"name": "清洁生产关键技术"
			}, {
				"name": "环保制造关键技术"
			}],
			"name": "清洁生产技术"
		}, {
			"childrens": [{
				"name": "资源勘查开采技术"
			}, {
				"name": "提高矿产资源回收利用率的采矿、选矿技术"
			}, {
				"name": "伴生有价元素的分选提取技术"
			}, {
				"name": "低品位资源和尾矿资源综合利用技术"
			}, {
				"name": "放射性资源勘查开发技术"
			}, {
				"name": "放射性废物处理处置技术"
			}, {
				"name": "绿色矿山建设技术"
			}],
			"name": "资源勘查、高效开采与综合利用技术"
		}],
		"name": "资源与环境"
	},{
		"childrens": [{
			"childrens": [{
				"name": "无"
			}],
			"name": "无"
		}],
		"name": "其它领域"
	}]
}
export default industrylist.industrylist
