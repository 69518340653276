<template>
	<div class="page index">
		<!-- <flexRight v-show="scroll_height>1200"></flexRight> -->
		<div class="index_box">

			<div class="body">

				<div class="container-inner" v-bind:class="{fixed:this.scroll_height>=1100}">
					<Top :user="user"></Top>
				</div>
				<div class="banner_text">
					<div class="caption">{{demand_megagame_title.title1}}</div>
					<div class="caption-1">{{demand_megagame_title.title2}}</div>
				</div>
				<div class="container-inner-1">
					<div class="wrapper-1">
						<div class="wrapper-inner">
							<span class="location">资源大数据</span>
							<div class="big_data">
								<div class="li" v-for="(v,k) in demand_megagame_data" :key="k">
									<span class="tag-3">{{v.title}}</span>
									<span class="num-1">{{v.value}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="box">
				<div class="main">

					<div class="container-inner-2">
						<span class="label-4">需求大厅</span>
						<div class="wrapper-2">
							<div class="list">
								<span class="tag-5" v-bind:class="{'word-4':demand_index==k}"
									v-for="(v,k) in demand_cate" :key="k" @click="demand_check(k)">{{v.name}}</span>

							</div>
							<div class="wrapper-3" @click="href('/demand',{})">
								<span class="more">更多</span>
								<i class="el-icon-arrow-right"></i>
							</div>
						</div>

					</div>

					<div class="demand_ul" v-show="demand_index==k" v-for="(v,k) in demand_cate" :key="k">
						<div class="container-inner-4">
							<div class="view-i0" @click="href('/demand/detail',{id:v2.id})" v-for="(v2,k2) in v.list"
								:key="k2">

								<div class="desc">{{v2.title}}</div>
								<div class="word-6">关键词</div>
								<div class="demand_tag">
									<div class="li" v-for="(t,tk) in v2.tag" :key="tk">
										{{t}}
									</div>

								</div>
								<div class="zzje flex">
									<span> 合作金额：</span>
									<div class="tag-7">{{v2.budget_amount}}万元</div>
								</div>


							</div>


						</div>
					</div>

				</div>
				<div class="container">
					<div class="news">
						<div class="word-12">动态</div>
						<div class="nav">
							<span class=" nav_li active tag-19">新闻动态</span>
							<!-- 							<span class=" nav_li label-19">最新公告</span> -->
							<span class="nav_li more-1" @click="href('/news',{})">更多 <i class="el-icon-arrow-right"></i>
							</span>
						</div>
						<div class="li" v-for="(v,k) in news.list" @click="href('/news/detail',{id:v.id})" :key="k">

							<span class="detail-4">{{v.title}}</span>
							<img class="icon"
								src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/megagame/img_76468_0_46.png" />

						</div>
					</div>
					<div class="banner2">
						<img @click="out_href('https://www.keji6.cn/soar')" class="banner2"
							src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/ad1.jpg" alt="">
					</div>

				</div>
			</div>

			<span class="title-2">技术专题</span>
			<div class="submain-i0">
				<div class="container-inner-i0">
					<img class="entry-pic-9"
						src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/megagame/img_76468_0_9.png" />
					<span class="word-13">智能制造</span>
				</div>
				<div class="container-inner-i1">
					<img class="entry-pic-10"
						src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/megagame/img_76468_0_10.png" />
					<span class="tag-20">大健康</span>
				</div>
				<div class="container-inner-i2">
					<img class="entry-pic-11"
						src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/megagame/img_76468_0_11.png" />
					<span class="label-20">新材料</span>
				</div>
			</div>
			<span class="caption-2">认证专家</span>
			<div class="zjrow">
				<div class="col-i0 li" v-for="(v,k) in expert.list" :key="k">

					<div class="wrapper-6">
						<div class="col">
							<img class="large-icon-2" :src="v.avatar" />
							<div class="wrapper-inner-3">
								<span class="word-14">{{v.name}}</span>
								<span class="info">{{v.danwei}}</span>
							</div>
						</div>
						<div class="col2">
							<span class="tag-21">服务</span> <span class="num-6">{{v.consulting_num}}</span>
							<span class="label-21">人</span>
						</div>
					</div>
					<div class="wrapper-7">
						<div class="wrapper-inner-i0">

							<div class="meta" v-for="(t,tk) in v.territory_tag" :key="tk">{{t}}</div>
						</div>

					</div>

					<div class="wrapper-8">
						<div class="wrapper-inner-4">
							<img class="icon-house"
								src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/megagame/img_76468_0_58.png" />
							<span class="count">{{v.star_num}}</span>
							<span class="tag-24">{{v.answer_num}}条问题</span>
						</div>
						<div class="wrapper-inner-5" @click="href('/expert/detail',{id:v.id})">

							<span class="label-24">联系专家</span>
						</div>
					</div>
				</div>

			</div>
			<div class="footer" @click="href('/expert',{})">
				<span class="more-2">找更多专家</span>
				<i class="el-icon-arrow-right"></i>
			</div>
			<span class="caption-2">合作企业</span>
			<div class="hzqy">
				<div class="li" v-for="(v,k) in 10" :key="k">
					<img :src="'https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/cooperate/hzimg'+v+'.png'"
						alt="科技牛合作企业">
				</div>

			</div>
		</div>
	</div>
</template>
<script>
	import Top from '../../components/top.vue';
	// import flexRight from "@/components/flexRight.vue"
	import {
		mapGetters,
		mapState
	} from 'vuex';
	export default {
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		components: {
			Top,
			// flexRight

		},
		data() {
			return {
				drawer: false,
				direction: 'btt',
				title: '',
				constants: {},
				userInfo: {},
				company: {
					page: 1,
					limit: 6,
					list: [],
					reload: false
				},
				expert: {
					page: 1,
					limit: 6,
					list: [],
					reload: false
				},
				news: {
					page: 1,
					limit: 5,
					list: [],
					reload: false
				},
				cg: {
					page: 1,
					limit: 8,
					list: [],
					industry: ''
				},
				banner: {
					page: 1,
					limit: 6,
					list: [],
					reload: false
				},
				expert_cate: {
					page: 1,
					limit: 4,
					list: [],
					reload: false
				},
				demand_megagame_title: {
					'title1': '科技牛第二届',
					'title2': '技术需求暨解决方案征集大赛'
				},
				demand_cate: [],
				demand_index: 0,
				city_value: '',
				industry_cate: [],
				cg_cate: [],
				activity: {
					list: []
				},
				cate_arr: [],
				menu: [],
				menu_index: 0,
				megagame_count: {},
				scroll_height: 0,
				demand_megagame_data:[],
			};
		},
		computed: {
			...mapGetters(['user', 'token']),
			...mapState(['city', 'province'])
		},
		mounted() {
			window.addEventListener('scroll', this.scroll_action)
			this.get_menu();
			this.get_config_title('demand_megagame_data');
			this.get_config_title('demand_megagame_title');
			this.get_demand_cate();
			this.get_company();
			this.get_expert_cate();
			this.get_expert();
			this.get_cg_cate();
			this.get_cg();
			this.get_news();

		},

		methods: {
			scroll_action() {
				this.scroll_height = document.documentElement.scrollTop || document.body.scrollTop;

			},
			get_count() {
				this.$http_qm
					.post('index/megagame_count', {})
					.then(res => {
						this.megagame_count = res.data;
					})
					.catch(err => {
						console.log(err);
					});
			},
			get_config_title(name) {
				this.$http_qm
					.post('config/detail', {
						'name': name,
						'version':1,
					})
					.then(res => {
						if (res.code == 200) {
							if(name == 'demand_megagame_title'){
								this.demand_megagame_title = res.data.map_value;
							}else if(name == 'demand_megagame_data'){
								this.demand_megagame_data = res.data.arr_value;
							}
						}
					})
					.catch(err => {
						console.log(err);
					});
			},
			menu_click(path) {
				this.$router.push({
					path: path
				})
			},
			get_menu() {
				this.menu = [{
						name: '首页',
						to: '/'
					}, {
						name: '需求创新',
						to: '/demand'
					}, {
						name: '认证专家',
						to: '/expert'
					}, {
						name: '新闻公告',
						to: '/news'
					},
					{
						name: '活动大厅',
						to: '/activity'
					}
				]

			},
			demand_check(k) {
				this.demand_index = k;
			},
			get_cg_cate() {
				this.$http_qm
					.post('achievement/cate', {
						type: 'industrial'
					})
					.then(res => {
						let industry = res.data.industrial;
						industry.unshift('不限')
						var cg_cate = this.cg_cate;
						industry.forEach(function(v, k) {
							if (k == 0) {
								cg_cate.push({
									name: v,
									active: true
								});
							} else {
								cg_cate.push({
									name: v,
									active: false
								});
							}
						});
					})
					.catch(err => {
						console.log(err);
					});
			},
			get_cate() {

				this.$http_qm
					.post('product_cate/list', {})
					.then(res => {
						this.cate_arr = res.data;
					})
					.catch(err => {
						console.log(err);
					});
			},
			href(url, param) {
				if (url != '') {
					this.$router.push({
						path: url,
						query: param
					});
					/* var router = this.$router.resolve({
						path: url,
						query: param
					});
					window.open(router.href, '_blank'); */
				}
			},
			out_href(url) {
				window.open(url, '_blank');
			},
			get_active() {
				this.$http_qm
					.post('activity/list', {
						page: 1,
						status: 1,
						limit: 3
					})
					.then(res => {
						if (res.code == 200) {
							this.activity.list = res.data;
						}
					})
					.catch(err => {
						console.log(err);
					});
			},
			change_cg(name, k) {
				this.cg_cate.forEach(function(v, k1) {
					if (k1 == k) {
						v.active = true;
					} else {
						v.active = false;
					}
				});
				if (name == '不限') {
					this.cg.industry = '';
				} else {
					this.cg.industry = name;
				}
				this.get_cg();
			},
			get_localStorage() {
				this.city_value = localStorage.getItem('city');
			},

			async get_demand_cate() {
				await this.$http_qm
					.post('demand/index_list', {
						limit: 9,
						page: 1
					})
					.then(res => {
						this.demand_cate = res.data;
					})
					.catch(err => {
						console.log(err);
					});
			},
			async get_expert_cate() {
				let search_data = {
					page: this.expert_cate.page,
					limit: this.expert_cate.limit
				};
				await this.$http
					.post('expert/cate', search_data)
					.then(res => {
						if (this.reload) {
							this.expert_cate.list = res.data;
						} else {
							this.expert_cate.list = this.expert_cate.list.concat(res.data);
						}
						this.expert_cate.reload = false;
						if (res.data.length == search_data.limit) {
							search_data.page++;
						} else {
							this.expert_cate.loading = false;
						}
					})
					.catch(err => {
						console.log(err);
					});
			},
			async get_banner() {
				const city = localStorage.getItem('city')
				const province = localStorage.getItem('province')
				let search_data = {
					page: this.banner.page,
					limit: this.banner.limit,
					type: 2,
					city: city,
					province: province
				};
				await this.$http
					.post('banner/list', search_data)
					.then(res => {
						this.banner.list = res.data;
					})
					.catch(err => {
						console.log(err);
					});
			},
			async get_cg() {
				let search_data = {
					page: this.cg.page,
					limit: this.cg.limit,
					industrial: this.cg.industry
				};
				await this.$http
					.post('achievement/list', search_data)
					.then(res => {
						this.cg.list = res.data;
					})
					.catch(err => {
						console.log(err);
					});
			},
			async get_news() {
				let search_data = {
					page: this.news.page,
					limit: this.news.limit,
					request_type: 'new'
				};
				this.$http_qm
					.post('news/list', search_data)
					.then(res => {
						if (this.reload) {
							this.news.list = res.data;
						} else {
							this.news.list = this.news.list.concat(res.data);
						}
						this.news.reload = false;
						if (res.data.length == search_data.limit) {
							search_data.page++;
						} else {
							this.news.loading = false;
						}
					})
					.catch(err => {
						console.log(err);
					});
			},
			async get_company() {
				let search_data = {
					page: this.company.page,
					limit: this.company.limit
				};
				await this.$http_qm
					.post('company/list', search_data)
					.then(res => {
						if (this.reload) {
							this.company.list = res.data;
						} else {
							this.company.list = this.company.list.concat(res.data);
						}
						this.company.reload = false;
						if (res.data.length == search_data.limit) {
							search_data.page++;
						} else {
							this.company.loading = false;
						}
					})
					.catch(err => {
						console.log(err);
					});
			},
			async get_expert() {
				let search_data = {
					page: this.expert.page,
					limit: this.expert.limit
				};
				await this.$http
					.post('expert/search', search_data)
					.then(res => {
						if (this.reload) {
							this.expert.list = res.data;
						} else {
							this.expert.list = this.expert.list.concat(res.data);
						}
						this.expert.reload = false;
						if (res.data.length == search_data.limit) {
							search_data.page++;
						} else {
							this.expert.loading = false;
						}
					})
					.catch(err => {
						console.log(err);
					});
			},

			cg_item(id) {
				this.$router.push({
					path: '/achivement/detail',
					query: {
						id: id
					}
				});
			},
			toUrl(url) {
				this.$router.push({
					path: url
				});
			}
		}
	};
</script>
<style lang="less" src="../../assets/css/common.less" />
<style lang="less">
	@import "../../assets/css/index.response.less";
</style>
