<template>
	<div id="add">
		<Top></Top>
		<div class="wrap">
			<el-page-header style="width: 1200px; margin: 0 auto 20px;" @back="goBack" content="需求发布"></el-page-header>
			<div class="container">
				<div class="from-title">
					<h1>需求信息</h1>
				</div>
				<el-form ref="form" :rules="rules" label-position="left" :model="form" label-width="100px">
					<el-form-item label="需求名称:" prop="title" :rules="{ required: true, message: '请输入需求名称' }">
						<el-input class="demand-name" v-model="form.title" placeholder="55个字以内"></el-input>
					</el-form-item>
					<el-form-item label="需求描述:" prop="describe" :rules="{ required: true, message: '请输入需求描述' }">
						<vue-editor v-model="form.describe" @focus="onEditorFocus" @blur="onEditorBlur"
							use-custom-image-handler @image-added="handleImageAdded"
							@selection-change="onSelectionChange" />
					</el-form-item>
					<el-form-item label="需求图片:" prop="cover" :rules="{ required: true, message: '请添加需求图片' }">
						<el-upload class="avatar-uploader" list-type="picture-card"
							:action="baseUrl + 'file/upload_one'" :file-list="cover_list" :data="upload_data"
							:on-success="handleAvatarSuccess" :limit="1">
							<i class="el-icon-plus"></i>
						</el-upload>
						<el-dialog :visible.sync="dialogVisible"><img width="100%" :src="dialogImageUrl" alt="" />
						</el-dialog>
					</el-form-item>
					<el-form-item class="industry" prop="industry" label="行业分类:"
						:rules="{ required: true, message: '请选择行业分类' }">
						<el-col :span="6">
							<el-select v-model="industry" placeholder="请选择">
								<el-option :label="v.name" v-for="(v, k) in industry_cate" :key="k" :value="v">
								</el-option>
							</el-select>
						</el-col>
						<!-- <el-col :span="6">
							<el-select v-model="form.industry" placeholder="请选择">
								<el-option label="医疗" value="shanghai"></el-option>
								<el-option label="生物" value="beijing"></el-option>
							</el-select>
						</el-col> -->
						<el-col :span="6">
							<el-button type="primary" @click="add_industry()" size="small">添加</el-button>
						</el-col>
						<br />
						<p class="industry-text">选择该需求主要应用于哪些行业领域</p>

						<el-tag v-for="tag in form.industry" :key="tag" closable @close="industryClose(tag)"
							type="info">{{ tag }}</el-tag>
					</el-form-item>
					<el-form-item class="industry" prop="tag" label="关键字:"
						:rules="{ required: true, message: '请添加关键字' }">
						<el-tag :key="tag" v-for="tag in form.tag" closable :disable-transitions="false"
							@close="handleClose(tag)">{{ tag }}</el-tag>
						<el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput"
							size="small" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm"></el-input>
						<el-button v-else class="button-new-tag" size="small" @click="showInput">+ 关键字</el-button>
					</el-form-item>
					<el-form-item label="需求类别:" prop="demand_type" :rules="{ required: true, message: '请选择需求类型' }">
						<el-radio-group v-model="form.demand_type">
							<el-radio v-for="(c,i) in cate.type" :key="i" :label="c.name"></el-radio>
						</el-radio-group>
					</el-form-item>

					<!-- <el-form-item label="投资预算:" prop="budget_amount" :inline="true" :rules="{ required: true, message: '请选择投资预算' }">
						<el-input style="width: 200px;" :disabled="mianyi_checked" v-model="form.budget_amount"></el-input>
						<span class="wanyuan">万元</span>
						<el-checkbox @change="budget_amount_change" v-model="mianyi_checked" :disabled="form.budget_amount ? true : false">面议</el-checkbox>
					</el-form-item> -->
					<el-form-item label="投资预算:" prop="budget_amount" :inline="true"
						:rules="{ required: true, message: '请选择投资预算' }">
						<el-select  v-model="form.budget_amount" placeholder="请选择">
							<el-option v-for="item in options" :key="item.value" :label="item.value"
								:value="item.value">
							</el-option>
						</el-select>
						<span class="wanyuan">万元</span>

					</el-form-item>
					<el-form-item label="截止时间" prop="deadline" :rules="{ required: true, message: '请选择截止时间' }">
						<el-radio-group v-model="form.deadline">
							<el-radio v-for="(v, k) in time" :key="k" :label="v.value">{{ v.label }}</el-radio>
							<el-date-picker v-model="custom_deadline" type="date" placeholder="选择日期"></el-date-picker>
						</el-radio-group>
					</el-form-item>
					<el-row>
						<el-col :span="6">
							<el-form-item class="site" label="需求所在地:" :rules="{ required: true, message: '请选择省' }">
								<el-select v-model="form.province" placeholder="----省----"
									@change="province_change($event)">
									<el-option v-for="(v, k) in city.sf" :key="k" :label="v.name" :value="v.name">
									</el-option>
								</el-select>
								<!-- <el-select v-if="city.sf && city.sf.length > 0" v-model="province_key" placeholder="----省----">
									<el-option v-for="(v, k) in city.sf" :key="k" :label="v.name" :value="k"></el-option>
								</el-select> -->
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item class="site" prop="city" :rules="{ required: true, message: '请选择市' }">
								<el-select v-if="city.sf && city.sf.length > 0" v-model="form.city"
									placeholder="----市----">
									<el-option :label="v.name" :value="v.name"
										v-for="(v, k) in city.sf[province_key].children" :key="k"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<br />
					<el-row>
						<el-col :span="7">
							<el-form-item class="relation" prop="contact_name" label="需求联系人:"
								:rules="{ required: true, message: '请填写需求联系人' }">
								<el-input placeholder="请填写联系人" v-model="form.contact_name" style="width: 200px;">
								</el-input>
							</el-form-item>
						</el-col>
						<el-col class="line" :span="2">手机：</el-col>
						<el-col :span="7">
							<el-form-item class="relation" prop="contact_tel" label="联系人手机:"
								:rules="[{ required: true, message: '请填写联系人手机' }, { min: 11, max: 11, message: '请输入正确的手机号' }]">
								<el-input placeholder="请填写手机号 " v-model="form.contact_tel" style="width: 200px;">
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-form-item label="背景" prop="background" :rules="{ required: true, message: '请输入背景' }">
						<el-input type="textarea" :autosize="{ minRows: 4 }" placeholder="请输入内容"
							v-model="form.background"></el-input>
					</el-form-item>
					<el-form-item label="约束条件" prop="condition" :rules="{ required: true, message: '请输入约束条件' }">
						<el-input type="textarea" :autosize="{ minRows: 4 }" placeholder="请输入内容"
							v-model="form.condition"></el-input>
					</el-form-item>
					<el-form-item label="技术要求" prop="requirements" :rules="{ required: true, message: '请输入技术要求' }">
						<el-input type="textarea" :autosize="{ minRows: 4 }" placeholder="请输入内容"
							v-model="form.requirements"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button :loading="submit_loading" class="submit" type="primary" @click="onSubmit">提交
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		VueEditor
	} from 'vue2-editor';
	import Top from "@/components/top.vue"
	export default {
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		components: {
			VueEditor,
			Top
		},
		data() {
			return {
				content: '', //vue2-editor
				props: {
					multiple: true
				}, //行业开启多选
				valueDate: '',
				imageUrl: '',
				upload_data: {
					dir: 'demand'
				},
				submit_loading: false,
				dialogImageUrl: '',
				dialogVisible: false,
				options: [{
					value: '10-50',
				}, {
					value: '50-100',
				}, {
					value: '100-200',
				}, {
					value: '200-300',

				}, {
					value: '300-500',
				}, {
					value: '500-1000',
				}, {
					value: '1000-2000',
				}, {
					value: '2000以上',
				}],
				form: {
					title: '',
					describe: '',
					industry: [],
					tag: [],
					cover: [],
					demand_type: [],
					budget_amount: '',
					deadline: '',
					province: '',
					city: '',
					contact_name: '',
					contact_tel: '',

					background: '',
					condition: '',
					requirements: ''
				},
				cover_list: [],
				rules: {
					title: [{
						required: true,
						message: '请输入需求名称',
						trigger: 'blur'
					}],
					describe: [{
						required: true,
						message: '请输入需求名称',
						trigger: 'blur'
					}]
				},
				inputVisible: false,
				inputValue: '',
				industry: '',
				tag: [],
				industry_cate: [],
				mianyi_checked: false,
				city: {
					sf: [],
				},
				province_key: 0,
				custom_deadline: '',
				time: [{
						label: '一个月内',
						value: ''
					},
					{
						label: '三个月内',
						value: ''
					},
					{
						label: '半年内',
						value: ''
					},
					{
						label: '一年内',
						value: ''
					},
					{
						label: '自定义时间',
						value: 'custom'
					}
				],
				time1: '',
				id: '',
				cate: []
			};
		},
		mounted() {
			const token = localStorage.getItem('token');
			if (!token) {
				this.$router.push({
					name: 'login'
				});
			}
			this.getNext();
			this.get_industry();
			this.get_city();
			this.get_cate();
			let query = this.$route.query;
			if (typeof query.id != 'undefined') {
				this.id = query.id;
				this.get_detail();
			}
			this.company_detail();
		},
		methods: {
			company_detail() {
				this.$http_qm
					.post('company/detail', {
						type: 'me'
					}).then(res => {
						if (res.code == 200) {
							this.form.company_id = res.data.id;
						}
					})
					.catch(err => {
						console.log(err);
					});
			},
			get_city() {
				this.$http_qm
					.post('city/list', {
						type: 'sf'
					})
					.then(res => {
						this.city.sf = res.data.sf;
					})
					.catch(err => {
						console.log(err);
					});
			},
			handleImageAdded(file, Editor, cursorLocation, resetUploader) {
				console.log(file);
				var formData = new FormData();
				formData.append('file', file);
				formData.append('dir', 'demand');
				this.$http
					.post('file/upload_one', formData, {
						headers: {
							'Content-Type': 'multipart/form-data;'
						}
					})
					.then(res => {
						console.log(res);
						let url = res.data.img_url; // Get url from response
						Editor.insertEmbed(cursorLocation, 'image', url);
						resetUploader();
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			industryClose(tag) {
				this.form.industry.splice(this.form.industry.indexOf(tag), 1);
			},
			budget_amount_change(v) {
				if (v) {
					this.form.budget_amount = 0;
				}
			},
			get_detail() {
				this.$http_qm
					.post('demand/detail', {
						id: this.id
					})
					.then(res => {
						if (res.code == 200) {
							this.form = res.data;
							let cover_list = this.cover_list;
							this.form.cover.forEach(function(v) {
								cover_list.push({
									url: v
								});
							});
						}
					})
					.catch(err => {
						console.log(err);
					});
			},
			async get_cate() {
				await this.$http_qm.post('demand/cate', {}).then(res => {
					this.cate = res.data;
				}).catch(err => {
					console.log(err)
				})
			},
			handleAvatarSuccess(res, file) {
				if (res.code == 200) {
					this.imageUrl = URL.createObjectURL(file.raw);
					this.form.cover.push(res.data.img_url);
				}
				/* let config = {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				};
				let param = new FormData() // 创建form对象
				param.append('file', file) // 通过append向form对象添加数据
				param.append('dir', 'demand') // 添加form表单中其他数据
				  console.log(param.get('dir'))
				this.$http.post('file/upload_one', param, config).then(res => {
				      console.log(res);
				}).catch(err => {
					console.log(err);
				}) */
			},

			getNext() {
				var now = new Date();
				var next_month = new Date();
				var three_month = new Date();
				var six_month = new Date();
				var next_year = new Date();
				var year = now.getFullYear();

				if (next_month.getMonth() + 1 > 12) {
					next_month.setMonth(next_month.getMonth() + 1 - 12);
					next_month.setFullYear(year + 1);
				} else {
					next_month.setMonth(next_month.getMonth() + 1);
				}
				if (three_month.getMonth() + 3 > 12) {
					three_month.setMonth(three_month.getMonth() + 3 - 12);
					three_month.setFullYear(year + 1);
				} else {
					three_month.setMonth(three_month.getMonth() + 3);
				}
				if (six_month.getMonth() + 6 > 12) {
					six_month.setMonth(six_month.getMonth() + 6 - 12);
					six_month.setFullYear(year + 1);
				} else {
					six_month.setMonth(six_month.getMonth() + 6);
				}
				var next_time = next_month.getTime();
				this.time[0].value = Math.floor(next_time / 1000);
				this.time[1].value = Math.floor(three_month.getTime() / 1000);
				this.time[2].value = Math.floor(six_month.getTime() / 1000);
				this.time[3].value = Math.floor(next_year.setFullYear(year + 1) / 1000);
			},
			onSubmit() {
				this.submit_loading = true;
				this.$refs['form'].validate(valid => {
					console.log(this.form);
					if (!valid) {
						console.log('error submit!!');
						this.submit_loading = false;
						return false;
					}

					if (this.mianyi_checked) {
						this.form.budget_amount = '面议';
					}
					if (this.form.deadline == 'custom') {
						this.form.deadline = this.custom_deadline.getTime() / 1000;
					}
					if (this.id == '') {
						var url = 'demand/add';
					} else {
						url = 'demand/update';
					}
					this.$http_qm
						.post(url, this.form)
						.then(res => {
							if (res.code == 200) {
								this.$router.push({
									path: '/demand/admin'
								});
							} else {
								this.$message.error(res.msg);
							}
							this.submit_loading = false;
						})
						.catch(err => {
							console.log(err);
						});
				});
			},
			goBack() {
				this.$router.go(-1);
			},
			province_change(event) {
				var cityList = this.city.sf;
				let that = this;
				cityList.forEach(function(item, index) {
					if (event == item.name) {
						that.province_key = index
					}
				})
				// this.form.province = this.city.sf[this.province_key].name;
				this.form.city = '';
				this.$forceUpdate();
			},
			get_industry() {
				this.$http_qm
					.post('expert/cate', {
						type: 'industrial'
					})
					.then(res => {
						this.industry_cate = res.data.industrial;
						this.industry_cate.forEach(function(item) {
							item.checked = false;
						});
					})
					.catch(err => {
						console.log(err);
					});
			},
			add_industry() {
				let industrys = this.form.industry;
				let index = industrys.indexOf(this.industry);
				if (index == -1) {
					industrys.push(this.industry);
				}
				this.$forceUpdate();
			},

			onEditorBlur(quill) {
				console.log('editor blur!', quill);
			},
			onEditorFocus(quill) {
				console.log('editor focus!', quill);
			},
			onSelectionChange(range) {
				console.log('selection change!', range);
			},
			handleClose(tag) {
				this.form.tag.splice(this.form.tag.indexOf(tag), 1);
			},
			showInput() {
				this.inputVisible = true;
				this.$nextTick(() => {
					this.$refs.saveTagInput.$refs.input.focus();
				});
			},

			handleInputConfirm() {
				console.log('handleInputConfirm');
				let inputValue = this.inputValue;
				if (inputValue) {
					this.form.tag.push(inputValue);
				}
				this.inputVisible = false;
				this.inputValue = '';
			}
		}
	};
</script>

<style lang="less" src="../../assets/css/patter.less" />
