<template>
	<div id="rights" ondrag="return false">
		<div id="qrcode" ref="qrcode"></div>
		<div class="rights-h">
			<img class="rights-himg" src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/rights/right_img_01.png" />
			<img class="VIPrights" src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/rights/right_img_04.png" @click="do_pay"/>
		</div>
		<div class="warp">
			<!-- height="530" -->
			<img class="img" src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/rights/right_img_02.png" />
			<!-- height="1950" -->
			<img class="img" src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/rights/right_img_03.png" />
			<div class="rights-s">
				<h1>- 加入科技牛，为企业发展助力 -</h1>
				<span v-if="member_info.member_price"><em>￥</em>{{member_info.member_price/100}}<em>/年</em></span>
				<button @click="do_pay">立即开通</button>
			</div>
		</div>
		<el-dialog
		  :visible.sync="centerDialogVisible"
		  width="20%"
		  center>
		  <span class="title">科技牛企业会员</span>
		  
		  <!-- <el-image style="width: 180px; height: 180px" src="https://ossweb-img.qq.com/images/lol/web201310/skin/big84000.jpg" fit="cover"></el-image> -->
		   <div id="qrcode" style="width: 100px; height: 180px;margin: 0 auto;display: flex;align-items: center;" ref="qrcode"></div>
		  <span class="price">￥{{member_info.member_price/100}}</span>
		  <span slot="footer" class="dialog-footer">
		    <span class="footer-text">支持使用微信支付</span>
			<span class="footer-deal">支付即表示同意<span class="deal">《科技牛企业会员服务协议》</span></span>
		  </span>
		</el-dialog>
	</div>
</template>

<script>

	import QRCode from 'qrcodejs2'
	export default{
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		components: {
			
		},
		data(){
			return{
				centerDialogVisible: false,
				scan: {},
				code_url: '',
				member_info:{},
			}
		},
		mounted() {
 this.get_company_member()
		},
		methods:{
			get_company_member() {
				this.$http_qm.post('company/member', {
					name: 'member_price'
				}).then(res => {
					this.member_info = res.data;
				}).catch(err => {
					console.log(err)
				})
			},
			do_pay() {
				this.centerDialogVisible = true
				if(this.code_url != ''){
					return false;
				}
				
				console.log('do_pay')
				this.$http.post('pay/pay_submit', {
					type: "wx_scan",
					order_type: 3,
				}).then(result => {
					if (result.code !== 200) {
						this.$message.info("获得订单信息失败")
						return;
					}
					this.code_url = result.data.code_url;
					new QRCode(this.$refs.qrcode, {
						text: result.data.code_url, // 需要转换为二维码的内容
						width: 100,
						height: 100,
						colorDark: '#000000',
						colorLight: '#ffffff',
						correctLevel: QRCode.CorrectLevel.H
					})
					// let data = JSON.parse(result.data)
					//函数为分装过得  (就是调用微信支付)
			
				}).catch(err => {
					console.log(err);
				})
			},
		}
	}
</script>

<style lang="less">
	#rights{
		padding: 0;
		text-align: center;
		-webkit-user-select: none; /*webkit浏览器*/
		-moz-user-select: none; /*火狐*/
		-ms-user-select: none; /*IE10*/
		user-select: none;
		.rights-h{
			margin: 0 auto;
			display: flex;
			justify-content: center;
			position: relative;
			.rights-himg{
				width: 100%;
			}
			.VIPrights{
				width: 12%;
				cursor: pointer;
				position: absolute;
				bottom: 15%;
				margin: 0 auto;
				z-index: 2;
			}
		}
		.warp{
			width: 1350px;
			margin: 0 auto;
		}
		.img{
			margin: 0 auto !important;
			width: 100%;
		}
		.rights-s{
			height: 520px;
			display: flex;
			text-align: center;
			justify-content: center;
			flex-direction: column;
			h1{
				font-size: 36px;
				font-weight: 700;
			}
			span{
				font-size: 48px;
				font-weight: 700;
				color: #e68f24;
				margin: 50px 0;
				em{
					font-size: 18px;
				}
			}
			button{
				width: 448px;
				height: 80px;
				border-radius: 50px;
				background: linear-gradient(#f4b879,#e68f24);
				font-size: 34px;
				color: #FFFFFF;
				margin: 0 auto;
				cursor: pointer;
				outline:none;
				border: none;
			}
			button:hover{
				background: #0088ff;
			}
		}
		@media(max-width:1200px){
			.rights-s{
				height: 460px;
				h1{
					font-size: 26px;
					font-weight: 700;
				}
				span{
					font-size: 32px;
					font-weight: 700;
					margin: 20px 0;
					em{
						font-size: 12px;
					}
				}
				button{
					width: 340px;
					height: 65px;
					font-size: 22px;
				}
			}
		}
		.el-dialog__body{
			padding: 15px 25px 0;
			display: flex;
			justify-content: center;
		}
		.el-dialog{
			border-radius: 10px;
		}
		.dialog-footer{
			display: flex;
			flex-direction: column;
			.footer-text{
				color: #333;
				font-size: 16px;
				font-weight: 600;
				letter-spacing: 1px;
			}
			.footer-deal{
				color: #333;
				font-size: 14px;
				font-weight: 600;
				letter-spacing: 1px;
				.deal{
					color: #0088ff;
					cursor: pointer;
				}
			}
		}
		.el-dialog__body{
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: center;
			.title{
				font-weight: 600;
				color: #825015;
				letter-spacing: 2px;
				font-size: 22px;
			}
			.el-image{
				margin: 15px auto 0;
				box-sizing: border-box;
				.el-image__inner{
					padding: 15px;
					background: #Fbfbfb;
					box-sizing: border-box;
					border: 1px solid #f0f0f0;
				}
			}
			.price{
				color: #e10100;
				font-size: 26px;
				font-weight: 700;
				margin-top: 18px;
			}
		}
	}
</style>
