<template>
	<div id="qy_message">
		<div class="wrapper clearfix">
			<div class="main_left"><LeftUser></LeftUser></div>

			<div class="content">
				<div class="page_nav clearfix"><div class="page_nav_tit">企业信息完善</div></div>
				<div class="perfect">
					<el-steps :active="active" simple>
						<el-step title="企业基本资料"></el-step>
						<el-step title="企业详细信息"></el-step>
						<el-step title="企业资质认证"></el-step>
					</el-steps>
					<div class="c-form" v-show="active == 1">
						<el-form :model="form1" ref="form1" label-width="120px">
							<el-form-item label="公司名称" prop="name" :rules="[{ required: true, message: '请输入公司名称' }]">
								<el-input v-model="form1.name" placeholder="请输入公司名称" size="mini"></el-input>
							</el-form-item>
							<el-form-item label="联系人" prop="contact_name" :rules="[{ required: true, message: '请输入联系人' }]">
								<el-input v-model="form1.contact_name" placeholder="请输入联系人" size="mini"></el-input>
							</el-form-item>
							<el-form-item label="联系人职位" prop="contact_post" :rules="[{ required: true, message: '请输入联系人职位' }]">
								<el-input v-model="form1.contact_post" placeholder="请输入联系人职位" size="mini"></el-input>
							</el-form-item>
							<el-form-item label="联系手机" prop="contact_tel" :rules="[{ required: true, message: '请输入联系手机' }]">
								<el-input v-model="form1.contact_tel" placeholder="请输入联系手机" size="mini"></el-input>
							</el-form-item>
							<el-form-item label="QQ号码" prop="contact_qq"><el-input v-model="form1.contact_qq" placeholder="请输入QQ号码" size="mini"></el-input></el-form-item>
							<el-form-item label="固定电话" prop="fixed_tel"><el-input v-model="form1.fixed_tel" placeholder="请输入固定电话" size="mini"></el-input></el-form-item>
							<el-form-item label="公司邮箱" prop="contact_email">
								<el-input v-model="form1.contact_email" placeholder="请输入公司邮箱" size="mini"></el-input>
							</el-form-item>
							<el-form-item label="所在地" prop="area" :rules="[{ required: true, message: '请输入所在地' }]" class="area">
								<el-select v-model="form1.province" @change="change_province()" placeholder="请选择省份" size="mini">
									<el-option v-for="(v, k) in city" :value="v.name" :key="k">{{ v.name }}</el-option>
								</el-select>
								<el-select v-model="form1.city" @change="change_city()" placeholder="请选择城市" size="mini">
									<el-option v-for="(v, k) in city[province_key].children" :value="v.name" :key="k">{{ v.name }}</el-option>
								</el-select>
								<el-select v-model="form1.area" placeholder="请选择区域" size="mini">
									<el-option v-for="(v, k) in city[province_key].children[city_key].children" :value="v.name" :key="k">{{ v.name }}</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="领域标签" prop="tag" :rules="[{ required: true, message: '请输入领域标签' }]">
								<el-tag :key="tag" v-for="tag in form1.tag" closable :disable-transitions="false" @close="handleClose(tag)">{{ tag }}</el-tag>
								<el-input
									class="input-new-tag"
									v-if="inputVisible"
									v-model="inputValue"
									ref="saveTagInput"
									size="small"
									@keyup.enter.native="handleInputConfirm"
									@blur="handleInputConfirm"
								></el-input>
								<el-button v-else class="button-new-tag" size="small" @click="showInput">添加自定义标签</el-button>
							</el-form-item>
							<el-form-item label="详细地址" prop="addr" :rules="[{ required: true, message: '请输入详细地址' }]">
								<el-input v-model="form1.addr" placeholder="请输入详细地址" size="mini"></el-input>
							</el-form-item>
							<el-form-item label="公司介绍" prop="introduction" :rules="[{ required: true, message: '请输入公司介绍' }]">
								<el-input :autosize="{ minRows: 3 }" type="textarea" v-model="form1.introduction" placeholder="请输入公司介绍" size="mini"></el-input>
							</el-form-item>
							<el-form-item label="营业执照注册号/统一社会信用代码" prop="business_license_number" class="number">
								<el-input v-model="form1.business_license_number" placeholder="请输入详细营业执照注册号/统一社会信用代码" size="mini"></el-input>
							</el-form-item>
							<el-form-item label="营业执照" prop="business_license" :rules="[{ required: true, message: '请上传营业执照' }]">
								<el-upload
									class="upload-demo"
									:action="baseUrl + 'file/upload_one'"
									:on-preview="handlePreview"
									:before-remove="handleRemove"
									:on-success="business_license_success"
									:data="banner_data"
									:file-list="business_license_fileList"
									:limit="1"
									list-type="picture-card"
								>
									<el-button size="small" type="primary">点击上传</el-button>
									<div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
								</el-upload>
							</el-form-item>
							<el-form-item label="公司LOGO" prop="logo" :rules="[{ required: true, message: '请上传公司LOGO' }]">
								<el-upload
									class="avatar-uploader"
									:action="baseUrl + 'file/upload_one'"
									:data="banner_data"
									:show-file-list="false"
									:on-success="logoSuccess"
									:before-upload="beforeAvatarUpload"
								>
									<img v-if="form1.logo" :src="form1.logo" class="avatar" />
									<i v-else class="el-icon-plus avatar-uploader-icon"></i>
								</el-upload>
							</el-form-item>
								<el-form-item label="产业领域" prop="territory.three" class="area" :rules="[{ required: true, message: '请选择产业领域' }]">
									<el-select v-model="form1.territory.one" @change="territory_one_change()" placeholder="请选择">
										<el-option v-for="(v1, k1) in industrylist" :value="v1.value" :label="v1.label" :key="k1">{{v1.value}}</el-option>
									</el-select>
									<el-select v-model="form1.territory.two" @change="territory_two_change()" placeholder="请选择">
										<template v-if="industrylist[territory_one] && industrylist[territory_one].children">
											<el-option v-for="(v2, k2) in industrylist[territory_one].children" :label="v2.label" :value="v2.value" :key="k2">{{v2.value}}</el-option>
										</template>
									</el-select>
									<el-select v-model="form1.territory.three" placeholder="请选择">
										<template v-if="industrylist[territory_one] && industrylist[territory_one].children[territory_two]">
											<el-option
												v-for="(v3, k3) in industrylist[territory_one].children[territory_two].children"
												:value="v3.value"
												:label="v3.label"
												:key="k3"
											>{{v3.value}}</el-option>
										</template>
									</el-select>
								</el-form-item>
							<el-form-item label="主营产品" prop="main_product" :rules="[{ required: true, message: '请输入主营产品' }]">
								<el-input v-model="form1.main_product" placeholder="请输入主营产品" size="mini"></el-input>
								<el-tooltip class="item" effect="dark" placement="right">
									<div slot="content">
										请填写企业的主萎经营产品,最多50个字符。
										<br />
										例如:木粉机，灰钙机,金鴈粉碎机，锯末机
									</div>
									<i class="el-icon-warning-outline"></i>
								</el-tooltip>
							</el-form-item>
							<el-form-item label="销售区域" prop="sales_area" :rules="[{ required: true, message: '请选择销售区域' }]" class="ball">
								<div class="areabox">
									<span v-for="(item,index) in form1.sales_area" :key="index">{{item}}</span>
								</div>
								<el-popover placement="top" width="410" trigger="click" v-model="visible">
									<el-checkbox-group v-model="form1.sales_area">
										<el-checkbox label="全国">全国</el-checkbox>
										<el-checkbox v-for="(v, k) in city" :label="v.name" :key="k">{{ v.name }}</el-checkbox>
									</el-checkbox-group>
									<div style="text-align: center; margin: 0">
										<el-button size="mini" type="text" @click="visible = false">取消</el-button>
										<el-button type="primary" size="mini" @click="visible = false">确定</el-button>
									</div>
									<el-button type="info" slot="reference" size="mini" plain>选择区域</el-button>
								</el-popover>
							</el-form-item>
							<el-form-item><el-button type="primary" @click="next(2, 'form1')" size="small">下一步</el-button></el-form-item>
						</el-form>
					</div>
					<div class="c-form" v-show="active == 2">
						<el-form ref="form2" :model="form2" label-width="120px">
							<el-form-item label="法人代表" prop="legal_person" :rules="[{ required: true, message: '请输入法人代表' }]">
								<el-input type="legal_person" v-model="form2.legal_person" placeholder="请输入法人代表" size="small"></el-input>
							</el-form-item>
							<el-form-item label="注册资金" class="ball">
								<el-input v-model="form2.registered_capital" placeholder="请输入注册资金" size="small"></el-input>
								<span>&nbsp;万元人民币</span>
							</el-form-item>
							<el-form-item label="公司类型">
								<el-input type="legal_person" v-model="form2.company_type" placeholder="请输入公司类型" size="small"></el-input>
							</el-form-item>
							<el-form-item label="经营模式">
								<el-radio-group v-model="form2.operation_mode">
									<el-radio label="生产型"></el-radio>
									<el-radio label="服务型"></el-radio>
									<el-radio label="贸易型"></el-radio>
								</el-radio-group>
							</el-form-item>
							<el-form-item label="成立时间">
								<el-date-picker
									v-model="form2.established_time"
									type="datetime"
									@change="established_time_change"
									value-format="timestamp"
									placeholder="请选择公司成立时间"
								></el-date-picker>
							</el-form-item>
							<el-form-item label="年营业额">
								<el-input type="legal_person" v-model="form2.annual_turnover" placeholder="请输入公司年营业额" size="small"></el-input>
							</el-form-item>

							<el-form-item label="年出口额">
								<el-input type="legal_person" v-model="form2.annual_export" placeholder="请输入年出口额" size="small"></el-input>
							</el-form-item>
							<el-form-item label="员工人数">
								<el-input type="legal_person" v-model="form2.employees_num" placeholder="请输入员工人数" size="small"></el-input>
							</el-form-item>
							<el-form-item label="提供OEM代工">
								<el-radio-group v-model="form2.provide_oem">
									<el-radio label="是" value="1"></el-radio>
									<el-radio label="否" value="2"></el-radio>
								</el-radio-group>
							</el-form-item>
							<el-form-item label="公司网址"><el-input v-model="form2.company_website" placeholder="请输入公司网址" size="mini"></el-input></el-form-item>
							<!-- <el-form-item label="公司banner">
								<el-upload
									class="upload-demo"
									:action="baseUrl + 'file/upload_one'"
									:on-preview="handlePreview"
									:before-remove="banner_remove"
									:on-success="banner_success"
									:data="banner_data"
									:limit="3"
									:file-list="banner_list"
									list-type="picture-card"
								>
									<el-button size="small" type="primary">点击上传</el-button>

									<div slot="tip" class="el-upload__tip">仅上传jpg/png文件，且不超过500kb,1200*360</div>
								</el-upload>
							</el-form-item> -->
							<el-form-item>
								<el-button type="primary" @click="next(3, 'form2')" size="small">下一步</el-button>
								<el-button @click="active = 1" size="small">返回上一步</el-button>
							</el-form-item>
						</el-form>
					</div>
					<div class="c-complete" v-show="active == 3">
						<el-form ref="form3" :model="form3" label-width="120px">
							<el-form-item label="资质认证标题"><el-input v-model="form3.qualification" placeholder="请输入标题..." size="mini"></el-input></el-form-item>
							<el-form-item label="资质认证图片">
								<el-upload
									class="upload-demo"
									:action="baseUrl + 'file/upload_one'"
									:on-preview="handlePreview"
									:before-remove="qualificationRemove"
									:on-success="qualification_success"
									:data="banner_data"
									:file-list="qualification_picture_list"
									list-type="picture-card"
								>
									<el-button size="small" type="primary" style="">点击上传</el-button>
									<div slot="tip" class="el-upload__tip">注：上传图片大小不超过5M 图片标题为10个以内汉字</div>
								</el-upload>
							</el-form-item>
							<el-form-item>
								<div class="attention" style="">
									<p>
										<strong>*注意</strong>
										：对于食品、保健食品、药品、医疗器械、化妆品、种子、饲料、添加剂、农药、兽药、出版物、音像制品、
										涉及第三方知识产品的物品、奥运会、亚运会、世博会等相关产品、烟花爆竹、危险化学品、二手汽车、保护动植物信息、培训机构及学校、
										再生资源回收、劳务派遣等国家规定适用许可证管理的产品，必须提供相关的资质证明，如生产许可证、经营许可证、卫生许可证、流通许可证、
										品牌授权证、授权许可证、所有权证、办学许可证、备案登记证、经营利用许可证、驯养繁殖许可证等。医疗、药品、特殊医学用途配方食品、
										医疗器械、农药、兽药、保健食品广告等法律、行政法规规定须经广告审查机关进行审查的特殊商品或者服务的广告，未经审查，不得发布。
										如果没有这些资质证明，企业审核将不予通过。
									</p>
								</div>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="onSubmit" size="small">保存</el-button>
								<el-button @click="active = 2" size="small">返回上一步</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import city from '../../assets/js/area.json';
import LeftUser from './Left_user.vue';
import { mapState } from 'vuex';
export default {
	metaInfo: {
		title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
		meta: [
			{
				name: 'keywords',
				content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
			},
			{
				name: 'description',
				content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
			}
		]
	},
	computed: {
		...mapState(['token'])
	},
	components: {
		LeftUser
	},
	data() {
		return {
			checkedCities: [],
			banner_list: [],
			qualification_picture_list: [],
			fileList: [],
			business_license_fileList: [],
			active: 1,
			banner_data: {
				dir: 'company'
			},
			form3: {
				qualification: '',
				qualification_picture: []
			},
			form2: {
				legal_person: '',
				registered_capital: '',
				company_type: '',
				operation_mode: '',
				established_time: '',
				annual_turnover: '',
				annual_export: '',
				provide_oem: '',
				company_website: '',
				employees_num: '',
				banner: []
			},
			form1: {
				name: '',
				contact_name: '',
				contact_tel: '',
				contact_qq: '',
				contact_email: '',
				contact_post: '',
				fixed_tel: '',
				province: '',
				city: '',
				area: '',
				tag: [],
				addr: '',
				business_license_number: '',
				business_license: '',
				introduction: '',
				sales_area: [],
				main_product: '',
				territory:{
					one: '',
					two: '',
					three: ''
				},
				logo: ''
			},
			detail: {},
			city: city,
			province_key: 0,
			city_key: 0,
			industrylist: [],
			territory_one: 0,
			territory_two: 0,
			visible: false,
			imageUrl: '',
			inputVisible: false,
			inputValue: '',
			has_company: 0
		};
	},
	mounted() {
		this.get_detail();
		this.get_industry_list();
	},
	methods: {
		
		established_time_change(val) {
			this.form2.established_time = val / 1000;
		},
		handleClose(tag) {
			this.form1.tag.splice(this.form1.tag.indexOf(tag), 1);
		},

		showInput() {
			this.inputVisible = true;
			this.$nextTick(() => {
				this.$refs.saveTagInput.$refs.input.focus();
			});
		},

		handleInputConfirm() {
			let inputValue = this.inputValue;
			if (inputValue) {
				this.form1.tag.push(inputValue);
			}
			this.inputVisible = false;
			this.inputValue = '';
		},
		handleAvatarSuccess(res, file) {
			this.imageUrl = URL.createObjectURL(file.raw);
		},
		beforeAvatarUpload(file) {
			const isJPG = file.type === 'image/jpeg';
			const isLt2M = file.size / 1024 / 1024 < 2;

			if (!isJPG) {
				this.$message.error('上传图片只能是 JPG格式!');
			}
			if (!isLt2M) {
				this.$message.error('上传图片大小不能超过 2MB!');
			}
			return isJPG && isLt2M;
		},
		get_detail() {
			this.$http_qm
				.post('company/detail', {
					type: 'me'
				})
				.then(res => {
					if (res.code != 200) {
						this.has_company = 0;
						return;
					}
					this.has_company = 1;
					this.detail = res.data;
					this.form1.name = this.detail.name;
					this.form1.contact_name = this.detail.contact_name;
					this.form1.contact_tel = this.detail.contact_tel;
					this.form1.contact_qq = this.detail.contact_qq;
					this.form1.contact_post = this.detail.contact_post;
					this.form1.contact_email = this.detail.contact_email;
					this.form1.fixed_tel = this.detail.fixed_tel;
					this.form1.province = this.detail.province;
					this.form1.city = this.detail.city;
					this.form1.area = this.detail.area;
					this.form1.tag = this.detail.tag != null ? this.detail.tag : [];
					this.form1.logo = this.detail.logo;
					this.form1.addr = this.detail.addr;
					this.form1.business_license = this.detail.business_license;
					if(this.detail.territory){
						this.form1.territory = this.detail.territory;
					}else{
						this.form1.territory.one = ''
						this.form1.territory.two = ''
						this.form1.territory.three = ''
					}

					if (this.detail.business_license != '') {
						this.business_license_fileList = [
							{
								url: this.form1.business_license
							}
						];
					}
					this.form1.business_license_number = this.detail.business_license_number;
					this.form1.introduction = this.detail.introduction;
					if (this.detail.sales_area != null) {
						this.form1.sales_area = this.detail.sales_area;
					}
					this.form1.main_product = this.detail.main_product;
					this.form2.legal_person = this.detail.legal_person;
					this.form2.registered_capital = this.detail.registered_capital;
					this.form2.company_type = this.detail.company_type;
					this.form2.operation_mode = this.detail.operation_mode;
					this.form2.established_time = this.detail.established_time;
					this.form2.annual_turnover = this.detail.annual_turnover;
					this.form2.annual_export = this.detail.annual_export;
					this.form2.provide_oem = this.detail.provide_oem;
					this.form2.company_website = this.detail.company_website;
					this.form2.employees_num = this.detail.employees_num;
					if (this.detail.banner != null) {
						this.form2.banner = this.detail.banner;
						var banner_list = this.banner_list;
						this.form2.banner.forEach(function(v) {
							banner_list.push({
								url: v
							});
						});
					}

					this.form3.qualification = this.detail.qualification;
					this.form3.qualification_picture = this.detail.qualification_picture;

					var qualification_picture_list = this.qualification_picture_list;
					this.form3.qualification_picture.forEach(function(v) {
						qualification_picture_list.push({
							url: v
						});
					});

					this.$forceUpdate();
				})
				.catch(err => {
					console.log(err);
				});
		},
		handleRemove(file) {
			console.log(file);
			let path = file.url;
			this.remove(path);
			this.form1.business_license = '';
		},
		qualificationRemove(file) {
			let path = file.url;
			this.remove(path);
			let qualification_picture = this.form3.qualification_picture;
			qualification_picture.forEach(function(v,k){
				if(v==path){
					qualification_picture.splice(k,1)
				}
			})
		},
		business_license_success(res) {
			console.log(res);
			this.form1.business_license = res.data.img_url;
		},
		qualification_success(res) {
			this.form3.qualification_picture.push(res.data.img_url);
		},
		logoSuccess(res) {
			this.form1.logo = res.data.img_url;
		},
		banner_success(res) {
			this.form2.banner.push(res.data.img_url);
			console.log(this.form2.banner);
		},
		next(active, form) {
			this.$refs[form].validate(valid => {
				if (valid) {
					this.active = active;
					document.querySelector('#app').scrollIntoView({
						behavior: 'auto'
					});
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		get_industry_list() {
			this.$http_qm.post('expert/cate', {type:'industry'})
				.then(res => {
					this.industrylist = res.data.industry;
				})
				.catch(err => {
					console.log(err);
				});
		},
		
		territory_one_change() {
			let that = this;
			this.industrylist.forEach(function(v, k) {
				if (v.label == that.form1.territory.one) {
					that.territory_one = k;
				}
			});
			this.form1.territory.two = '';
			this.form1.territory.three = '';
		},
		territory_two_change() {
			let that = this;
			this.industrylist[that.territory_one].children.forEach(function(v, k) {
				if (v.label == that.form1.territory.two) {
					that.territory_two = k;
				}
			});
			this.form1.territory.three = '';
		},
		change_province() {
			let that = this;
			this.form1.city = '';
			this.form1.area = '';
			this.city.forEach(function(v, k) {
				if (v.name == that.form1.province) {
					that.province_key = k;
				}
			});
			this.$forceUpdate();
		},
		change_city() {
			let that = this;
			console.log(this.province_key);
			this.city[this.province_key].children.forEach(function(v, k) {
				if (v.name == that.form1.city) {
					that.city_key = k;
				}
			});
		},
		banner_remove(file) {
			let path = file.url;
			this.remove(path);
			let banner = this.form2.banner;
			console.log(banner);
			banner.forEach(function(v, k) {
				if (v == path) {
					banner.splice(k, 1);
				}
			});
		},
		remove(path) {
			this.$http_qm
				.post('upload/remove', {
					path: path
				})
				.then(res => {
					this.$message.info(res.msg);
				})
				.catch(err => {
					console.log(err);
				});
		},

		handlePreview(file) {
			console.log(file);
		},
		onSubmit() {
			let form = this.form1;
			Object.assign(form, this.form2, this.form3);
			// console.log(form);
			// return false;
			if (this.has_company) {
				var url = 'company/update';
			} else {
				url = 'company/add';
			}
			this.$http_qm
				.post(url, form)
				.then(res => {
					this.$message.info(res.msg);
				})
				.catch(err => {
					console.log(err);
				});
		},
		toUrl(url) {
			console.log(url);
			this.$router.push({
				path: url
			});
		}
	}
};
</script>
<style lang="less" src="../../assets/css/patter.less"></style>
