<template>
	<div id="vip01_news">
		<Header :data="data" :id="id"></Header>
		<div class="pro_banner"><img src="../../../../assets/img/template/pro_banner.png" alt=""></div>
		<div class="product wrap">
			<el-tabs v-model="activeName">
				<el-tab-pane label="公司新闻" name="first">
					<div class="news_list">
						<div class="feed_box" v-for="(v,k) in news.list" :key="k" >
							<div class="feed_timer"><span>{{v.created_at.substring(5,7)}}</span><i>{{v.created_at.substring(8,10)}}</i></div>
							<span class="feed_title" @click="detail(v.id)">{{v.title}}</span>
							<p>{{v.describe}}</p>
							<div class="look_more" @click="detail(v.id)">查看详情+</div>
						</div>
					</div>
					<div class="page">
						<el-pagination :page-size="news.limit" background :current-page="currentPage" layout="prev, pager, next" :total="news.count" @current-change="handleCurrentChange"></el-pagination>
					</div>
				</el-tab-pane>
				<!-- <el-tab-pane label="行业动态" name="second" disabled></el-tab-pane> -->
			</el-tabs>

		</div>
		<Footer ref="mychild" :data="data" :id="id"></Footer>
	</div>
</template>

<script>
	import Header from "./header.vue"
	import Footer from "./footer.vue"
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				activeName: 'first',
				id: '',
				data: {},
				news: {
					page: 1,
					limit: 8,
					status: '',
					request_type: 'me',
					list: [],
					count: 0,
				},
				currentPage:1
			}
		},
		watch:{
			$route(){
				let query = this.$route.query
				if(typeof(query.page) != 'undefined'){
					this.news.page = parseInt(query.page);
					this.currentPage = parseInt(query.page);
				}
				this.get_list();
			},
		},
		created() {
			var querys = this.$route.query
			if(typeof(querys.page) != 'undefined'){
				this.news.page = parseInt(querys.page);
				this.currentPage = parseInt(querys.page);
			}else{
				this.news.page = 1;
				this.currentPage = 1;
			}
		},
		mounted() {
			let query = this.$route.query;
			this.id = query.id;
			this.get_detail();
		},
		methods: {
			get_detail() {
				this.$http_qm.post('company/detail', {
					id: this.id
				}).then(res => {
					this.data = res.data;
					this.$refs.mychild.link_list(this.data.id);
					this.get_list();
				}).catch(err => {
					console.log(err);
				})
			},
			handleCurrentChange(page) {
				this.$router.push({
					path: '/c/vip01/news',
					query: {
						page: page
					}
				});
			},
			get_list() {
				this.$http_qm.post('news/list', this.news).then(res => {
					this.news.list = res.data != null ? res.data : [],
					this.news.count = res.count
				}).catch(err => {
					console.log(err)
				})
			},
			detail(id) {
				this.$router.push({
					path: '/news/detail',
					query:{id:id}
				})
			}
		}
	}
</script>

<style lang="less">
	#vip01_news {
		.pro_banner {
			height: 280px;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.el-tabs__item {
			height: 36px;
			line-height: 36px;
			width: 75px;
			margin: 15px 15px 15px 0;
			padding: 0;
			text-align: center;
		}

		.is-active {
			color: #fff;
			background-color: #0088ff;
		}

		.el-tabs__active-bar {
			background-color: none;
		}

		.wrap {
			width: 1200px;
			margin: 0 auto;
		}
		.news_list {
		    height: auto;
		    padding-top: 50px;
			display: flex;
			flex-wrap: wrap;
			.feed_box {
			    width: 238px;
			    margin: 0 36px 50px 0;
				float: left;
				height: 178px;
				border: 1px solid #e6e6e6;
				padding: 100px 16px 40px;
				position: relative;
				&:nth-child(4n) {
					margin-right: 0;
				}
				.feed_timer {
				    width: 66px;
				    height: 70px;
				    background: url("../../../../assets/img/template/stone_icon.png") no-repeat;
				    background-position: 0 -100px;
				    position: absolute;
				    top: -1px;
				    left: -1px;
				    color: #fff;
					span {
					    font-size: 14px;
					    line-height: 30px;
					    margin-left: 10px;
					    display: block;
					}
					i {
					    font-size: 30px;
					    line-height: 40px;
					    margin-left: 26px;
					    display: block;
						font-style: normal;
					}
				}
				.feed_title {
				    font-size: 16px;
				    line-height: 30px;
				    color: #333;
				    display: block;
				    margin-bottom: 26px;
				    max-height: 60px;
					white-space:nowrap;
					overflow:hidden;
					text-overflow:ellipsis;
					cursor: pointer;
					&:hover{
						color: #0088ff;
					}
				}
				 p {
				    font-size: 14px;
				    line-height: 24px;
				    color: #666;
				    display: -webkit-box;
				    -webkit-box-orient: vertical;
				    -webkit-line-clamp: 3;
				    overflow: hidden;
				    padding-top: 20px;
				    border-top: 1px solid #ccc;
				    height: 72px;
				}
				.look_more {
				    display: block;
				    width: 98px;
				    height: 28px;
				    background: #fff;
				    border: 1px solid #e6e6e6;
				    font-size: 12px;
				    color: #0088ff;
				    line-height: 28px;
				    text-align: center;
				    position: absolute;
				    left: 50%;
				    bottom: -14px;
				    margin-left: -50px;
					cursor: pointer;
					&:hover{
						background: #0088ff;
						color: #fff;
					}
				}
			}
		}
		.page {
		    margin: 20px 0 40px 0px;
			text-align: center;
		}
	}
</style>
