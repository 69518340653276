<template>
	<div id="message">
		<Top></Top>
		<div class="account-setting-main">
			<div class="left-nav">
				<Left></Left>
			</div>
			<div class="account-setting-content">
				<div class="set-edit-baseinfo">
					<h5 class="set-item-title">基本设置</h5>
					<el-form :rules="rules" :model="formData" ref="formData" label-position="right" label-width="80px">
						<el-form-item label="头像" prop="imageUrl">
							<div class="uploadImg">
								<el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false"
								 :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
									<img v-if="formData.imageUrl" :src="formData.imageUrl" class="avatar">
									<i v-else class="el-icon-plus avatar-uploader-icon"></i>
								</el-upload>
								<el-button size="small" type="primary" @click="unload">点击上传</el-button>
							</div>
						</el-form-item>
						<el-form-item label="用户名">
							<span class="username">t_8134_oWqh9l</span>
						</el-form-item>
						<el-form-item label="昵称" prop="name">
							<el-input v-model="formData.name"></el-input>
						</el-form-item>
						<el-form-item label="性别" prop="gender">
							<el-radio-group v-model="formData.gender">
								<el-radio label="男"></el-radio>
								<el-radio label="女"></el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="生日" prop="birthday">
							<el-date-picker v-model="formData.birthday" type="date" placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
						 <el-form-item :inline="true" label="活动区域" prop="site">
							 <div class="site">
								 <el-select v-model="formData.site" placeholder="----省----">
								   <el-option label="区域一" value="shanghai"></el-option>
								   <el-option label="区域二" value="beijing"></el-option>
								 </el-select>
								 <el-select v-model="formData.site" placeholder="----市----">
								   <el-option label="区域一" value="shanghai"></el-option>
								   <el-option label="区域二" value="beijing"></el-option>
								 </el-select>
								 <el-select v-model="formData.site" placeholder="----区----">
								   <el-option label="区域一" value="shanghai"></el-option>
								   <el-option label="区域二" value="beijing"></el-option>
								 </el-select>
							 </div>
							 <el-input v-model="formData.site" placeholder="详细地址"></el-input>
						  </el-form-item>
						<el-form-item label="简介" prop="brief">
							<el-input type="textarea" placeholder="请输入400个字,支持中文,英文" v-model="formData.brief"></el-input>
						</el-form-item>

						<el-form-item>
							<el-button type="primary" @click="submitForm('formData')">保存修改</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Top from './Top.vue'
	import Left from './Left.vue'
	export default {
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		components: {
			Top,
			Left
		},
		data() {
			return {
				formData: {
					name: '',
					gender: '',
					imageUrl: '',
					brief: '',
					birthday:'',
					site:''
				},
				rules: {
					name: [{
							required: true,
							message: '请输入活动名称'
						},
						{
							min: 3,
							max: 5,
							message: '长度在 3 到 5 个字符',
							trigger: 'blur'
						}
					],
					gender: [{
						required: true,
						message: '请选择	行别',
						trigger: 'change'
					}],
					imageUrl: [{
						required: true,
						message: '请上传头像',
						trigger: 'blur'
					}],
					brief: [{
						required: true,
						message: '请填简介',
						trigger: 'blur'
					}],
					site: [{
						required: true,
						message: '请选择地址',
						trigger: 'blur'
					}],
					birthday: [{
						required: true,
						message: '请选择生日',
						trigger: 'blur'
					}],
				}
			}
		},
		methods: {
			unload() {
				document.querySelector('.avatar-uploader-icon').click();
			},
			handleAvatarSuccess(res, file) {
				this.formData.imageUrl = URL.createObjectURL(file.raw);
			},
			beforeAvatarUpload(file) {
				const isIMG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isIMG) {
					this.$message.error('上传头像图片只能是 JPG/JPEG/PNG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isIMG && isLt2M;
			},
			submitForm(formData) {
				this.$refs[formData].validate((valid) => {
					if (valid) {
						alert('submit!');
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
		}
	}
</script>

<style lang="less">
	#message {
		background: #f2f2f2;
		font-family: "Microsoft YaHei";

		.account-setting-main {
			display: flex;
			width: 1100px;
			margin: 0 auto;
			padding: 20px 0;
			background: #f2f2f2;

			.left-nav {
				width: 160px;
				float: left;
				padding-bottom: 30px;
				margin-right: 20px;
				background: #fff;
			}

			.account-setting-content {
				flex: 1;
				padding: 10px 20px;
				min-height: 600px;
				background: #fff;

				.set-edit-baseinfo {
					margin-left: 30px;
					position: relative;

					.set-item-title {
						height: 28px;
						font-weight: 650;
						line-height: 28px;
						margin-top: 10px;
						margin-bottom: 15px;
						font-family: "Microsoft YaHei";
						font-size: 18px;
						color: #333;
					}

					.el-form {
						width: 540px;
						.el-input__inner{
							width: 460px;
						}
						.uploadImg {
							display: flex;
							flex-direction: row;

							.el-button {
								margin-top: 5px;
								margin-left: 18px;
								width: 88px;
								height: 32px;
								background-color: #fff;
								box-sizing: border-box;
								border: 1px solid #0088ff;
								font-size: 14px;
								text-align: center;
								color: #0088ff;
							}
						}

						.avatar-uploader {
							.el-upload {
								border: 1px dashed #d9d9d9;
								border-radius: 6px;
								cursor: pointer;
								position: relative;
								overflow: hidden;
							}

							.el-upload:hover {
								border-color: #409EFF;
							}
						}

						.avatar-uploader-icon {
							font-size: 28px;
							color: #8c939d;
							width: 100px;
							height: 100px;
							line-height: 100px;
							text-align: center;
						}

						.avatar {
							width: 100px;
							height: 100px;
							display: block;
						}

						.username {
							font-size: 14px;
							color: #333;
						}
						.site{
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							.el-input__inner{
								width: 140px !important;
								margin-bottom: 10px;
							}
						}
					}
				}
			}
		}
	}
</style>
