<template>
	<div id="qy_status">
		<div class="wrapper">
			<div class="main_left">
				<Left></Left>
			</div>

			<div class="content">
				<div class="companyname">
					<span>{{time_text}}好，{{data.name}}</span>
					<em>[查看企业网站]</em>
				</div>
				<div class="rolechoose">
					<div @click="update_company('供应商')" class="rolechoose_supplier a">我是供应商</div>
					<div @click="update_company('采购商')" class="rolechoose_purchase a">我是采购商</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Left from "./Left.vue"
	export default {
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		components:{
			Left
		},
		data() {
			return {
				data: {
				},
				time_text: '',
			}
		},
		mounted() {
			//获取企业名
			var d = new Date();
			var n = d.getHours();
			if (n <= 12) {
				this.time_text = '上午'
			} else {
				this.time_text = '下午'
			}
			this.get_detail();
		},
		methods: {
			get_detail() {
				this.$http_qm.post('company/detail', {
					'type': 'me'
				}).then(res => {
					if (res.code == 200) {
						this.data = res.data
					}
				}).catch(err => {
					console.log(err)
				})
			},
			update_company(identity) {
				var url = 'company/update_identity'
				this.$http_qm.post(url, {
					identity: identity
				}).then(res => {
					this.$message.info(res.msg)
					if (res.code == 501) {
						this.$router.push({
							path: '/login'
						})
					}
				}).catch(err => {
					console.log(err);
				})
			},
			toUrl(url) {
				console.log(url)
				this.$router.push({
					path: url
				})
			}
		}
	}
</script>

<style lang="less" src="../../assets/css/expert.less"></style>
