<template>
	<div id="qy_column">
		<el-tree :data="data" node-key="id" :default-expanded-keys="[1,2, 3,4,5,6,7,8,9]" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
	</div>
</template>

<script>
	export default {
		props: {},
		data() {
			return {
				member:true,
				data: [{
						id: 1,
						label: '账号管理',
						children: [{
							label: '基本信息',
							tourl: 'zcMessage'
						}, {
							label: '账号密码',
							tourl: 'zcPassword'
						}, {
							label: '账号验证',
							tourl: 'zcVerify'
						}]
					},
			
				],
				
				defaultProps: {
					children: 'children',
					label: 'label'
				}
			}
		},
		created() {
			this.get_detail();
		},
		methods: {
			handleNodeClick(data) {
				if (data.tourl != "" && data.tourl != undefined) {
					this.$router.push({
						name: data.tourl
					})
				}
			},
			get_detail() {
				this.$http_qm
					.post('company/detail', {
						type: 'me'
					}).then(res => {
						if (res.code == 200) {
							this.company_detail = res.data;
							if (res.data.status == 1 ) {
								this.member = true
							}
						}else if(res.code == 400){
							this.member = false
						}
					})
					.catch(err => {
						console.log(err);
					});
			},
		}
	}
</script>

<style lang="less">
	.el-tree {
		color: #333 !important;

		.el-tree-node__content {
			height: 35px !important;
		}
	}
</style>
