<template>
	<div id="shop">
		<Top v-if="company_data.name" :data="company_data" :TabCur="1"></Top>

		<div class="new-shop-index shop-case about-shop">

			<!-- top 最新产品 -->
			<div class="w1200 flex-column" v-if="TabCur==1">
				<div class="pro-base-info clearfix margin-top-20">
					<div class="img-box fl">
						<div class="media-slide">

							<el-carousel indicator-position="none" :autoplay="false" ref="carousel" >
								<el-carousel-item v-for="item in data.cover" :current-page="current_cover" :key="item">
									<el-image fit="cover"  style="width:400px; height: 400px;" :src="item" alt=""> </el-image>
								</el-carousel-item>
								
							</el-carousel>
							<ul class="cover2">
								<li v-for="(item,k) in data.cover" @click="cover_click(k)" :key="item">
									<el-image fit="cover" style="width:50px; height:50px;" :src="item" alt=""> </el-image>
								</li>
							</ul>
						</div>
					</div>

					<div class="txt-box fl margin-left-30">
						<div class="title-box">
							<h1 class="f20 text-bold">
								<em style="color: #0088ff">
									[最新产品]
								</em>
								{{data.title}} </h1>
						</div>
						<div class="tag-box">
							<span class="f12 margin-right-10">输送</span>
						</div>
						<div class="sku-box margin-top-30">
							<ul class="clearfix">
								<li class="fl margin-right-10">
									<label for="" class="f14 text-muted">产品名称</label>
									<p class="f14"> {{data.title}} </p>
								</li>
								<li class="fl margin-right-10">
									<label for="" class="f14 text-muted">所属行业</label>
									<p class="f14" v-if="data.cate">{{data.cate.two_name}} </p>
								</li>

								<li class="fl margin-right-10">
									<label for="" class="f14 text-muted">项目金额</label>
									<p class="f14">￥{{data.price}}</p>
								</li>

							</ul>
						</div>

						<p class="line"></p>
						<!-- <table class="contrast">
							<tbody>
								<tr>
									<td>自动化解决方案</td>
									<td>用工情况</td>
									<td>产能(每8小时)</td>
									<td>良品率</td>
								</tr>
								<tr>
									<td>实施前</td>
									<td>1人</td>
									<td>--</td>
									<td>--</td>
								</tr>
								<tr>
									<td><i class="iconfont iconpraise_fill"></i><em class="padding-left-15">实施后</em></td>
									<td>--</td>
									<td>--</td>
									<td>--</td>
								</tr>
							</tbody>
						</table> -->

						<div class="ask-for-box">
							<router-link class="cur-por" :to="{path:'/store/leave?',query:{id:company_id,product_id:data.id}}">
								<i class="el-icon-edit"></i>留言咨询</router-link>
							<span @click="dialogVisible = true">
								<i class="el-icon-user"></i>联系企业
							</span>
						</div>
						<!-- 商家聊天 -->
						<el-dialog title="站内消息" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
							<div class="msg_dialogue">
								<!--聊天记录一列 -->
								<div class="msg_dialogue_list"  v-for="(v,index) in chat.list" :key="index">
									<div class="wp-chatlist wp-chatlist-self">
										<!-- right -->
										<template  v-if="user.id == v.uid">
											<div class="avatar_right">{{v.avatar_name}}</div>
											<div class="wp-right-content">
												<em class="wp-arrow"></em>
												<em class="wp-arrow wp-arrow-inner"></em>
												<p v-html="v.content"></p>
											</div>
											<div class="wp-chat-time">{{v.time}}</div>
										</template>
										<template v-else>
											<!-- left -->
											<div class="avatar_left">{{v.avatar_name}}</div>
											<div class="wp-left-content">
												<em class="wp-arrow"></em>
												<em class="wp-arrow wp-arrow-inner"></em>
												<p v-html="v.content"></p>
											</div>
											<div class="wp-chat-time">{{v.time}}</div>
										</template>
									</div>
						
								</div>
								<!--聊天记录一列end-->
							</div>
							<div class="send">
								<span>发消息</span>
								<el-input type="textarea" v-model="message" :rows="2" placeholder="请输入内容"></el-input>
							</div>
							<span slot="footer" class="dialog-footer">
								<el-button type="danger" size="small" @click="dialogVisible = false">关闭</el-button>
								<el-button type="primary" size="small" @click="send()">发送</el-button>
							</span>
						</el-dialog>
					</div>
				</div>
			</div>
			<!-- bottom -->
			<div class="w1200 margin-top-20 clearfix">
				<!-- left -->
				<Left :data="company_data" :id="company_id" :product_id="data.id"></Left>
				<!--Cur2 案例信息 -->
				<div class="right-panel fl margin-left-20" v-if="TabCur==1">
					<div class="tab-info margin-bottom-40">
						<p class="tab-info-title"><i></i><em>设备布局</em></p>
						<div class="tab-info-detail margin-top-25 margin-bottom-40" v-html="data.introduction">

						</div>

						<!-- 		<p class="tab-info-title" id="crafts"><i></i><em>工艺流程</em></p>
						<div class="tab-info-detail margin-top-25 margin-bottom-40">
							<div class="crafts-list">
								<p>
									自动化输送 </p>
							</div>
							<div class="crafts-list">
								<p>
									自动化分瓶，使连在一起的产品分开一定的距离（可调） </p>
							</div>
						</div>

						<p class="tab-info-title" id="effect"><i></i><em>项目成效</em></p>
						<div class="tab-info-detail margin-top-25">
							<table class="contrast">
								<tbody>
									<tr>
										<td>自动化解决方案</td>
										<td>用工情况</td>
										<td>产能(每8小时)</td>
										<td>良品率</td>
									</tr>
									<tr>
										<td>实施前</td>
										<td>1人</td>
										<td>--</td>
										<td>--</td>
									</tr>
									<tr>
										<td><i class="iconfont iconpraise_fill"></i><em class="padding-left-15">实施后</em></td>
										<td>--</td>
										<td>--</td>
										<td>--</td>
									</tr>
								</tbody>
							</table>
						</div>
				 -->
					</div>

				</div>

			</div>

		</div>
	</div>
</template>

<script>
	import Top from "./top.vue"
	import Left from "./left.vue"
	import config from '@/config'
	var wsUrl = process.env.NODE_ENV === 'development' ? config.wsUrl.dev : config.wsUrl.pro;
	
	import {
		mapGetters
	} from 'vuex'
	export default {
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		components: {
			Top,
			Left,
		},
		computed: {
			...mapGetters(['user'])
		},
		data() {
			return {
				id: '',
				company_id: '',
				title_list: [{
					title: '店铺首页',
					path: '/store/index'
				}, {
					title: '最新产品',
					path: '/store/anli'
				}, {
					title: '关于我们',
					path: '/store/about'
				}, {
					title: '留言咨询',
					path: '/store/leave'
				}],
				TabCur: 1,
				img: '',
				data: {},
				company_data:{},
				current_cover:0,
				message:'',
				dialogVisible:false,
				chat_post: {
					page: 1,
					limit: 1000,
					room_id: '',
				},
				chat: {
					list: [],
				},
				room_id: '',
				to_uid: '',
				ws: null,
			}
		},
		mounted() {
			let query = this.$route.query;
			if(typeof(query.id) != 'undefined'){
				this.id = query.id;
			}
			if(typeof(query.company_id) != 'undefined'){
				this.company_id = query.company_id;
			}
			
			this.get_detail();
            this.get_company_detail();
			
		},
		methods: {
			get_chat_list() {
				console.log('room_id', this.room_id);
				if (this.room_id == '') {
					return
				}
				this.chat_post.room_id = this.room_id
				this.$http.post('chat/list', this.chat_post).then(res => {
			
					if (res.data != null) {
						this.chat = res.data
						// this.chat_post.page += 1
					} else {
						// this.chat.loading = false
					}
				}).catch(function(error) {
					console.log(error)
				})
			},
			join_room() {
				let add_room_data = {
					'method': 'join.room',
					'params': {
						'roomid': this.room_id,
						'uid': this.data.uid,
					},
					id: this.room_id
				}
				// id 必须存在 否则回报错
				add_room_data = JSON.stringify(add_room_data)
				this.ws.send(add_room_data)
				console.log('加入房间' + this.room_id)
			},
			send() {
			
				let send_query = {
					'method': 'chat.emit',
					'params': {
						'message': this.message,
						'uid': this.user.id,
						'to_uid': this.data.uid,
						'room_id': this.room_id
					},
					'id': this.room_id
				}
				send_query = JSON.stringify(send_query)
				this.ws.send(send_query)
			},

			handleClose(done) {
				this.$confirm('确认关闭？')
					.then(() => {
						done();
					})
					.catch(() => {});
			},
			cover_click(k){
				console.log(k);
				this.$refs.carousel.setActiveItem(k);
			},
			submit() {
				this.$http_qm.post('leave_message/add', this.form).then(res => {
					this.$message(res.msg);
				}).catch(err => {
					console.log(err);
				})
			},
			get_company_detail() {
				this.$http_qm.post('company/detail', {
					id: this.company_id
				}).then(res => {
					this.company_data = res.data;
					this.get_room_id()
				}).catch(err => {
					console.log(err);
				})
			},
			get_room_id() {
				this.$http.post('room/get_id', {
					to_uid: this.data.uid
				}).then(res => {
					if (res.code == 200) {
						this.room_id = res.data.id;
						this.webSocket_init()
						
						this.get_chat_list();
					}
				}).catch(err => {
					console.log(err)
				})
			},
			webSocket_init() {
			
				if ('WebSocket' in window) {
					console.log('您的浏览器支持 WebSocket!')
					let ws_url = wsUrl
					this.ws = new WebSocket(ws_url)
					// 连接成功建立的回调方法
					this.ws.onopen = this.onopen
					this.ws.onmessage = this.onmessage
					// 连接发生错误的回调方法
					this.ws.onerror = this.onerror
					// 连接关闭的回调方法
					this.ws.onclose = this.onclose
				} else {
					// 浏览器不支持 WebSocket
					this.$message.info('您的浏览器不支持 WebSocket!')
				}
			},
			onclose() {
				// 关闭 websocket
				console.log('连接已关闭...')
			},
			onerror(e) {
				console.log('websocket服务出错了---onerror', e)
			},
			onopen() {
				this.join_room()
			},
			onmessage(res) {
				let data = JSON.parse(res.data)
				this.chat.list.push(data.result)
				this.$nextTick(() => {
					this.message = ''
				})
			},
			get_detail() {
				this.$http_qm.post('product/detail', {
					id: this.id
				}).then(res => {
					this.data = res.data;
				}).catch(err => {
					console.log(err);
				})
			},
			tabSelect(url) {
				this.$router.push({
					path: url
				})
			},
		}
	}
</script>

<style lang="less">
	.msg_dialogue {
	    margin-top: 0px;
	    width: 100%;
	    height: 240px;
	    overflow-x: hidden;
	    overflow-y: auto;
	    margin-bottom: 10px;
		.msg_dialogue_list {
		    margin-bottom: 10px;
			.wp-chatlist {
			    position: relative;
			    padding: 6px 0;
				.avatar_right {
				    float: right;
				    margin: 0 0 0 5px;
					width: 80px;
				}
				.avatar_left{
					float: left;
					margin: 0 0 0 5px;
					width: 80px;
				}
				.wp-right-content {
				    float: right;
				    margin: 0 10px 0 0;
				    border: 1px solid #32CD32;
				    background: #32CD32;
				    margin-bottom: 10px;
					position: relative;
					padding: 5px 7px;
					border-radius: 3px;
					max-width: 230px;
					.wp-arrow {
					    left: auto;
					    right: -6px;
					    border: 0;
					    border-left: 6px solid #32CD32;
					    border-top: 6px solid transparent;
					    border-bottom: 6px solid transparent;
					    background: none;
						position: absolute;
						top: 5px;
						width: 0;
						height: 0;
					}
					.wp-arrow-inner {
					    left: auto;
					    right: -5px;
					    border-left: 6px solid #32CD32;
					}
					p {
					    min-height: 16px;
					    font-size: 14px;
					    word-break: break-all;
					    word-wrap: break-word;
					    font-family: Microsoft YaHei, 微软雅黑, 宋体, simsun;
						color: #fff;
					}
				}
				.wp-left-content{
					float: left;
					margin: 0 10px 0 0;
					border: 1px solid #F1F1F1;
					background: #FAFAFA;
					margin-bottom: 10px;
					position: relative;
					padding: 5px 7px;
					border-radius: 3px;
					max-width: 230px;
					.wp-arrow {
					    right: auto;
					    left: -6px;
					    border: 0;
					    border-right: 6px solid #F1F1F1;
					    border-top: 6px solid transparent;
					    border-bottom: 6px solid transparent;
					    background: none;
						position: absolute;
						top: 5px;
						width: 0;
						height: 0;
					}
					.wp-arrow-inner {
					    right: auto;
					    left: -5px;
					    border-right: 6px solid #F1F1F1;
					}
					p {
					    min-height: 16px;
					    font-size: 14px;
					    word-break: break-all;
					    word-wrap: break-word;
					    font-family: Microsoft YaHei, 微软雅黑, 宋体, simsun;
					}
				}
				.wp-chat-time {
					display:block;
				    font-size: 14px;
				    font-family: Tahoma, Verdana, STHeiTi, simsun, sans-serif;
				    color: #AAA;
				    clear: both;
				    text-align: center;
				}
			}
		}
	}
</style>
