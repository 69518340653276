<template>
	<div id="vip01_contact">
		<Header :data="data" :id="id"></Header>
		<div class="pro_banner"><img src="../../../../assets/img/template/pro_banner.png" alt=""></div>
		<div class="contact01">
			<div id="allmap" class="map">
				<baidu-map :center="data.company_website" :zoom="12" @ready="handler" style="height:400px" @click="getClickInfo" :scroll-wheel-zoom='true'></baidu-map>
			</div>
			<div class="box">
				<div class="Leaving">
					<h3 class="title"><span>在线</span>留言</h3>
					<el-form :rules="rules" ref="ruleForm" label-position="right" label-width="80px" :model="form">
						<el-form-item label="主题" prop="title">
							<el-input v-model="form.title"></el-input>
						</el-form-item>
						<el-form-item>
							<el-checkbox-group v-model="form.type">
								<el-checkbox :label="v" v-for="(v,k) in type_arr" :key="k" :value="v"></el-checkbox>
							</el-checkbox-group>
						</el-form-item>
						<el-form-item label="详细描述" prop="describe">
							<el-input type="textarea" v-model="form.describe"></el-input>
						</el-form-item>
						<el-form-item label="公司名称" prop="contact_company_name">
							<el-input v-model="form.contact_company_name"></el-input>
						</el-form-item>
						<el-form-item label="联系人" prop="contact_name">
							<el-input v-model="form.contact_name"></el-input>
						</el-form-item>
						<el-form-item label="联系电话" prop="contact_tel">
							<el-input v-model="form.contact_tel"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button @click="submit()" type="primary">立即提交</el-button>
						</el-form-item>
					</el-form>
				</div>
				<div class="lianxi">
					<h3 class="title"><span>联系</span>方式</h3>
					<p>联系人：{{data.contact_name}}</p>
					<p>联系人职位：{{data.contact_post}}</p>
					<p>固话：{{data.fixed_tel}}</p>
					<p>手机：{{data.contact_tel}}</p>
					<p>QQ：{{data.contact_qq}}</p>
					<p>邮箱：{{data.contact_email}}</p>
					<p>地址：{{data.company_website}}</p>
				</div>
			</div>

		</div>

		<Footer  ref="mychild" :data="data" :id="id"></Footer>
	</div>
</template>

<script>
	import Header from "./header.vue"
	import Footer from "./footer.vue"
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				id: '',
				product_id: '',
				data: {},
				form: {
					title: '',
					type: [],
					describe: '',
					contact_company_name: '',
					contact_name: '',
					contact_tel: '',
					company_id: '',
				},
				type_arr: ['了解最新产品',
					'寻求解决方案',
					'咨询技术问题',
					'咨询产品报价',
					'其它'
				],
				rules: {
					title: [{
						required: true,
						message: '请输入活动名称',
						trigger: 'blur'
					}],
					describe: [{
						required: true,
						message: '请输入详细描述',
						trigger: 'blur'
					}],
					contact_company_name: [{
						required: true,
						message: '请输入公司名称',
						trigger: 'blur'
					}],
					contact_name: [{
						required: true,
						message: '请输入联系人',
						trigger: 'blur'
					}],
					contact_tel: [{
						required: true,
						message: '请输入联系电话',
						trigger: 'blur'
					},{ min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
						{
							pattern: /^1[3|4|5|7|8][0-9]\d{8}$/,
							message: "请输入正确的手机号码"
						}],
					region: [{
						required: true,
						message: '请选择活动区域',
						trigger: 'change'
					}],
					date1: [{
						type: 'date',
						required: true,
						message: '请选择日期',
						trigger: 'change'
					}],
					date2: [{
						type: 'date',
						required: true,
						message: '请选择时间',
						trigger: 'change'
					}],
					type: [{
						type: 'array',
						required: true,
						message: '请至少选择一个活动性质',
						trigger: 'change'
					}],
					resource: [{
						required: true,
						message: '请选择活动资源',
						trigger: 'change'
					}]
				},
			}
		},
		mounted() {
			let query = this.$route.query;
			this.id = query.id;
			if (typeof(query.product_id) != 'undefined') {
				this.product_id = query.product_id;
			}
			this.get_detail();
		},
		methods: {
			submit() {
				this.$refs["ruleForm"].validate((valid) => {
					if (valid) {
						this.form.company_id = this.id
						this.form.product_id = this.product_id;
						let data = this.form
						data.contact_tel = Number(data.contact_tel)
						this.$http_qm.post('leave_message/add', data).then(res => {
							this.$message(res.msg);
						}).catch(err => {
							console.log(err);
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});

			},
			get_detail() {
				this.$http_qm.post('company/detail', {
					id: this.id
				}).then(res => {
					this.data = res.data;
					this.$refs.mychild.link_list(this.data.id);
				}).catch(err => {
					console.log(err);
				})
			},
			getClickInfo(e) {
				this.center.lng = e.point.lng
				this.center.lat = e.point.lat
			}
		}
	}
</script>

<style lang="less">
	#vip01_contact {
		.pro_banner {
			height: 280px;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.contact01 {
			width: 1200px;
			margin: auto;
			padding-top: 30px;

			.map {
				width: 1200px;
				height: 402px;
				overflow: hidden;
			}

			.box {
				display: flex;
				flex-direction: row;
				justify-content: space-between;

				.Leaving {
					width: 580px;
					float: left;
					margin-right: 109px;

					.title {
						font-size: 14px;
						color: #0088ff;
						line-height: 32px;
						padding-top: 10px;
						margin-bottom: 30px;

						span {
							display: inline-block;
							border-bottom: 2px solid #0088ff;
						}
					}
				}

				.lianxi {
					width: 500px;
					float: left;
					background: url(../../../../assets/img/template/contact02.jpg) 0 76px no-repeat;
					min-height: 350px;

					.title {
						margin-bottom: 23px;
						font-size: 14px;
						color: #0088ff;
						line-height: 32px;
						padding-top: 10px;

						span {
							display: inline-block;
							border-bottom: 2px solid #0088ff;
						}
					}

					p {
						padding-left: 45px;
						font-size: 14px;
						color: #666;
						line-height: 40px;
					}
				}
			}

		}
	}
</style>
