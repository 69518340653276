<template>
	<div id="login" style="background-image: url(https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/login_bg1.jpg);">
		<div class="kjn-header">
			<div class="account-header" id="utopia_widget_1">
				<div class="grid">
					<div class="logo">
						<div class="logo-img"></div>
						<h1 class="logo-title">欢迎登录</h1>
					</div>
				</div>
			</div>
		</div>

		<div class="content" >

			<div class="kjn-grid">
				<div class="box-warp">
					<div class="box-form">
						<el-tabs v-model="activeName" @tab-click="handleClick">
							<el-tab-pane label="账号密码登录" name="code">
								<div class="login-form">
									<div class="input-new">
										<img src="//as.zbjimg.com/static/nodejs-zbj-account-web/widget/login/login-form/login-password-form/img/username-input_4485dfc.png"
										 class="left" width="37px">
										<input id="username" name="username" v-model="form1.tel" class="username-input" type="text" placeholder="手机号/邮箱/用户名"
										 tabindex="1">
									</div>
									<div class="input-new">
										<img src="//as.zbjimg.com/static/nodejs-zbj-account-web/widget/login/login-form/login-password-form/img/password-input_d4f84c3.png"
										 class="left" width="37px">
										<input id="password" name="password" v-model="form1.password" class="username-input" type="password"
										 placeholder="密码" tabindex="2">
									</div>
									<div class="slip" style="display: none;">
										<label>
											<input type="checkbox" name="cache" value="1" tabindex="4">
											<span>两周内自动登录</span>
										</label>
										<span class="forget-password">忘记密码</span>
										<span class="forget-password" style="color:#ff6600;margin-right: 10px">账号申诉</span>
									</div>
									<div class="login-form-button">
										<button class="button_login" tabindex="5" @click="do_login(1)" type="button" data-linkid="10101304">登&nbsp;录</button>
									</div>
								</div>
							</el-tab-pane>
							<el-tab-pane label="手机动态登录" name="auth">
								<div class="login-form">
									<div class="input-new">
										<img src="//as.zbjimg.com/static/nodejs-zbj-account-web/widget/login/login-form/login-password-form/img/username-input_4485dfc.png"
										 class="left" width="37px">
										<input id="username" name="username" v-model="form2.tel" class="username-input" type="text" placeholder="手机号/邮箱/用户名"
										 tabindex="1">
									</div>
									<div class="flex-row">
										<div class="input-new">
											<img src="//as.zbjimg.com/static/nodejs-zbj-account-web/widget/login/login-form/login-password-form/img/password-input_d4f84c3.png"
											 class="left" width="37px">
											<input id="username" name="username" v-model="form2.code" class="username-input" type="password" placeholder="密码"
											 tabindex="2">
										</div>
										<button type="button" class="get-dynamic-code" @click="get_code()">{{get_code_text}}</button>
									</div>

									<div class="slip" style="display: none;">
										<span class="forget-password" style="color:#ff6600;margin-right: 10px">账号申诉</span>
									</div>
									<div class="login-form-button">
										<button class="button_login" tabindex="5" type="button" @click="do_login(2)" data-linkid="10101304">登&nbsp;录</button>
									</div>
								</div>
							</el-tab-pane>
						</el-tabs>
						<div class="login-other-ways-login login-other-ways">
							<div class="authorize-login">
								<p class="clearfix authorize-login-title">
									<span class="authorize-login-title-left" style="display: none;">使用第三方账号登录</span>
									<span class="authorize-link" @click="toUrl('/register')">注册新账号&gt;</span>
								</p>
								<ul class="clearfix authorize-login-list" style="display: none;">
									<li class="authorize-login-item" data-linkid="10101308">
										<span class="j-authorize-login-item wechat-login" title="微信登录" style="margin-top: 0px;"></span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="account-footer" id="utopia_widget_15">
			<div class="zbj-grid zbj-footer-grid">
				<div class="footer-sitelink flex center">
					<h5>关于我们</h5>
					<span class="split">|</span>
					<h5>联系我们</h5>
					<span class="split">|</span>
					<h5>专家服务</h5>
					<span class="split">|</span>
					<h5>新闻中心</h5>
					<span class="split">|</span>
					<h5>热门服务</h5>
				</div>
				<p class="footer-copyright">Copyright http://www.keji6.cn/ 版权所有
					<span>沪ICP备16055134号-1</span>
				</p>
				<div class="footer-gov" style="display: none;">
					<div class="info">
						<img v-if="img" :src="img">
						<span v-else class="footer-gov-text">工商网监电子标识</span>
					</div>
					<div class="info">
						<img v-if="img" :src="img">
						<span v-else class="footer-gov-text">工商网监电子标识</span>
					</div>
					<div class="info">
						<img v-if="img" :src="img">
						<span v-else class="footer-gov-text">工商网监电子标识</span>
					</div>
					<div class="info">
						<img v-if="img" :src="img">
						<span v-else class="footer-gov-text">工商网监电子标识</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapMutations
	} from "vuex"
	export default {
		data() {
			return {
				img: '//as.zbjimg.com/static/nodejs-zbj-account-web/widget/accountFooter/images/gov3_f555083.jpg',
				activeName: 'code',
				form1: {
					tel: '',
					password: '',
				},
				form2: {
					tel: '',
					code: '',
				},
				get_code_text: '获取验证码',
				second: 60,
			}
		},
		methods: {
			...mapMutations(['setLogin']),
			handleClick(tab, event) {
				console.log(tab, event);
			},
			do_login(type) {
				if (type == 1) {
					var form = this.form1
				} else {
					form = this.form2
				}

				this.$http.post('user/login', form).then(res => {
					this.$message(res.msg);
					if (res.code == 200) {
						this.setLogin(res.data);
						this.$router.push({path:'/'});
					}
				}).catch(err => {
					console.log(err);
				})
			},
			get_code() {
				if (this.form2.tel == '') {
					this.$message.error('请输入手机号');
					return false;
				}
				this.$http.post('user/get_code', {
					tel: this.form2.tel,
					template: 'login',
				}).then(res => {
					if (res.code == 200) {
						var interval = setInterval(() => {
							--this.second;
							this.get_code_text = '重新获取' + '(' + this.second + ')';
						}, 1000)
						setTimeout(() => {
							clearInterval(interval)
							this.second = 60;
							this.get_code_text = '获取验证码';
						}, 60000)
					}

				}).catch(err => {
					console.log(err);
				})
			},
			toUrl(url) {
				this.$router.push({
					path: url
				})
			}
		}
	}
</script>

<style lang="less" src="../../assets/css/patter.less"></style>
<style scoped>
	#login{
		overflow:hidden; max-height:1080px;
	}
	.footer-sitelink{
		justify-content: center;
	}
	@media screen and (max-width: 750px) {

		.kjn-grid .box-warp {
			margin:0 20px;
			width: 100% !important;
		}
		.kjn-grid .box-warp .box-form{
			width: 100%;
			height: 100%;
		}
	}

	@media screen and (max-width: 1620px) {
		.content {
			background-size: cover !important;
		}
	}

	@media screen and (max-width: 1920px) {
		.content {
			background-size: 100% 100%;
		}

		.kjn-grid {
			margin: 0 auto;
			max-width: 1200px;
			width: 100%;
			display: flex;
			
		}

		.kjn-grid .box-warp {
			width: 328px;
			display: flex;
		
		}
	}
</style>
