import axios from 'axios' //引入axios
import store from '@/store/index' //引入store
import router from '@/router' //引入router
import config from '@/config'
import tool from '@/api/tool.js'
// import qs from 'qs';
import md5 from 'crypto-js/md5';
export const baseUrl = process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro
export const baseUrlqm = process.env.NODE_ENV === 'development' ? config.qmbaseUrl.dev : config.qmbaseUrl.pro

export let http = axios.create({
	baseURL: baseUrl,
	headers: {
		
	},
})

export let httpqm = axios.create({
	baseURL: baseUrlqm,
})

// http request 拦截器
http.interceptors.request.use(
	config => {
		const token = localStorage.getItem('token')
		if (token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
			config.headers.authorization = 'Bearer ' + token //请求头加上token
		}
		let data = config.data;

		if (config.url == 'file/upload_one') {
			return config
		}
		// config.headers['Content-Type'] = "application/x-www-form-urlencoded";
		if (typeof(data) != 'undefined' && typeof(data.file) == 'undefined') {
			var time = (Date.now() - (Date.now() % (60 * 1000))) / 1000
			data.time = time;
			var str = Object.keys(data).map(function(key) {
				return key + "=" + data[key];
			}).join("&");
			data.sign = md5(str + 'kjnxxy').toString();
			config.data = data
			// config.data = qs.stringify(data);
		}
		return config
	},
	err => {
		return Promise.reject(err)
	})

// httpqm request 拦截器
httpqm.interceptors.request.use(
	config => {
		const token = localStorage.getItem('token')
		if (token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
			config.headers.authorization = 'Bearer ' + token //请求头加上token
		}
		delete config.data['time'];
		delete config.data['sign'];
		var length = 0;
		for (var ever in config.data) {
			length++;
		}
		console.log(ever);
		if (length > 0) {
			var time = (Date.now() - (Date.now() % (60 * 1000))) / 1000
			config.data.time = time;
			var str = tool.jsonSort(config.data)
			config.data.sign = md5(str + 'kjnxxy').toString();
		}
		return config
	},
	err => {
		return Promise.reject(err)
	})

// http response 拦截器
http.interceptors.response.use(
	response => {
		//拦截响应，做统一处理 
		if (response.data.code) {
			switch (response.data.code) {
				case 401:
					store.state.isLogin = false
					router.replace({
						path: '/login',
						query: {
							redirect: router.currentRoute.fullPath
						}
					})
			}
		}
		return response.data
	},
	//接口错误状态处理，也就是说无响应时的处理
	error => {
		return Promise.reject(error.response) // 返回接口返回的错误信息
	})

httpqm.interceptors.response.use(
	response => {
		//拦截响应，做统一处理 
		if (response.data.code) {
			switch (response.data.code) {
				case 401:
					store.state.isLogin = false
					router.replace({
						path: '/login',
						query: {
							redirect: router.currentRoute.fullPath
						}
					})
			}
		}
		return response.data
	},
	//接口错误状态处理，也就是说无响应时的处理
	error => {
		return Promise.reject(error.response) // 返回接口返回的错误信息
	})

export default {
	http: http,
	httpqm: httpqm,
	baseUrl,
	baseUrlqm
}
