<template>
	<div id="expert_list">
     <Top></Top>

		<article class="main">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item>专家列表</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="search-listbox search-margin">
				<div class="sslist-ylxs" v-for="(v, k) in classify" :key="k">
					<strong>{{ v.title }}：</strong>
					<div :class="v.active ? 'active' : 'unfold'" class="sslist-ssnr">
						<el-radio-group v-model="radio[k]" size="small" @change="tagChange">
							<el-radio-button :label="s" :value="s" v-for="(s, y) in v.tags" :key="y"></el-radio-button>
						</el-radio-group>
					</div>
					<span v-if="k != 2" :class="v.active ? 'item-down' : 'item-span'" v-html="v.active ? '收起' : '展开'" @click="v.active = !v.active"></span>
				</div>
			</div>
			<div class="contentMain">
				<div class="left"></div>
				<div class="right">
					<div class="infinite-list-wrapper" style="overflow:initial">
						<ul class="list">
							<div class="expertitem" v-for="(v, k) in list" :key="k" @click="expert_detail(v._id.$oid)">
								<div class="touxiang" >
									<el-image style="width: 130x; height: 131px" v-if="v.img" :src="v.img" fit="cover"></el-image>
									<el-image style="width: 130x; height: 130px" v-else-if="v.user_info && v.user_info.avatar"
									 fit="cover" :src="v.user_info.avatar"></el-image>
									<el-image style="width: 130x; height: 132px"  v-else>
									 <div slot="error" class="image-slot">
									        {{v.name.substr(0,1)}}
									      </div>
									</el-image>
								</div>
								<span class="name">{{ v.name }}</span>
								<p class="kind">{{ v.danwei }}{{ v.zhiwu }}</p>
								<p v-if="v.subject">
									学科分类：
									<span>{{ v.subject.cate_two }}</span>
								</p>
								<p>
									产业领域：
									<span>{{ v.territory_one_name }}</span><span>{{ v.territory_two_name }}</span><span>{{ v.territory_three_name }}</span>
									
								</p>
								<button class="contact">查看专家</button>
							</div>
						</ul>
					</div>
				
				</div>
			
			</div>
			<div class="pagination">
				<el-pagination
					background
					@current-change="handleCurrentChange"
					:current-page.sync="currentPage"
					:page-size="search_data.limit"
					layout="total, prev, pager, next, jumper"
					:total="count"
				></el-pagination>
			</div>
		</article>
	</div>
</template>

<script>
	import {
		mapGetters,
	} from 'vuex'
import citylist from '../../assets/js/city.min.js';
import subjectlist from '../../assets/js/subjectlist.js';
import Top from "@/components/top.vue"
export default {
	metaInfo: {
		title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
		meta: [
			{
				name: 'keywords',
				content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
			},
			{
				name: 'description',
				content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
			}
		]
	},
	components: {
		Top
	},

	data() {
		return {
			radio: ['不限', '不限', '不限'],
			classify: [
				{
					title: '所在地',
					active: false,
					tags: ['不限']
				},
				{
					title: '学科',
					active: false,
					tags: ['不限']
				},
				{
					title: '产业领域',
					active: false,
					tags: ['不限']
				}
			],
			currentPage: 1,
			search_data: {
				has_collect: 1,
				limit: 25,
				page: 1,
				name: '',
				keyword: '',
				yjfx: '', //研究方向
				danwei: '',
				province: '',
				subject: '',
				territory_one_name: ''
			},
			list: [],
			count: 0,
			reload: false,
			loading: true
		};
	},
	watch: {
		$route() {
			let query = this.$route.query;
			this.currentPage = 2;
			console.log('$route', query);
			if (typeof query.page != 'undefined') {
				this.search_data.page = parseInt(query.page);
				this.currentPage = parseInt(query.page);
			} else {
				this.search_data.page = 1;
				this.currentPage = 1;
			}
			if (typeof query.keyword != 'undefined') {
				this.search_data.keyword = query.keyword;
				this.currentPage = 1;
				this.search_data.page = 1;
			}
			this.search();
		}
	},
	
	computed: {
		noMore() {
			return this.count >= 40;
		},
		disabled() {
			return this.loading;
		},
		...mapGetters(['token','user'])
	},
	created() {
		var querys = this.$route.query;
		if (typeof querys.keyword != 'undefined') {
			this.search_data.keyword = querys.keyword;
		}
		if (typeof querys.page != 'undefined') {
			this.search_data.page = parseInt(querys.page);
			this.currentPage = parseInt(querys.page);
		} else {
			this.search_data.page = 1;
			this.currentPage = 1;
		}
	},
	mounted() {
		var listcity = [];
		var listsubject = [];
		// var listindustry = [];
		citylist.forEach(function(item) {
			listcity.push(item.p);
			return;
		});
		subjectlist.forEach(function(item) {
			listsubject.push(item.p);
			return;
		});
		this.classify[0].tags = this.classify[0].tags.concat(listcity);
		this.classify[1].tags = this.classify[1].tags.concat(listsubject);
		// window.addEventListener('scroll', this.load); // 滚动到底部，再加载的处理事件
		this.expertCate();
		this.$nextTick(() => {
			this.search();
		});
	},
	methods: {
		expertCate(){
			this.$http_qm.post('expert/cate',{
				type:'industrial'
			}).then(res => {
				if(res.code == 200){
					this.classify[2].tags = this.classify[2].tags.concat(res.data.industrial);
				}
			}).catch(err => {
				console.log(err)
			})
		},
		tagChange() {
			let that = this;
			this.radio.forEach(function(v, k) {
				if (k == 0) {
					that.search_data.province = v == '不限' ? '' : v;
				} else if (k == 1) {
					that.search_data.subject = v == '不限' ? '' : v;
				} else if (k == 2) {
					that.search_data.territory_one_name = v == '不限' ? '' : v;
				}
			});
			this.search();
		},
		handleCurrentChange(page) {
			this.$router.push({
				path: '/expert',
				query: {
					page: page
				}
			});
			// this.search_data.page = val;
			// this.search();
			document.querySelector('#app').scrollIntoView({
				behavior: 'auto'
			});
		},
		do_search() {
			this.search_init();
			this.search();
		},
		search_init() {
			this.reload = true;
			this.search_data.page = 1;
			this.loading = true;
		},
		async search() {
			let search_data = this.search_data;
			await this.$http
				.post('expert/search', search_data)
				.then(res => {
					this.list = res.data;
					this.count = res.count;
				})
				.catch(err => {
					console.log(err);
				});
		},
		createFilter(queryString) {
			return restaurant => {
				return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
			};
		},

		load() {
			let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
			let clientHeight = document.documentElement.clientHeight;
			let scrollHeight = document.documentElement.scrollHeight;
			if (scrollTop + clientHeight >= scrollHeight) {
				// 滚动到底部，逻辑代码
				if (this.loading) {
					this.search();
				}
			}
		},
		expert_detail(id) {
			if(this.token=='' || this.token== null){
				this.$router.push({
					path: '/login'
				})
				return
			}
			this.$router.push({
				name: 'expertDetail',
				query: {
					id: id
				}
			});
		}
	},

};
</script>

<style type="text/css" lang="less" src="../../assets/css/expert.less"></style>
