<template>
	<div id="admin">
		<Top></Top>
		<div class="wrapper">
			<div class="main_left">
				<Left></Left>
			</div>
			<div class="main_right">
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane :disabled='k == 0' v-for="(v,k) in tabItem" :key="k" :status="v.status" :label="v.label" :name="v.name">
						<div class="articles-contents" v-if="news.list.length>0">
							<div class="search-result ">共 {{news.count}} 条内容</div>
							<div class="genre-item">
								<div class="article-card" v-for="(v,k) in news.list" :key="k">
									<div class="article-card-wrap ">
										<div class="image">
											<el-image style="width: 166px; height: 106px" v-if="Array.isArray(v.cover)" :src="v.cover[0]" fit="contain"></el-image>
											<el-image style="width: 166px; height: 106px" v-else :src="v.cover" fit="contain"></el-image>
										</div>
										<div class="article-card-bone">
											<div class="title-wrap">
												<span class="title">{{v.title}}</span>
												<span class="label create-time">{{v.created_at}}</span>
											</div>
											<div class="abstruct">
												<div class="byte-tag-simple">{{v.status_mes}}</div>
												<div class="byte-tag-checked" v-if="v.is_top">置顶</div>
												<div class="divider"></div>
											</div>
											<div class="count-wrapper">
												<ul class="count ">

													<li>阅读 {{v.view_num}}</li>
													<li>点赞 {{v.support_num}}</li>
													<li>评论 {{v.comment_num}}</li>
												</ul>
												<div class="article-action-list">
													<div style="padding-right: 20px;">
														<router-link :to="{path:'/news/add',query:{id:v.id}}" class="icon-label disable" tabindex="0">修改</router-link>
													</div>
													<el-popconfirm title="这是一条新闻确定删除吗？" @confirm="del(k,v.id)">
														<span slot="reference" class="icon-label hover">删除作品</span>
													</el-popconfirm>

												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="byte-page">
								<el-pagination :page-size="news_post.limit" @current-change="handleCurrentChange" background layout="prev, pager, next"
								 :total="news.count"></el-pagination>
							</div>
						</div>
						<div class="articles-contents" v-else>
							<div class="byte-empty empty">
								<div class="byte-empty-wrapper">
									<div class="byte-empty-image">
										<img alt="empty" src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/news-tips.png">
									</div>
									<div class="byte-empty-description">
										<div class="empty-tip">没有符合条件的内容</div>
									</div>
								</div>
							</div>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
		</div>
	</div>
</template>

<script>
	import Left from "../company/Left.vue"
	import Top from "@/components/top.vue"
	export default {
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		components: {
			Left,Top
		},
		data() {
			return {
				activeName: 'item2',
				tabItem: [{
					label: '状态',
					name: 'item1'
				}, {
					label: '全部',
					name: 'item2',
					status: '',
				}, {
					label: '已发布',
					name: 'item3',
					status: '1',
				}, {
					label: '审核中',
					name: 'item4',
					status: '0',
				}, {
					label: '未通过',
					name: 'item5',
					status: '2',
				}],
				news_post: {
					page: 1,
					limit: 4,
					status: '',
					request_type:'me'
				},
				news: {
					list: [],
					count: 0,
				}
			}
		},
		mounted() {
			this.get_list();
		},
		methods: {
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.news_post.page = val;
				this.get_list();
			},
			get_list() {
				this.$http_qm.post('news/list', this.news_post).then(res => {
					this.news.list = res.data != null ? res.data : [],
						this.news.count = res.count
				}).catch(err => {
					console.log(err)
				})
			},
			handleClick(tab) {
				this.news_post.status = tab.$attrs.status;
				this.get_list();
			},
			detail(id) {
				this.$router.push({
					path: '/news/detail',
					query: {
						id: id
					}
				})
			},
			del(k, id) {
				console.log(k, id)
				this.$http_qm.post('news/delete', {
					id: id
				}).then(res => {
					this.$message.info(res.msg);
					if (res.code == 200) {
						this.news.list.splice(k, 1);
					}
				}).catch(err => {
					console.log(err)
				})

			},
		}
	}
</script>

<style lang="less">
	#admin {
		margin: 0;
		background: #f0f0f0;

		.wrapper {
			padding: 15px 0;
			width: 1200px;
			margin: 0 auto;
			flex-direction: row;
			justify-content: space-between;
			display: flex;

			.main_left {
				width: 195px;
				height: auto;
				text-align: center;
			}

			.main_right {
				width: 973px;
				height: auto;
				min-height: 100%;
				float: right;
				border: 1px solid #e0e0e0;
				background: #fff;
				padding: 10px 20px;
				box-sizing: border-box;

				.articles-contents {
					.search-result {
						margin: 4px 0 8px;
					}

					.genre-item {
						transition: background-color 1s ease-in-out;
						margin: 0 -64px;
						padding: 0 64px;

						.article-card {
							padding: 24px 0;
							line-height: 1;
							font-size: 14px;
							color: #666;
							border-bottom: 1px solid #e8e8e8;
							position: relative;
							background-color: transparent;

							.article-card-wrap {
								position: relative;
								display: flex;

								.image {
									width: 168px;
									min-width: 168px;
									height: 108px;
									margin-right: 24px;
									position: relative;
									overflow: hidden;
									border-radius: 4px;
									border: 1px solid #e8e8e8;
									cursor: pointer;

									img {
										width: 100%;
										height: 100%;
										transition: all .5s ease-out .1s;
									}
								}

								.article-card-bone {
									width: 100%;
									display: flex;
									flex-direction: column;
									min-width: 0;

									.title-wrap {
										display: flex;
										justify-content: space-between;
										margin-top: 0;

										.title {
											font-size: 16px;
											overflow: hidden;
											white-space: nowrap;
											text-overflow: ellipsis;
											line-height: 24px;
											font-weight: 600;
											display: block;
											color: #222;
										}

										.create-time {
											color: #999;
											line-height: 24px;
											margin-left: 12px;
											flex-shrink: 0;
										}
									}

									.abstruct {
										line-height: 20px;
										margin-top: 12px;
										height: 20px;
										display: flex;
										align-items: flex-end;

										.byte-tag-simple {
											margin-right: 8px;
											padding: 0 8px;
											line-height: 20px;
											color: var(--black03);
											border: none;
											background-color: #f5f5f5;
										}

										.byte-tag-checked {
											color: #3d89ff;
											border: none;
											background-color: #e6f0ff;
											margin-right: 8px;
											padding: 0 8px;
											line-height: 20px;
										}

										.divider {
											width: 1px;
											height: 12px;
											margin: 4px 10px 4px 4px;
											background: var(--black04);
										}
									}

									.count-wrapper {
										margin-top: 32px;
										display: flex;
										justify-content: space-between;

										.count {
											line-height: 20px;
											position: relative;

											li {
												display: inline-block;
												margin-right: 24px;
												list-style: none;

												&:after {
													content: "\ff65";
													font-size: 20px;
													margin: 0 8px;
													line-height: 0;
													position: absolute;
													top: 10px;
													color: #666;
													color: var(--black02);
												}

												&:last-child {
													&:after {
														content: "";
														border-left: none;
													}
												}
											}
										}

										.article-action-list {
											display: flex;
											line-height: 20px;
											flex: 1 0 auto;
											justify-content: flex-end;

											.icon-label {
												cursor: pointer;
												font-size: 14px;
												line-height: 20px;
												display: flex;
												color: #222;
												color: var(--black01);
												font-weight: 400;
												margin-left: 24px;

												&:first-child {
													margin-left: 0;
												}
											}
										}
									}
								}
							}
						}
					}

					.byte-page {
						display: flex;
						justify-content: flex-end;
						margin-top: 24px;
					}

					.byte-empty {
						width: 100%;
						box-sizing: border-box;
						padding-top: 128px;

						.byte-empty-wrapper {
							width: 100%;
							box-sizing: border-box;
							text-align: center;
							font-size: 12px;
							color: #999;

							.byte-empty-image {
								margin-bottom: 17px;
								color: var(--black05);
								font-size: 48px;
								line-height: 1;

								img {
									height: 140px;
									width: 140px;
								}
							}

							.byte-empty-description {
								line-height: 20px;

								.empty-tip {
									color: var(--black03);
									text-align: center;
									font-size: 14px;
									line-height: 20px;
								}
							}
						}
					}
				}

			}
		}
	}
</style>
